<template>
    <div class="AskRecord">
        <div class="AskRecord-Top">
            <div class="A1" @click="Goto">
                <el-icon><ArrowLeft /></el-icon>
            </div>
            <div class="A2">{{ $t('AskRe.Top') }}</div>
        </div>

        <div class="AskRecord-BckImg">
            <img src="@/assets/Img/Imge/8.svg">
            <div>{{ $t('AskRe.text') }}</div>
        </div>
    </div>
</template>

<script setup>
import { defineEmits } from 'vue'

const emits = defineEmits('PageFlagZ')

function Goto(){
    emits('PageFlagZ',true)
}
</script>