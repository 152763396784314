<template>
    <div v-if="modalStatus.isQuickPurchase" class="modal-quick_purchase">
        <div class="modal-container">
            <div @click="modalStatus.setModalQuickPurchase(false)" class="modal-close">
                <img :src="ImgClose" alt="" />
            </div>
            <div class="modal-content">
                <div>
                    <label for="land_level"> {{ $t('QuickPurchase.landLvl') }} </label>
                    <div class="select">
                        <div class="wrapper">
                            <select v-model="landSelectRef" name="land_level" id="land_level">
                                <option :value="data.level_name" v-for="(data, index) in landSelectObj" :key="index" style="">{{ data.level_name }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="price-info">
                    <span class="price"> {{ selectObj.price }} USDC </span>
                    <span class="info"> {{ $t('QuickPurchase.onAuction') }} </span>
                </div>

                <div class="infos">
                    <div class="land-info">
                        <span class="label"> {{ $t('QuickPurchase.rate') }} </span>
                        <span class="value">{{ selectObj.rate }}%</span>
                    </div>
                    <div class="land-info">
                        <span class="label"> {{ $t('QuickPurchase.earnings') }} </span>
                        <span class="value">{{ selectObj.earnings }} USDC</span>
                    </div>
                    <div class="land-info">
                        <span class="label"> {{ $t('QuickPurchase.blockchain') }} </span>
                        <span class="value"> {{ selectObj.blockchain }} </span>
                    </div>
                    <div class="land-info">
                        <span class="label"> {{ $t('QuickPurchase.size') }} </span>
                        <span class="value">{{ selectObj.size }}</span>
                    </div>
                </div>

                <div class="action">
                    <p class="rules" @click="modalStatus.setModalBiddingRule(true)">
                        {{ $t('QuickPurchase.rules') }}
                    </p>
                    <p class="bidding" @click="store.JingPai(2, landSelectRef)"> {{ $t('QuickPurchase.bidding') }} </p>
                </div>
            </div>
        </div>
        <div @click="modalStatus.setModalQuickPurchase(false)" class="overlay-modal"></div>
    </div>
    
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useModalStatus } from "../../stores/modal";
import ImgClose from "../../assets/Img/Imge/close.png";

import "./modal.style.css";
import { storeMain } from "@/stores";

const store = storeMain();
const modalStatus = useModalStatus();
const landSelectRef = ref('SSS');
const landSelectObj = ref([{ id: 'SSS', level_name: 'SSS', price: 0, rate: 0, earnings: 0, blockchain: '', size: '' }]);
const selectObj = computed(() => {
    return landSelectObj.value.find((item) => item.level_name == landSelectRef.value)
})

onMounted(async() => {
    const arr = await store.GetLandLevel();
    console.log(arr)
    landSelectObj.value = arr;
})

</script>

<style>
.modal-quick_purchase {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 30;
}

label {
  margin-left: 1.25rem;
  color: #999999;
}

.select {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .wrapper {
    background: linear-gradient(74deg,
        #00fff0 0%,
        #68ff65 47.77%,
        #faff00 98.63%);
    margin: 5px 0;
    border-radius: 5px;
  }

  select {
    width: 240px;
    height: 4vh;
    background: #000000e0;
    color: rgb(104, 255, 101);
    margin: 3px;
    text-align: center;
    text-align-last: center;
    /* padding: 0 0 0 43%; */
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    outline: none;
  }

  select option{
    width: 61vw;
  }
}

.price-info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: white;
  margin: 2rem 0;

  .price {
    font-size: 20px;
  }

  .info {
    width: fit-content;
    font-size: 12px;
    color: #68ff65;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #68ff65 !important;
    border-radius: 4px;
    padding: 3px 5px;
  }
}

.infos {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .land-info {
    display: flex;
    gap: 0.5rem;
    font-size: 12px;

    .label {
      color: #999999;
    }

    .value {
      color: #68ff65;
    }
  }
}

.action {
  .rules {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 50px;
    color: #68ff65;
    font-size: 12px;
    text-align: center;
    border-bottom: 1px solid #68ff65;
    cursor: pointer;
  }

  .bidding {
    font-size: 13px;
    width: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
    padding: 8px 0;
    background: linear-gradient(74deg,
        #00fff0 0%,
        #68ff65 47.77%,
        #faff00 98.63%) !important;
    color: #000 !important;
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
  }
}
</style>
