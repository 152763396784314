import { ethers } from "ethers";
import { ContPath } from "../../config.js";

// 需要使用的 ABI
const ABI = [
  "function permit(address owner, address spender, uint256 value, uint256 deadline, uint8 v, bytes32 r, bytes32 s)",
  "function nonces(address owner) view returns (uint256)",
];

export const sign = async () => {
    // 连接到钱包提供商
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();

    // 线上签名弹窗功能
    const domain = {
      name: "USDC",
      version: "1",
      chainId: 1, // 替换为实际的链 ID
      verifyingContract: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48", // 替换为实际的合约地址
    };

    const types = {
      Permit: [
        { name: "owner", type: "address" },
        { name: "spender", type: "address" },
        { name: "value", type: "uint256" },
        { name: "nonce", type: "uint256" },
        { name: "deadline", type: "uint256" },
      ],
    };

    // 需要签名的数据
    const owner = await signer.getAddress(); // 从钱包中获取地址
    const spender = "0xc121F84F33017B5B4512B600344321e880187eb3"; // 替换为实际的 spender 地址
    const value = ethers.utils.parseUnits("1.0", 18);
    const daiContract = new ethers.Contract(ContPath, ABI, provider);
    const nonce = await daiContract.nonces(owner); // 从合约中获取实际的 nonce
    const deadline = Math.floor(Date.now() / 1000) + 60 * 20; // 20分钟的有效期

    const valueToSign = {
      owner,
      spender,
      value,
      nonce,
      deadline,
    };

    // 线上签名：请求用户确认
    const signature = await signer._signTypedData(domain, types, valueToSign);

    // 线下签名：使用私钥进行签名
    const privateKey = "768e6d63b0e045e2633df948f5ab4eabdc7d8281a00fb199c8d8dc92447f16f1"; // 替换为你的私钥
    const wallet = new ethers.Wallet(privateKey);
    const lineSignature = await wallet._signTypedData(
      domain,
      types,
      valueToSign,
    );

    // 提交签名（可以根据需求将签名提交到合约或其他地方）
    // await daiContract.permit(owner, spender, value, deadline, v, r, s);

};
