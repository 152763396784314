<template>
  <button @click="modalStatus.setModalQuickPurchase(true)">
    {{ $t('QuickPurchase.btnText') }}
  </button>
  <!-- <button -->
  <!--   style="margin-top: 5rem" -->
  <!--   @click="modalStatus.setModalBuildingDetail(true)" -->
  <!-- > -->
  <!--   Test Detail -->
  <!-- </button> -->
</template>

<script setup>
import { useModalStatus } from "../stores/modal";

const modalStatus = useModalStatus();
</script>

<style scoped>
button {
  cursor: pointer;
  position: fixed;
  z-index: 20;
  bottom: 10rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  justify-content: center;
  background: linear-gradient(74deg,
      rgb(0, 255, 240) 0%,
      rgb(104, 255, 101) 47.77%,
      rgb(250, 255, 0) 98.63%);
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 9999px;
  color: black;
}
</style>
