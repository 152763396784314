export default {
  home: {
    HtD1: "共同玩耍，創造價值",
    HtD2: "創建並擁有你的虛擬土地",
    HtD3: "讓我們一起建立一個全新的世界",
    Btn: "前往探索",
    info1: "總用戶",
    info2: "活躍用戶",
    info3: "日交易量+月交易量+季交易量",
    BoxTop: "元宇宙（虛擬土地）簡介",
    desc: "可認知元宇宙為真實世界之體現，隨時進行連結及同步，實體設施均設計為可直接適用於社交、學習、商業、娛樂、協作、遊戲",
    infoTop:
      "透過MetaSpaces建立獨特的遊戲資產，並對其代幣化，係統端不涉入MetaSpaces中的營運及建設方向，用戶擁有絕對的自主權。 可全權決定與MetaSpaces中構建資產之最終價值。",
    li1: "區塊鏈虛擬世界",
    li2: "擁有絕對保障",
    li3: "原產地真實性",
    li4: "可透過去中心化市場進行交易",
    li5: "採用經過驗證的安全標準建構",
    swiperTop: "合作伙伴",
    caseTop: "案例",
  },
  Build: {
    label1: "未建设",
    label2: "已建设",
    labeltext: "暫無數據",
  },
  MyPage: {
    Score: "信譽評分",
    JeCount1: "凍結金額",
    JeCount2: "可用金額",
    EarNingsTop: "收益",
    EarNingsInfo1: "總收益",
    EarNingsInfo2: "待提領",
    EarNingsInfo3: "今日收益",
    EarNingsBtn: "提領收益",
    List1: "邀請獎勵",
    List2: "訂單記錄",
    List3: "歷史記錄",
    List4: "借貸記錄",
    List5: "已競標土地",
    List6: "幫助中心",
    msg: "退出成功",
  },
  MyTxPage: {
    Top: "提现收益",
    Top2: "提現USDC到達您的錢包中",
    info1: "平台USDC余额",
    info2: "輸入妳想要兌換的USDC數量",
    btn: "全部",
    info3: "手续费",
    info4: "實際到賬",
    txmBA: "您的提現申請將在24小時內發送到您的錢包",
    txmBB: "確認提現",
    Msg1: "領取申請已提交,等待審核",
    Msg2: "提现失败，提现金额不能大于平台余额",
  },
  Order: {
    Top: "訂單記錄",
    wc: "暫無數據",
    sb: "暫無數據",
    ywc: "已完成",
    ysb: "已失败",
  },
  History: {
    Top: "歷史記錄",
    tis: "暫無數據",
    ShuoYi: "訂單收益",
    JiLu: "提領記錄",
  },
  Bor: {
    Top: "借貸記錄",
    tis: "暫無數據",
  },
  Land: {
    Top: "已競標土地",
    tis: "暫無數據",
  },
  Ask: {
    Top: "邀請獎勵",
    info1: "邀請獎勵",
    info2: "團隊規模",
    info3: "直屬下屬",
    info4: "總收入",
    info5: "今日收益",
    a1: "邀請記錄",
    btn1: "提領獎勵",
    AskUrlText: "邀請連結",
    btn2: "前往邀請",
    tab1: "地址",
    tab2: "時間",
    lab1: "資產記錄",
    lab2: "等級一",
    lab3: "等級二",
    lab4: "等級三",
  },
  AskRe: {
    Top: "邀請記錄",
    text: "暫無數據",
  },
  Help: {
    Top: "幫助中心",
    text1:
      " Meta land允許用戶使用USDC來競標虛擬土地，並透過競標投機土地並收回虛擬土地收益以及收回虛擬土地。因此，就像在現實生活中一樣，購買土地並完成土地建設，以便以更高的價格出售。在完成時建置的同時，使用者可以擁有自己創建的NFT。",
    text2:
      "土地 C 價格： 500. 日利率 1.3 % ，期限： 3 天 。土地 B 價格: 5,000.日利率1.5 % ，期限：5天。：土地 A 價格：30,000。日利率 1.9 % .期限：7 天 。土地 S 價格：100,000。日利率 2.5 % .期限：15 天 。土地 SS 價格：300,000。日利率 3.2 %期限：20 天。土地SSS價格：500,000。日利率 4.0 %，期限：30天。 註：只有 S 级别地塊以上（包括 S 地塊）的地塊才能進行建設，並產生更多的每日利潤。",
    tte1: "人們可以透過哪些方式在MetaLand上賺錢",
    tte2: "關於 Meta Land 的土地規劃",
  },
  Login: {
    lab1: "連接錢包 前往探索",
  },
  AppBar: {
    text1: "首页",
    text2: "地图",
    text3: "建設",
    text4: "個人資產",
  },
  MsgPage: {
    Top: "通知",
    tte: "暫無數據",
  },
  QuickPurchase: {
    btnText: "快速购买",
    landLvl: "土地等级",
    onAuction: "拍卖中",
    earningRate: "收益率",
    earnings: "收益",
    size: "大小",
    rules: "规则",
    bidding: "竞拍",
    blockchain: "区块链",
    ethereum: "以太坊",
  },
  QuickPurchaseRules: {
    title: "我可以用土地做什么？",
    rule1: "获得特别奖励",
    rule2: "MetalLand持有者的专属抵押",
    rule3: "出售你的土地并赚取收入",
    rule4: "发布并货币化你的创作",
  },
  BuildingDetail: {
    coordinates: "坐标",
    owner: "所有者",
    earningRate: "收益率",
    earnings: "收益",
    size: "大小",
    token: "令牌",
  },

   "LoansTopName": "借貸規則",
  "LoansBtn": "確認申請貸款",
  "Loans_L1": "信用",
  "Loans_L2": "恭喜",
  "Loans_L3": "您的信用評等良好，可申請貸款，您只需提交一份申請<br/>客服部門會根據您的信用評等申請貸款額度",
  "Loans_L4": "確認申請",
  "Loans_L5": "根據信用積分提交貸款申請",
  "Loans_L6": "獲得貸款",
  "Loans_L7": "完成更高階任務",
  "Loans_L8": "持續完成任務，累計信用積分，可借貸上限為",
  "Loans_L9": "金額",
  "Loans_L10": "天",
  "Loans_L11": "利息",
  "Loans_L12": "接受",
  "Loans_L13": "投資金額",
  "Loans_L14": "收益佔比",

  "Build_L1": "建設",

  "BOT_L1": "建設土地",
  "BOT_L2": "土地價格",
  "BOT_L3": "立即建設",
  "BOT_Sel1": "住宅",
  "BOT_Sel2": "公共",
  "BOT_L4": "所有人",
  "BOT_L5": "地理位置:",
  "BOT_L6": "基礎收益:",
  "BOT_L7": "建設收益:",
  "BOT_L8": "收益:",
  "BOT_L9": "建設用途選擇:",
  "BOT_L10": "建設類型選擇:",
  "BOT_L11": "請選擇建設類型:",
  "BOT_L12": "建設費用",
  "BOT_L13": "提領NFT",

  "Land_L1": "競標金額",
  "Land_L2": "期間收益",
  "Land_L3": "結束",
  "Land_L4": "所在地點",
  "Land_L5": "收益佔比",
  "Land_L6": "收益",
  "Land_L7": "天",

  "Bor_L1": "借貸金額",
  "Bor_L2": "天",
  "Bor_L3": "申請中",
  "Bor_L4": "時間",
  "Bor_L5": "利息",
  "Bor_L6": "完成",

  "Not_data_Msg": "沒有更多了"
}