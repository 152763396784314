<template>
    <div class="MsgTop BuildIndexPage" style="padding-bottom: 100px;">
        <div class="MsgTop-info">
            <div class="A1" @click="Goto()">
                <el-icon>
                    <ArrowLeft />
                </el-icon>
            </div>
            <div class="A2">{{ $t('BOT_L1') }}</div>
        </div>

        <div class="main">
            <img src="@/assets/Img/BOTImge/tjs.png" style="border-radius: 10px;width: 100%;height: auto;">
        </div>

        <div class="title">{{ props.PageData.name }} : {{ props.PageData.level_name }}</div>

        <div class="land-price-main">
            <div class="text-color-active">{{ $t("BOT_L2") }} {{ props.PageData.price }} USDC</div>
        </div>

        <div class="built-info">
            <div class="top">
                <div class="left built-color">{{ $t('BOT_L4') }}<div class="address"> {{ props.PageData.address }}</div>
                </div>
                <div class="right built-color">
                    <img src="@/assets/Img/BOTImge/1.svg" style="margin: -3px 5px 0px 0px; height: 15px;">
                    {{ $t('BOT_L5') }}: -{{ props.PageData.x }}
                </div>
            </div>
            <div class="info_">
                <div class="info_left">
                    <div class="info_flex" style="position: relative;">
                        <div class="ant-space-item built-color">{{ props.PageData.build_usage }}</div>
                    </div>
                </div>
                <div class="info_right" style="justify-content: right;padding-right: 10px;">
                    <div class="info_flex" style="position: relative;min-width: 100px;justify-content: right;">
                        <div class="ant-space-item built-color">{{ props.PageData.build_type }}</div>
                    </div>
                </div>
            </div>
            <div class="info_ info_bg_">
                <div class="name built-color">{{ $t('BOT_L12') }}</div>
                <div class="name built-color">{{ props.PageData.build_cost }} USDC</div>
            </div>
            <div class="info_ info_bg_">
                <div class="name built-color">{{ $t('BOT_L6') }}</div>
                <div class="name built-color">{{ props.PageData.build_base_rate }}%</div>
            </div>
            <div class="info_ info_bg_">
                <div class="name built-color">{{ $t('BOT_L7') }}</div>
                <div class="name built-color">{{ props.PageData.build_rate }}%</div>
            </div>
            <div class="info_ info_bg_">
                <div class="name built-color">{{ $t('BOT_L8') }}</div>
                <div class="name built-color">{{ earnings }}</div>
            </div>
        </div>

        <div style="margin: 20px 0;text-align: center;">
            <span style="background: linear-gradient(74deg, rgb(0, 255, 240) 0%, rgb(104, 255, 101) 47.77%, rgb(250, 255, 0) 98.63%);
                color: black;font-weight: bold;padding: 10px 12px;border-radius: 10px;
                " @click="TNFT()">
                {{ $t('BOT_L13') }}
            </span>
        </div>

    </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, onMounted } from 'vue';

const emits = defineEmits(['Build'])
const props = defineProps({
    PageData: Array
})
const earnings = ref(0);

onMounted(() => {
    // 转换字符串为数值类型
    const landPrice = Number(props.PageData.land_price);
    const baseInterestRate = Number(props.PageData.build_base_rate);
    const interestRate = Number(props.PageData.build_rate);
    const landPeriod = Number(props.PageData.land_period);
    earnings.value = (landPrice * ((baseInterestRate + interestRate) / 100) * landPeriod).toFixed(2);
})

function Goto() {
    emits('Build', true)
}

function TNFT(){
    
}
</script>

<style scoped>
div {
    box-sizing: border-box;
}

.main {
    width: 240px;
    height: 240px;
    background: linear-gradient(74deg, rgb(0, 255, 240) 0%, rgb(104, 255, 101) 47.77%, rgb(250, 255, 0) 98.63%);
    border-radius: 10px;
    margin: 10px auto;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 1px;
}

.main .img {
    width: 100%;
    height: 100%;
}

.title {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 10px 0px;
}

.land-price-main {
    margin: 10px auto;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.land-price-main .text-color-active {
    background-image: linear-gradient(74deg, rgb(0, 255, 240) 0%, rgb(104, 255, 101) 47.77%, rgb(250, 255, 0) 98.63%);
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    border: 1px solid rgb(104, 255, 101);
    padding: 6px 20px;
    border-radius: 8px;
    background-clip: text !important;
}

.built-info {
    margin: 30px 0px;
}

.built-info .top {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    font-size: 13px;
}

.built-info .top .left {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.built-info .top .right {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.built-info .top .left .address {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 10px;
}

.built-color{
    background-image: linear-gradient(74deg, rgb(0, 255, 240) 0%, rgb(104, 255, 101) 47.77%, rgb(250, 255, 0) 98.63%);
    background-position: initial;
    background-size: initial;
    background-repeat: initial;
    background-attachment: initial;
    background-origin: initial;
    background-color: initial;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    background-clip: text !important;
}

.built-info .info_ {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin: 15px 0px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.built-info .info_ .info_left,
.built-info .info_ .info_right {
    width: 44%;
    height: 100%;
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    background: rgb(21, 23, 21);
    border-radius: 10px;
    padding-left: 10px;
}

.info_flex {
    display: flex;
    align-items: center;
    column-gap: 8px;

}

.built-info .info_bg_ {
    background: rgb(21, 23, 21);
    padding: 0px 10px;
}


.SelPop-enter-active,
.SelPop-leave-active {
    transition: all 0.3s ease;
}

/* 进入时的初始状态：从上方淡入 */
.SelPop-enter-from {
    transform: translateY(-20%);
    opacity: 0;
}

/* 进入时的最终状态：在原位置显示 */
.SelPop-enter-to {
    transform: translateY(0);
    opacity: 1;
}

/* 离开时的初始状态：在原位置 */
.SelPop-leave-from {
    transform: translateY(0);
    opacity: 1;
}

/* 离开时的最终状态：向下方淡出 */
.SelPop-leave-to {
    transform: translateY(-20%);
    opacity: 0;
}
</style>