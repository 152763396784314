export default {
  "home": {
    "HtD1": "一緒に遊び、価値を創造する",
    "HtD2": "あなたの仮想土地を作成して所有する",
    "HtD3": "新しい世界を一緒に築こう",
    "Btn": "探検に行く",
    "info1": "総ユーザー数",
    "info2": "アクティブユーザー数",
    "info3": "日取引量+月取引量+四半期取引量",
    "BoxTop": "メタバース（仮想土地）紹介",
    "desc": "メタバースは現実世界の具現化として認識され、常に接続および同期されています。物理的な施設は、ソーシャル、学習、ビジネス、エンターテインメント、協力、ゲームに直接適用できるように設計されています。",
    "infoTop": "MetaSpacesを通じて独自のゲーム資産を作成し、それをトークン化します。システム側はMetaSpacesの運営および建設の方向性に関与せず、ユーザーは絶対的な自主権を持っています。MetaSpacesで構築された資産の最終的な価値を完全に決定することができます。",
    "li1": "ブロックチェーン仮想世界",
    "li2": "絶対的なセキュリティ保証",
    "li3": "原産地の真実性",
    "li4": "分散型市場で取引可能",
    "li5": "検証済みのセキュリティ基準で構築",
    "swiperTop": "パートナー",
    "caseTop": "ケーススタディ"
  },
  "Build": {
    "label1": "未建設",
    "label2": "建設済み",
    "labeltext": "データなし"
  },
  "MyPage": {
    "Score": "信用スコア",
    "JeCount1": "凍結金額",
    "JeCount2": "利用可能金額",
    "EarNingsTop": "収益",
    "EarNingsInfo1": "総収益",
    "EarNingsInfo2": "引き出し待ち",
    "EarNingsInfo3": "今日の収益",
    "EarNingsBtn": "収益を引き出す",
    "List1": "招待報酬",
    "List2": "注文記録",
    "List3": "履歴",
    "List4": "借用記録",
    "List5": "入札された土地",
    "List6": "ヘルプセンター",
    "msg": "ログアウト成功"
  },
  "MyTxPage": {
    "Top": "収益を引き出す",
    "Top2": "USDCをウォレットに引き出す",
    "info1": "プラットフォームのUSDC残高",
    "info2": "交換したいUSDCの数量を入力してください",
    "btn": "すべて",
    "info3": "手数料",
    "info4": "実際の入金額",
    "txmBA": "引き出し申請は24時間以内にウォレットに送信されます",
    "txmBB": "引き出しを確認",
    "Msg1": "回収申請は提出され、審査を待っています",
    "Msg2": "引き出し失敗、引き出し金額はプラットフォームの残高を超えることはできません"
  },
  "Order": {
    "Top": "注文記録",
    "wc": "データなし",
    "sb": "データなし",
    "ywc": "完了",
    "ysb": "失敗"
  },
  "History": {
    "Top": "履歴",
    "tis": "データなし",
    "ShuoYi": "注文収益",
    "JiLu": "引き出し記録"
  },
  "Bor": {
    "Top": "借用記録",
    "tis": "データなし"
  },
  "Land": {
    "Top": "入札された土地",
    "tis": "データなし"
  },
  "Ask": {
    "Top": "招待報酬",
    "info1": "招待報酬",
    "info2": "チーム規模",
    "info3": "直属の部下",
    "info4": "総収入",
    "info5": "今日の収益",
    "a1": "招待記録",
    "btn1": "報酬を引き出す",
    "AskUrlText": "招待リンク",
    "btn2": "招待に行く",
    "tab1": "住所",
    "tab2": "時間",
    "lab1": "資産記録",
    "lab2": "レベル1",
    "lab3": "レベル2",
    "lab4": "レベル3"
  },
  "AskRe": {
    "Top": "招待記録",
    "text": "データなし"
  },
  "Help": {
    "Top": "ヘルプセンター",
    "text1": "Meta landはユーザーがUSDCを使用して仮想土地に入札し、投機土地を入札して仮想土地収益を回収し、仮想土地を取得できるようにします。したがって、現実の生活と同じように、土地を購入し、土地の建設を完了して、より高い価格で販売することができます。ユーザーは完了後に自分で作成したNFTを所有できます。",
    "text2": "土地C価格：500。日利率1.3％、期間：3日。土地B価格：5,000。日利率1.5％、期間：5日。土地A価格：30,000。日利率1.9％、期間：7日。土地S価格：100,000。日利率2.5％、期間：15日。土地SS価格：300,000。日利率3.2％、期間：20日。土地SSS価格：500,000。日利率4.0％、期間：30日。注：S級以上（S級含む）の土地のみが建設可能であり、より多くの日利を生み出します。",
    "tte1": "MetaLandでお金を稼ぐ方法",
    "tte2": "Meta Landの土地計画について"
  },
  "Login": {
    "lab1": "ウォレットを接続して探索へ"
  },
  "AppBar": {
    "text1": "ホーム",
    "text2": "地図",
    "text3": "建設",
    "text4": "個人資産"
  },
  MsgPage: {
    Top: "通知",
    tte: "データなし"
  },
  "QuickPurchase": {
    "btnText": "クイック購入",
    "landLvl": "土地のレベル",
    "onAuction": "オークション中",
    "earningRate": "収益率",
    "earnings": "収益",
    "size": "サイズ",
    "rules": "ルール",
    "bidding": "入札",
    blockchain: "ブロックチェーン",
    ethereum: "イーサリアム"
  },
  "QuickPurchaseRules": {
    "title": "土地で何ができますか？",
    "rule1": "特別な報酬を獲得",
    "rule2": "MetalLand保有者専用の誓約",
    "rule3": "土地を売却して収益を得る",
    "rule4": "作成物を公開して収益化"
  },
  "BuildingDetail": {
    "coordinates": "座標",
    "owner": "所有者",
    "earningRate": "収益率",
    "earnings": "収益",
    "size": "サイズ",
    "token": "トークン"
  },

  "LoansTopName": "ローン規則",
  "LoansBtn": "ローン申請を確認する",
  "Loans_L1": "信用",
  "Loans_L2": "おめでとうございます",
  "Loans_L3": "あなたの信用評価は良好です。ローンを申請することができます。申請を提出するだけで、<br/>カスタマーサービス部門があなたの信用評価に基づいてローン額を決定します。",
  "Loans_L4": "申請を確認する",
  "Loans_L5": "信用ポイントに基づいてローン申請を提出する",
  "Loans_L6": "ローンを取得する",
  "Loans_L7": "より高い階層のタスクを完了する",
  "Loans_L8": "タスクを継続的に完了し、信用ポイントを累積することで、最高借入額は",
  "Loans_L9": "金額",
  "Loans_L10": "日数",
  "Loans_L11": "利息",
  "Loans_L12": "受け入れる",
  "Loans_L13": "投資額",
  "Loans_L14": "収益割合",

  "Build_L1": "建設",

  "BOT_L1": "土地を建設する",
  "BOT_L2": "土地価格",
  "BOT_L3": "今すぐ建設する",
  "BOT_Sel1": "住宅",
  "BOT_Sel2": "公共",
  "BOT_L4": "所有者",
  "BOT_L5": "位置:",
  "BOT_L6": "基本収益:",
  "BOT_L7": "建設収益:",
  "BOT_L8": "収益:",
  "BOT_L9": "建設用途の選択:",
  "BOT_L10": "建設タイプの選択:",
  "BOT_L11": "建設タイプを選択してください:",
  "BOT_L12": "建設費用",
  "BOT_L13": "NFTを引き出す",

  "Land_L1": "入札額",
  "Land_L2": "期間中の収益",
  "Land_L3": "終了",
  "Land_L4": "所在地",
  "Land_L5": "収益割合",
  "Land_L6": "収益",
  "Land_L7": "日数",

  "Bor_L1": "借入額",
  "Bor_L2": "日数",
  "Bor_L3": "申請中",
  "Bor_L4": "時間",
  "Bor_L5": "利息",
  "Bor_L6": "完了",

  "Not_data_Msg": "これ以上のデータはありません"
}
