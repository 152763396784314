<template>
    <div v-show="PageFlag">
        <div class="LandTopAll">
            <LandTop @PageFlag="setPageFlag"></LandTop>
        </div>
        <div class="MyPages">
            <div class="MyPage" style="width: 100%">
                <div>
                    <div class="MyData">
                        <div class="DataTop">
                            <div class="Letf">
                                <div>
                                    <img src="@/assets/Img/Imge/9.svg" style="padding-right: 6px" />
                                </div>
                                <div>USDC</div>
                            </div>

                            <div @click="Logout" style="cursor: pointer;" class="Rigth">
                                <div>
                                    {{ UserName.slice(0, 4) }}...{{
                                    UserName.slice(UserName.length - 6, UserName.length)
                                    }}
                                </div>
                                <div>
                                    <img src="@/assets/Img/Imge/10.svg"
                                        style="width: 20px; height: 18px; padding-left: 5px" />
                                </div>
                            </div>
                        </div>

                        <div class="DataCentre">
                            <div>
                                {{ UserData.total_balance || 0 }}
                            </div>
                        </div>

                        <div class="Score">{{ $t('MyPage.Score') }}: {{ UserData.score || 0 }}</div>

                        <div class="JeCount">
                            <div class="J">
                                <div>{{ $t('MyPage.JeCount1') }}</div>
                                <div class="J2">{{ UserData.frozen_balance || 0 }} USDC</div>
                            </div>

                            <div class="J" style="text-align: right;">
                                <div>{{ $t('MyPage.JeCount2') }}</div>
                                <div class="J2">{{ UserData.balance || 0 }} USDC</div>
                            </div>
                        </div>
                    </div>

                    <div class="EarNings">
                        <div class="Top">{{ $t('MyPage.EarNingsTop') }}</div>

                        <div class="Ear-info">
                            <div style="color: #7b7b7b; font-size: 12px">{{ $t('MyPage.EarNingsInfo1') }}</div>
                            <div style="padding: 10px 0">{{ income.total_income || 0 }} USDC</div>
                        </div>

                        <div class="Ear-info">
                            <div style="color: #7b7b7b; font-size: 12px">{{ $t('MyPage.EarNingsInfo2') }}</div>
                            <div style="padding: 10px 0">{{ income.pending || 0 }} USDC</div>
                        </div>

                        <div class="Ear-info">
                            <div style="color: #7b7b7b; font-size: 12px">{{ $t('MyPage.EarNingsInfo3') }}</div>
                            <div style="padding: 10px 0">{{ income.day_income || 0 }} USDC</div>
                        </div>

                        <div class="Ear-Btn" @click="Goto(7)">
                            <div class="Btn">{{ $t('MyPage.EarNingsBtn') }}</div>
                        </div>
                    </div>

                    <div class="MyListArr">
                        <div class="ArrData" @click="Goto(0)">
                            <div>{{ $t('MyPage.List1') }}</div>
                            <div class="ArrImg">
                                <el-icon>
                                    <ArrowRight />
                                </el-icon>
                            </div>
                        </div>

                        <div class="ArrData" @click="Goto(1)">
                            <div>{{ $t('MyPage.List2') }}</div>
                            <div class="ArrImg">
                                <el-icon>
                                    <ArrowRight />
                                </el-icon>
                            </div>
                        </div>

                        <div class="ArrData" @click="Goto(2)">
                            <div>{{ $t('MyPage.List3') }}</div>
                            <div class="ArrImg">
                                <el-icon>
                                    <ArrowRight />
                                </el-icon>
                            </div>
                        </div>

                        <div class="ArrData" @click="Goto(3)">
                            <div>{{ $t('MyPage.List4') }}</div>
                            <div class="ArrImg">
                                <el-icon>
                                    <ArrowRight />
                                </el-icon>
                            </div>
                        </div>

                        <div class="ArrData" @click="Goto(4)">
                            <div>{{ $t('MyPage.List5') }}</div>
                            <div class="ArrImg">
                                <el-icon>
                                    <ArrowRight />
                                </el-icon>
                            </div>
                        </div>

                        <div class="ArrData" @click="Goto(5)">
                            <div>{{ $t('MyPage.List6') }}</div>
                            <div class="ArrImg">
                                <el-icon>
                                    <ArrowRight />
                                </el-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="server" draggable="true">
            <img src="@/assets/Img/Imge/Serve.svg" />
        </div>
        <div class="Daik" @click="DaiKPop()" v-if="loan.loan_open == 1">
            <img src="@/assets/Img/Imge/Daik.svg" />
        </div>
    </div>

    <Transition name="outPage">
        <div class="IntPage" v-if="!PageFlag" style="transition: all 0.5 ease-out">
            <component :is="tabs[PageNameIndex]" @PageZ="setPageFlag"></component>
        </div>
    </Transition>

    <Teleport to="body">
        <div>
            <AppBar :Orindex="4"></AppBar>
        </div>
    </Teleport>

    <Transition name="DaikPop">
        <div class="ZheDan" @click="CreatePopDaik()" v-if="DaiK"></div>
    </Transition>
    <Transition name="DaikPop">
        <LoansPage v-if="DaiK" class="LoansPage" @emit="ClosePop()" />
    </Transition>


</template>

<script setup>
import { onMounted, ref, defineEmits, watch, computed } from "vue";
import LoansPage from "./MyPage/LoansPage.vue";
import AskPage from "./MyPage/AskPage.vue";
import OrderRecord from "./MyPage/OrderRecord.vue";
import HistoryRecord from "./MyPage/HistoryRecord.vue";
import BorMRecord from "./MyPage/BorMRecord.vue";
import LandPage from "./MyPage/LandPage.vue";
import HelpPage from "./MyPage/HelpPage.vue";
import LandTop from "./LandTop.vue";
import AppBar from "./AppBar.vue";
import MsgPage from "./MsgPage.vue";
import TxMPage from "./MyPage/TxMoney.vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { disconnect, getAccount } from "@wagmi/core";
import config from "@/utils/configweb3";
import { useI18n } from 'vue-i18n';
import { storeMain } from "@/stores/index.js";

const { t } = useI18n();
const UserName = ref(localStorage.getItem("UserName"));
const tabs = [
  AskPage,
  OrderRecord,
  HistoryRecord,
  BorMRecord,
  LandPage,
  HelpPage,
  MsgPage,
  TxMPage,
];
const PageNameIndex = ref("");
const PageFlag = ref(true);
const emits = defineEmits(["FlagZ"]);
const router = useRouter();
const scrollPosition = ref(0);  // 添加保存滚动位置的变量
const DaiK = ref(false)
const mainStore = storeMain();

const UserData = ref({})
const income = ref({

})
const loan = ref({})

onMounted(async() => {
  document
    .querySelector(".MyPages")
    .style.setProperty("padding", "75px 25px 125px 25px");
  TuoDO();
    const data = (await mainStore.GetUserData());
    console.log(data)
    UserData.value = data.UserData;
    income.value = data.income;
    loan.value = data.loan;
    console.log(loan.value)
});

function setPageFlag(data) {
  if (data == "TopIndex") {
    PageNameIndex.value = 6;
    PageFlag.value = false;
  } else {
    PageFlag.value = true;
  }
}

function Goto(i) {
  PageNameIndex.value = i;
  PageFlag.value = false;
}

watch(PageFlag, (newVal, oldVal) => {
    if (oldVal) {
        // 保存滚动位置
        scrollPosition.value = document.querySelector("#app").scrollTop;
        
    } else {
        // 恢复滚动位置
        document.querySelector(".MyPages").scrollTop = scrollPosition.value;
    }
    emits("FlagZ", PageFlag.value);
});

function TuoDO() {
  const Ser = document.querySelector(".server");

  let isDragging = false,
    imgY,
    imgX,
    setImgY,
    setImgX,
    imgWidth = Ser.clientWidth,
    imgHeight = Ser.clientHeight;

  Ser.addEventListener("touchstart", (e) => {
    isDragging = true;
    imgY = e.touches[0].clientY - Ser.getBoundingClientRect().top;
    imgX = e.touches[0].clientX - Ser.getBoundingClientRect().left;
    Ser.style.cursor = "grabbing";
    e.preventDefault(); // 阻止默认行为
  });

  Ser.addEventListener("touchmove", (e) => {
    setImgY = e.touches[0].clientY - imgY;
    setImgX = e.touches[0].clientX - imgX;

    const maxLeft = window.innerWidth - imgWidth;
    const maxTop = window.innerHeight - imgHeight;

    if (setImgX < 0) setImgX = 0;
    if (setImgY < 0) setImgY = 0;
    if (setImgX > maxLeft) setImgX = maxLeft;
    if (setImgY > maxTop) setImgY = maxTop;
    // if(window.innerWidth / 2 >= setImgX){
    //     Ser.style.right = 'none';
    //     Ser.style.Letf = '0';
    // }else{
    //     setImgX = 0;
    // }
    Ser.style.left = setImgX + "px";
    Ser.style.top = setImgY + "px";
    e.preventDefault(); // 阻止默认行为
  });

  Ser.addEventListener("touchend", (e) => {
    console.log(Ser.getBoundingClientRect().left + imgWidth, window.innerWidth);
    if (Ser.getBoundingClientRect().left + imgWidth >= window.innerWidth / 2) {
      Ser.style.left = window.innerWidth - imgWidth + "px";
    } else {
      Ser.style.left = 0;
    }
    isDragging = false;
    Ser.style.cursor = "grab";
  });
}

async function Logout() {
  const { connector } = getAccount(config)
  await disconnect(config, connector)
  localStorage.setItem("UserName", "");
  await mainStore.Logout();
  mainStore.token = "";
  mainStore.username = "";
  ElMessage({
    message: t('MyPage.msg'),
    type: "success",
    plain: true,
  });
  router.replace("/Login");
}

function DaiKPop(){
    document.body.style.overflow = 'hidden';
    DaiK.value = true;
}

function CreatePopDaik() {
    document.body.style.overflow = '';
    DaiK.value = false;
}

function ClosePop(){
    document.body.style.overflow = '';
    DaiK.value = false;
}
</script>

<style scoped>
.DaikPop-enter-active,
.DaikPop-leave-active {
    transition: opacity 0.5s ease;
    /* 过渡的持续时间和效果 */
}

.DaikPop-enter-from,
.DaikPop-leave-to {
    opacity: 0;
    /* 初始和结束时的透明度 */
}

.DaikPop-enter-to,
.DaikPop-leave-from {
    opacity: 1;
    /* 过渡结束时的透明度 */
}
</style>