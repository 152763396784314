export default{
  "home": {
    "HtD1": "เล่นร่วมกัน สร้างคุณค่า",
    "HtD2": "สร้างและเป็นเจ้าของที่ดินเสมือนของคุณ",
    "HtD3": "มาร่วมกันสร้างโลกใหม่",
    "Btn": "ไปสำรวจ",
    "info1": "ผู้ใช้ทั้งหมด",
    "info2": "ผู้ใช้ที่ใช้งาน",
    "info3": "ปริมาณการซื้อขายรายวัน + รายเดือน + รายไตรมาส",
    "BoxTop": "ข้อมูลพื้นฐานของเมต้าเวิร์ส (ที่ดินเสมือน)",
    "desc": "เมต้าเวิร์สสามารถมองว่าเป็นการจำลองโลกจริง สามารถเชื่อมต่อและซิงค์ได้ตลอดเวลา สิ่งอำนวยความสะดวกจริงถูกออกแบบมาให้เหมาะสำหรับการสังคม การเรียนรู้ ธุรกิจ ความบันเทิง ความร่วมมือ และเกม",
    "infoTop": "สร้างสินทรัพย์เกมที่ไม่ซ้ำใครผ่าน MetaSpaces และทำให้มันกลายเป็นโทเค็น ระบบจะไม่เข้าไปมีส่วนเกี่ยวข้องกับการดำเนินงานและทิศทางการสร้างใน MetaSpaces ผู้ใช้มีสิทธิ์เต็มที่ในการตัดสินใจมูลค่าของสินทรัพย์ที่สร้างใน MetaSpaces",
    "li1": "โลกเสมือนบล็อกเชน",
    "li2": "รับประกันอย่างแน่นหนา",
    "li3": "ความถูกต้องของต้นกำเนิด",
    "li4": "สามารถซื้อขายผ่านตลาดแบบกระจายศูนย์",
    "li5": "สร้างตามมาตรฐานความปลอดภัยที่ได้รับการตรวจสอบ",
    "swiperTop": "พันธมิตร",
    "caseTop": "กรณีศึกษา"
  },
  "Build": {
    "label1": "ยังไม่สร้าง",
    "label2": "สร้างแล้ว",
    "labeltext": "ไม่มีข้อมูล"
  },
  "MyPage": {
    "Score": "คะแนนเครดิต",
    "JeCount1": "จำนวนเงินที่ถูกล็อก",
    "JeCount2": "จำนวนเงินที่สามารถใช้ได้",
    "EarNingsTop": "รายได้",
    "EarNingsInfo1": "รายได้รวม",
    "EarNingsInfo2": "รอถอน",
    "EarNingsInfo3": "รายได้วันนี้",
    "EarNingsBtn": "ถอนรายได้",
    "List1": "รางวัลการเชิญ",
    "List2": "ประวัติการสั่งซื้อ",
    "List3": "ประวัติ",
    "List4": "ประวัติการกู้ยืม",
    "List5": "ที่ดินที่ประมูลแล้ว",
    "List6": "ศูนย์ช่วยเหลือ",
    "msg": "ออกจากระบบสำเร็จ"
  },
  "MyTxPage": {
    "Top": "ถอนรายได้",
    "Top2": "ถอน USDC ไปยังกระเป๋าเงินของคุณ",
    "info1": "ยอด USDC ของแพลตฟอร์ม",
    "info2": "กรุณากรอกจำนวน USDC ที่คุณต้องการแลกเปลี่ยน",
    "btn": "ทั้งหมด",
    "info3": "ค่าธรรมเนียม",
    "info4": "ยอดที่ได้รับจริง",
    "txmBA": "คำขอถอนของคุณจะถูกส่งไปยังกระเป๋าเงินของคุณภายใน 24 ชั่วโมง",
    "txmBB": "ยืนยันการถอน",
    "Msg1": "ส่งใบสมัครเรียกเก็บเงินแล้วและกำลังรอการตรวจสอบ",
    "Msg2": "การถอนล้มเหลว จำนวนเงินที่ถอนต้องไม่เกินยอดเงินในแพลตฟอร์ม"
  },
  "Order": {
    "Top": "ประวัติการสั่งซื้อ",
    "wc": "ไม่มีข้อมูล",
    "sb": "ไม่มีข้อมูล",
    "ywc": "เสร็จสิ้น",
    "ysb": "ล้มเหลว"
  },
  "History": {
    "Top": "ประวัติ",
    "tis": "ไม่มีข้อมูล",
    "ShuoYi": "รายได้จากคำสั่งซื้อ",
    "JiLu": "บันทึกรายการถอน"
  },
  "Bor": {
    "Top": "ประวัติการกู้ยืม",
    "tis": "ไม่มีข้อมูล"
  },
  "Land": {
    "Top": "ที่ดินที่ประมูลแล้ว",
    "tis": "ไม่มีข้อมูล"
  },
  "Ask": {
    "Top": "รางวัลการเชิญ",
    "info1": "รางวัลการเชิญ",
    "info2": "ขนาดทีม",
    "info3": "ผู้ใต้บังคับบัญชาโดยตรง",
    "info4": "รายได้รวม",
    "info5": "รายได้วันนี้",
    "a1": "บันทึกการเชิญ",
    "btn1": "รับรางวัล",
    "AskUrlText": "ลิงก์เชิญ",
    "btn2": "ไปเชิญ",
    "tab1": "ที่อยู่",
    "tab2": "เวลา",
    "lab1": "บันทึกสินทรัพย์",
    "lab2": "ระดับหนึ่ง",
    "lab3": "ระดับสอง",
    "lab4": "ระดับสาม"
  },
  "AskRe": {
    "Top": "บันทึกการเชิญ",
    "text": "ไม่มีข้อมูล"
  },
  "Help": {
    "Top": "ศูนย์ช่วยเหลือ",
    "text1": "Meta land อนุญาตให้ผู้ใช้ใช้ USDC เพื่อประมูลที่ดินเสมือน และทำการเก็งกำไรจากที่ดินและรับรายได้จากที่ดินเสมือน ดังนั้น เหมือนกับในชีวิตจริง ซื้อที่ดินและทำการก่อสร้างที่ดินเพื่อขายในราคาที่สูงขึ้น ขณะสร้างเสร็จ ผู้ใช้สามารถมี NFT ที่สร้างขึ้นเอง",
    "text2": "ที่ดิน C ราคา: 500. อัตราดอกเบี้ยรายวัน 1.3%, ระยะเวลา: 3 วัน. ที่ดิน B ราคา: 5,000. อัตราดอกเบี้ยรายวัน 1.5%, ระยะเวลา: 5 วัน. ที่ดิน A ราคา: 30,000. อัตราดอกเบี้ยรายวัน 1.9%, ระยะเวลา: 7 วัน. ที่ดิน S ราคา: 100,000. อัตราดอกเบี้ยรายวัน 2.5%, ระยะเวลา: 15 วัน. ที่ดิน SS ราคา: 300,000. อัตราดอกเบี้ยรายวัน 3.2%, ระยะเวลา: 20 วัน. ที่ดิน SSS ราคา: 500,000. อัตราดอกเบี้ยรายวัน 4.0%, ระยะเวลา: 30 วัน. หมายเหตุ: ที่ดินระดับ S หรือสูงกว่า (รวมถึงที่ดินระดับ S) เท่านั้นที่สามารถทำการก่อสร้างและสร้างผลกำไรรายวันมากขึ้นได้",
    "tte1": "ผู้คนสามารถทำเงินใน MetaLand ได้อย่างไร",
    "tte2": "เกี่ยวกับการวางแผนที่ดินใน Meta Land"
  },
  "Login": {
    "lab1": "เชื่อมต่อกระเป๋าเงิน ไปสำรวจ"
  },
  "AppBar": {
    "text1": "หน้าแรก",
    "text2": "แผนที่",
    "text3": "สร้าง",
    "text4": "สินทรัพย์ส่วนตัว"
  },
  "MsgPage": {
    "Top": "การแจ้งเตือน",
    "tte": "ไม่มีข้อมูล"
  },
  "QuickPurchase": {
    "btnText": "ซื้อด่วน",
    "landLvl": "ระดับที่ดิน",
    "onAuction": "กำลังประมูล",
    "earningRate": "อัตราผลตอบแทน",
    "earnings": "รายได้",
    "size": "ขนาด",
    "rules": "กฎ",
    "bidding": "ประมูล",
    "blockchain": "บล็อกเชน",
    "ethereum": "อีเธอเรียม"
  },
  "QuickPurchaseRules": {
    "title": "ฉันสามารถทำอะไรกับที่ดินได้บ้าง?",
    "rule1": "รับรางวัลพิเศษ",
    "rule2": "การจำนำพิเศษสำหรับผู้ถือ MetaLand",
    "rule3": "ขายที่ดินของคุณและรับรายได้",
    "rule4": "เผยแพร่และสร้างรายได้จากผลงานของคุณ"
  },
  "BuildingDetail": {
    "coordinates": "พิกัด",
    "owner": "เจ้าของ",
    "earningRate": "อัตราผลตอบแทน",
    "earnings": "รายได้",
    "size": "ขนาด",
    "token": "โทเค็น"
  },

  "LoansTopName": "กฎการกู้ยืม",
  "LoansBtn": "ยืนยันการสมัครกู้ยืม",
  "Loans_L1": "เครดิต",
  "Loans_L2": "ยินดีด้วย",
  "Loans_L3": "คะแนนเครดิตของคุณดีเยี่ยม คุณสามารถสมัครกู้ยืมได้ เพียงแค่ส่งใบสมัคร<br/>ฝ่ายบริการลูกค้าจะกำหนดวงเงินกู้ตามคะแนนเครดิตของคุณ",
  "Loans_L4": "ยืนยันการสมัคร",
  "Loans_L5": "สมัครกู้ยืมตามคะแนนเครดิต",
  "Loans_L6": "ได้รับเงินกู้",
  "Loans_L7": "ทำภารกิจระดับสูงให้เสร็จสิ้น",
  "Loans_L8": "ทำภารกิจอย่างต่อเนื่องและสะสมคะแนนเครดิต วงเงินกู้สูงสุดคือ",
  "Loans_L9": "จำนวนเงิน",
  "Loans_L10": "วัน",
  "Loans_L11": "ดอกเบี้ย",
  "Loans_L12": "ยอมรับ",
  "Loans_L13": "จำนวนเงินลงทุน",
  "Loans_L14": "อัตราผลตอบแทน",

  "Build_L1": "การสร้าง",

  "BOT_L1": "การสร้างที่ดิน",
  "BOT_L2": "ราคาที่ดิน",
  "BOT_L3": "สร้างทันที",
  "BOT_Sel1": "ที่อยู่อาศัย",
  "BOT_Sel2": "สาธารณะ",
  "BOT_L4": "เจ้าของ",
  "BOT_L5": "ตำแหน่ง:",
  "BOT_L6": "รายได้พื้นฐาน:",
  "BOT_L7": "รายได้จากการสร้าง:",
  "BOT_L8": "รายได้:",
  "BOT_L9": "เลือกวัตถุประสงค์ในการสร้าง:",
  "BOT_L10": "เลือกประเภทการสร้าง:",
  "BOT_L11": "กรุณาเลือกประเภทการสร้าง:",
  "BOT_L12": "ค่าธรรมเนียมการสร้าง",
  "BOT_L13": "ถอน NFT",

  "Land_L1": "จำนวนเงินประมูล",
  "Land_L2": "รายได้ระหว่างช่วงเวลา",
  "Land_L3": "สิ้นสุด",
  "Land_L4": "สถานที่",
  "Land_L5": "อัตราผลตอบแทน",
  "Land_L6": "รายได้",
  "Land_L7": "วัน",

  "Bor_L1": "จำนวนเงินกู้",
  "Bor_L2": "วัน",
  "Bor_L3": "กำลังดำเนินการ",
  "Bor_L4": "เวลา",
  "Bor_L5": "ดอกเบี้ย",
  "Bor_L6": "เสร็จสิ้น",

  "Not_data_Msg": "ไม่มีข้อมูลเพิ่มเติม"
}