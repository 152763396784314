<template>
  <div v-if="modalStatus.isBiddingRule" class="modal-bidding_rules">
    <div class="modal-container">
      <div @click="modalStatus.setModalBiddingRule(false)" class="modal-close">
        <img :src="ImgClose" alt="" />
      </div>
      <div class="modal-content">
        <div class="land-desc">
          <div class="title"> {{ $t('QuickPurchaseRules.title') }} </div>
          <div class="info-flex">
            <b class="dian">.</b>
            <div class="info-land"> {{ $t('QuickPurchaseRules.rule1') }} </div>
          </div>
          <div class="info-flex">
            <b class="dian">.</b>
            <div class="info-land"> {{ $t('QuickPurchaseRules.rule2') }} </div>
          </div>
          <div class="info-flex">
            <b class="dian">.</b>
            <div class="info-land"> {{ $t('QuickPurchaseRules.rule3') }} </div>
          </div>
          <div class="info-flex">
            <b class="dian">.</b>
            <div class="info-land"> {{ $t('QuickPurchaseRules.rule4') }} </div>
          </div>
          <img
            id="DialogLandDescCloseBtn"
            class="close-img"
            src="/images/close.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div
      @click="modalStatus.setModalBiddingRule(false)"
      class="overlay-modal"
    ></div>
  </div>
</template>

<script setup>
import { useModalStatus } from "../../stores/modal";
import ImgClose from "../../assets/Img/Imge/close.png";

import "./modal.style.css";

const modalStatus = useModalStatus();
</script>

<style>
.modal-bidding_rules {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 31;
}

.land-desc .title {
  font-weight: bold;
  color: #68ff65;
  padding-bottom: 10px !important;
}

.land-desc .info-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 4px;
}
.land-desc .info-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 4px;
}
.land-desc .info-flex .dian {
  color: white;
  font-size: 30px;
  margin: -20px 10px 0 0;
}
.land-desc .info-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 4px;
}
.info-land {
  color: white;
}
</style>
