<template>
    <van-loading
        style="z-index: 999999;width: 100vw;height: 100vh;display: flex;align-items: center;justify-content: center;"
        size="100" v-if="mainStore.loading" />
    <div class="PageTop" v-if="!mainStore.loading">
        <div class="PageTop-info">
            <div class="A1" @click="Goto()">
                <el-icon>
                    <ArrowLeft />
                </el-icon>
            </div>
            <div class="A2">{{ $t('Bor.Top') }}</div>
        </div>

        <div>
            <!-- <div class="Page-BckImg">
                <img src="@/assets/Img/Imge/8.svg">
                <div>{{ $t('Bor.tis') }}</div>
            </div> -->

            <van-list v-model:loading="loading" :finished="finished" :finished-text="$t('Not_data_Msg')" @load="onLoad">
                <div v-for="(data, index) in BorData" :key="index" class="css-v0gcpp">
                    <div class="item_view">
                        <div class="title">{{ $t('Bor_L1') }}</div>
                        <div class="value">{{ data.interest }} USDC {{ $t('Bor_L5') }}</div>
                    </div>
                    <div class="item_view">
                        <div class="value">
                            <img src="@/assets/Img/Imge/9.svg" alt="" style="padding-right: 6px;">{{ data.amount }} USDC
                        </div>
                        <div class="value">{{ data.period }}{{ $t('Bor_L2') }}</div>
                    </div>
                    <div class="item_view" v-if="data.status == 0">
                        <div class="value">{{ $t('Bor_L3') }}</div>
                        <div class="value">{{ ConvertTime(data.addtime) }} {{ $t('Bor_L4') }}</div>
                    </div>

                    <div class="item_view" v-if="data.status == 1">
                        <div class="value">{{ $t('Bor_L6') }}</div>
                        <div class="value">{{ ConvertTime(data.enddate) }} {{ $t('Bor_L4') }}</div>
                    </div>
                </div>
            </van-list>

        </div>
    </div>
</template>

<script setup>
import { defineEmits, onMounted, ref } from 'vue'
import { storeMain } from "@/stores/index.js";

const mainStore = storeMain();
const loading = ref(false);
const finished = ref(false);
const list = ref([]);
const emits = defineEmits(['PageZ'])
const BorData = ref([])
let Pageindex = 1;

onMounted(async() => {
    mainStore.loading = true;
    GetData()
})

// 时间戳转换
function ConvertTime(time) {
    let date = new Date(Number(time) * 1000);
    return date.getFullYear() + '-' +
        String(date.getMonth() + 1).padStart(2, '0') + '-' +
        String(date.getDate()).padStart(2, '0') + ' ' +
        String(date.getHours()).padStart(2, '0') + ':' +
        String(date.getMinutes()).padStart(2, '0') + ':' +
        String(date.getSeconds()).padStart(2, '0');
}

async function GetData(){
    loading.value = true;
    let arr = await mainStore.GetDaiKData({page:Pageindex,size:6});

    BorData.value.push(...arr);

    if(arr.length < 6){
        finished.value = true
    }
    loading.value = false;
}

function Goto(){
    emits('PageZ',true)
}

const onLoad = () => {
    if(!finished.value){
        Pageindex++;
        GetData()
    }
};
</script>

<style scoped>
.css-v0gcpp {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    background-color: rgb(38, 50, 64);
    border-radius: 10px;
    padding: 20px 20px 10px 20px;
}

.css-v0gcpp .item_view {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    color: rgb(255, 255, 255);
    margin-bottom: 10px;
    -webkit-box-align: center;
    align-items: center;
}

.css-v0gcpp .title {
    font-size: 18px;
    color: rgb(255, 255, 255);
}

.css-v0gcpp .value {
    font-size: 14px;
    color: rgb(255, 255, 255);
}

img {
    vertical-align: middle;
    border-style: none;
}
</style>