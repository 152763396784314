export default {
  "home": {
    "HtD1": "Play Together • Create Value",
    "HtD2": "And Own Your Own Virtual Land",
    "HtD3": "Let's build a whole new world together",
    "Btn": "Going to Explore",
    "info1": "Total number of users",
    "info2": "Total Active Users",
    "info3": "Daily Transaction Volume",
    "BoxTop": "Metaverse Virtual Land Profile",
    "desc": "Many of the virtual worlds will be digital twins reflecting the real world, linked and synchronized in real time. Others will be designed for entertainment, socialization, gaming, learning, collaboration, or commerce",
    "infoTop": "By building unique in-game assets through MetaSpaces and tokenizing them, we give this right to our users, MetaSpaces thus decouples forever from owning and operating plots of land and all in-game assets, leaving everything up to the user to determine their ultimate value.",
    "li1": "Blockchain Virtual Worlds",
    "li2": "Ownership Security",
    "li3": "Authenticity of Origin",
    "li4": "Can be traded through decentralized marketplaces",
    "li5": "Built using verified security standards",
    "swiperTop": "Partners",
    "caseTop": "Case in point"
  },
  "Build": {
    "label1": "Not built",
    "label2": "Already built",
    "labeltext": "No data available"
  },
  "MyPage": {
    "Score": "Credit Score",
    "JeCount1": "Frozen Amount",
    "JeCount2": "Available Amount",
    "EarNingsTop": "Earnings",
    "EarNingsInfo1": "Total",
    "EarNingsInfo2": "Pending",
    "EarNingsInfo3": "Daily",
    "EarNingsBtn": "Withdraw Earnings",
    "List1": "Invitation Rewards",
    "List2": "Order Records",
    "List3": "Earning Flow Records",
    "List4": "Loan Records",
    "List5": "Bid Land",
    "List6": "Help Center",
    "msg": "Logout Successful"
  },
  "MyTxPage": {
    "Top": "Withdraw Earnings",
    "Top2": "Withdraw USDC to Your Wallet",
    "info1": "Platform USDC Balance",
    "info2": "Enter the Amount of USDC You Want to Exchange",
    "btn": "All",
    "info3": "Service Fee",
    "info4": "Actual Amount Received",
    "txmBA": "Your withdrawal request will be sent to your wallet within 24 hours",
    "txmBB": "Confirm Withdrawal",
    "Msg1": "The application has been submitted and is awaiting review.",
    "Msg2": "Withdrawal Failed, Withdrawal Amount Cannot Exceed Platform Balance"
  },
  "Order": {
    "Top": "Order Records",
    "wc": "No data available",
    "sb": "No data available",
    "ywc": "Completed",
    "ysb": "Failed"
  },
  "History": {
    "Top": "History",
    "tis": "No data available",
    "ShuoYi": "Order income",
    "JiLu": "Withdrawal Records"
  },
  "Bor": {
    "Top": "Loan Records",
    "tis": "No data available"
  },
  "Land": {
    "Top": "Bid Land",
    "tis": "No data available"
  },
  "Ask": {
    "Top": "Invitation Rewards",
    "info1": "Invitation Rewards",
    "info2": "Team Size",
    "info3": "Direct Subordinates",
    "info4": "Gross Revenue",
    "info5": "Daily",
    "a1": "Invitation Records",
    "btn1": "Claim Reward",
    "AskUrlText": "Invitation Link",
    "btn2": "Invitation",
    "tab1": "Address",
    "tab2": "Time",
    "lab1": "All Records",
    "lab2": "Level 1",
    "lab3": "Level 2",
    "lab4": "Level 3"
  },
  "AskRe": {
    "Top": "Invitation Records",
    "text": "No data available"
  },
  "Help": {
    "Top": "Help Center",
    "text1": "MetaL land is a digital living space constructed by human beings using digital technology that maps or transcends the real world, a virtual world that can interact with the real world, and a new type of social system.",
    "text2": "Meta land allows users to bid on virtual land using USDC and profit by bidding on speculation as well as leasing and reclaiming virtual land. So, just like in real life, buy land and complete the construction of the land in order to sell it for a higher price. While completing the construction, the user can own the NFT that he/she has created.",
    "text3": "Land C Price: 500. daily interest rate 1.3%, period: 3 days. Land B Price: 5,000. daily interest rate 1.5 %, period: 5 days. Land A Price: 30,000. daily interest rate 1.9 % . Term: 7 days. Land S Price: 100,000. daily interest rate 2.5% . Period: 15 days. Land SS Price: 300,000. daily interest rate 3.2 % Period: 20 days. Land SSS Price: 500,000. daily interest rate 4.0%. period: 30 days. Note: Only land parcels of S level and above (including S land parcels) can be constructed and generate more daily profit.",
    "tte1": "What is the meta land?",
    "tte2": "What are the ways people can make money in Meta land",
    "tte3": "About the land planning of Meta Land "
  },
  "Login": {
    "lab1": "Connect Wallet to Explore"
  },
  AppBar: {
    text1: "Home",
    text2: "Map",
    text3: "Build",
    text4: "My"
  },
  MsgPage: {
    Top: "Notification",
    tte: "No data available Available"
  },
  QuickPurchase: {
    btnText: "Quick Purchase",
    landLvl: "Land Level",
    onAuction: "OnAuction",
    earningRate: "Earning Rate",
    earnings: "Earnings",
    size: "Size",
    rules: "Rules",
    bidding: "Bidding",
    blockchain: "Blockchain",
    ethereum: "Ethereum"
  },
  QuickPurchaseRules: {
    title: "What I can do with the land?",
    rule1: "Earn Special Rewards",
    rule2: "Exclusive pledges for MetalLand holders",
    rule3: "Sell your land and earn revenue",
    rule4: "Publish and monetize your creations."
  },
  BuildingDetail: {
    coordinates: "Coordinates",
    owner: "Owner",
    earningRate: "Earning Rate",
    earnings: "Earnings",
    size: "Size",
    token: "Token"
  },

   "LoansTopName": "Loan Rules",
  "LoansBtn": "Confirm Loan Application",
  "Loans_L1": "Credit",
  "Loans_L2": "Congratulations",
  "Loans_L3": "Your credit rating is good, you can apply for a loan. Just submit an application<br/>Customer service will determine the loan amount based on your credit rating.",
  "Loans_L4": "Confirm Application",
  "Loans_L5": "Submit loan application based on credit points",
  "Loans_L6": "Obtain Loan",
  "Loans_L7": "Complete higher-level tasks",
  "Loans_L8": "Continue to complete tasks to accumulate credit points. The maximum loan limit is",
  "Loans_L9": "Amount",
  "Loans_L10": "Days",
  "Loans_L11": "Interest",
  "Loans_L12": "Accept",
  "Loans_L13": "Investment Amount",
  "Loans_L14": "Return Percentage",

  "Build_L1": "Construction",

  "BOT_L1": "Construction Land",
  "BOT_L2": "Land Price",
  "BOT_L3": "Build Now",
  "BOT_Sel1": "Residential",
  "BOT_Sel2": "Public",
  "BOT_L4": "Owner",
  "BOT_L5": "Location:",
  "BOT_L6": "Basic Return:",
  "BOT_L7": "Construction Return:",
  "BOT_L8": "Return:",
  "BOT_L9": "Construction Purpose Selection:",
  "BOT_L10": "Construction Type Selection:",
  "BOT_L11": "Please select a construction type:",
  "BOT_L12": "Construction Cost",
  "BOT_L13": "Claim NFT",

  "Land_L1": "Bid Amount",
  "Land_L2": "Return During Period",
  "Land_L3": "End",
  "Land_L4": "Location",
  "Land_L5": "Return Percentage",
  "Land_L6": "Return",
  "Land_L7": "Days",

  "Bor_L1": "Loan Amount",
  "Bor_L2": "Days",
  "Bor_L3": "Applying",
  "Bor_L4": "Time",
  "Bor_L5": "Interest",
  "Bor_L6": "Completed",

  "Not_data_Msg": "No more data"
}
