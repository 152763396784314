<template>
    <van-loading
        style="z-index: 999999;width: 100vw;height: 100vh;display: flex;align-items: center;justify-content: center;"
        size="100" v-if="mainStore.loading" />
    <div class="PageTop" v-if="!mainStore.loading">

        <div class="PageTop-info">
            <div class="A1" @click="Goto()">
                <el-icon>
                    <ArrowLeft />
                </el-icon>
            </div>
            <div class="A2">{{ $t('History.Top') }}</div>
        </div>

        <div>
            <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
                <el-tab-pane :label="$t('History.ShuoYi')" name="shouyi" style="padding-bottom: 70px;">
                    <div class="Page-BckImg" v-if="OrderData.length == 0">
                        <img src="@/assets/Img/Imge/8.svg">
                        <div>{{ $t('History.tis') }}</div>
                    </div>

                    <van-list v-model:loading="loading" :finished="finished" :finished-text="$t('Not_data_Msg')"
                        @load="OrderonLoad" v-if="OrderData.length != 0">
                        <div v-for="(data, index) in OrderData" :key="index" class="css-1t7ooz7">
                            <div class="top">{{ ConvertTime(Number(data.ctime),1) }}</div>
                            <div class="info isFlex">
                                <div class="left">{{ ConvertTime(Number(data.ctime)) }}</div>
                                <div class="right">{{ data.amount }}</div>
                            </div>
                        </div>
                    </van-list>

                </el-tab-pane>
                <el-tab-pane :label="$t('History.JiLu')" name="jilu" style="padding-bottom: 70px;">
                    <div class="Page-BckImg" v-if="WitData.length == 0">
                        <img src="@/assets/Img/Imge/8.svg">
                        <div>{{ $t('History.tis') }}</div>
                    </div>

                    <van-list v-model:loading="loading" :finished="finished" :finished-text="$t('Not_data_Msg')"
                        @load="WitonLoad" v-if="WitData.length != 0">
                        <div v-for="(data, index) in WitData" :key="index" class="css-1t7ooz7">
                            <div class="top">{{ ConvertTime(Number(data.time), 1) }}</div>
                            <div class="info isFlex">
                                <div class="left">{{ ConvertTime(Number(data.time)) }}</div>
                                <div class="right">{{ parseFloat(data.amount) }} USDC</div>
                            </div>
                        </div>
                    </van-list>

                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script setup>
import { ref,defineEmits, onMounted } from 'vue'
import { storeMain } from "@/stores/index.js";

const mainStore = storeMain();
const activeName = ref('shouyi')
const emits = defineEmits(['PageZ'])
const loading = ref(false);
const finished = ref(false);
const OrderData = ref([])
const WitData = ref([])
let PageIndex = 1;

onMounted(async() => {
    mainStore.loading = true;
    await GetOrderYield();
    await GetWit();
})

// 获取订单收益
async function GetOrderYield(){
    let arr = await mainStore.GetOrderYieldData({ page: PageIndex, size: 10 })
    
    for (let i = 0; i < arr.length; i++) {
        OrderData.value.push(arr[i])
    }
    if (arr.length == 0) {
        finished.value = true;
    }
}

// 时间戳转换
function ConvertTime(time,v) {
    let date = new Date(Number(time) * 1000);
    if(v == 1){
        return date.getFullYear() + '-' +
            String(date.getMonth() + 1).padStart(2, '0') + '-' +
            String(date.getDate()).padStart(2, '0')
    }else{
        return date.getFullYear() + '-' +
            String(date.getMonth() + 1).padStart(2, '0') + '-' +
            String(date.getDate()).padStart(2, '0') + ' ' +
            String(date.getHours()).padStart(2, '0') + ':' +
            String(date.getMinutes()).padStart(2, '0') + ':' +
            String(date.getSeconds()).padStart(2, '0');
    }
}

// 获取提领记录
async function GetWit(){
    let arr = await mainStore.GetWitData({ page: PageIndex, size: 10 })

    for(let i = 0;i < arr.length;i ++){
        WitData.value.push(arr[i])
    }
    if(arr.length == 0){
        finished.value = true;
    }
}

function Goto(){
    emits('PageZ',true)
}

async function handleClick(v) {
    PageIndex = 1;
    if(v.props.name == 'shouyi'){
        OrderData.value = [];
        await GetOrderYield();
    }

    if(v.props.name == 'jilu'){
        WitData.value = [];
        await GetWit();
    }
}

const OrderonLoad = async () => {
    PageIndex++;
    await GetOrderYield();
    loading.value = false;
}

const WitonLoad = async () => {
    PageIndex++;
    await GetWit();
    loading.value = false;
}
</script>

<style scoped>
.css-1t7ooz7 {
    border-bottom: 1px solid rgba(255, 255, 255, 0.306);
    padding: 10px 0px;
}

.css-1t7ooz7 .top {
    color: rgb(153, 153, 153);
    font-size: 12px;
}

.css-1t7ooz7 .isFlex {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.css-1t7ooz7 .info {
    color: rgb(238, 238, 238);
    font-size: 14px;
    font-weight: bold;
    padding: 7px 0px;
}
</style>