<template>
    
    <div v-show="PageFlag">
        <div class="LandTopAll">
            <LandTop @PageFlag="setPageFlag"></LandTop>
        </div>
        <div class="HomePage">
            

            <div class="HomeTop">
                <div class="HomeTop-info">
                    <div class="info">
                        <div class="Ht">
                            <div class="Ht1">
                                <img src="@/assets/Img/Imge/1.svg" style="width: 80%;"/>
                            </div>

                            <div class="HtD">
                                <div>{{ $t('home.HtD1') }}</div>

                                <div>{{ $t('home.HtD2') }}</div>

                                <div>{{ $t('home.HtD3') }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="Htbtn">
                    <div style="display: block;width: auto;padding: 4px 30px;height: auto;">{{ $t('home.Btn') }}</div>
                </div>
            </div>

            <div class="UseCount">
                <div class="U">
                    <div class="Ucout">
                        <div class="info">
                            <div>{{ $t('home.info1') }}</div>
                            <div>{{ HomeData.total_user_amount }}</div>
                        </div>
                    </div>

                    <div class="Ucout">
                        <div class="info">
                            <div>{{ $t('home.info2') }}</div>
                            <div>{{ HomeData.active_user_amount }}</div>
                        </div>
                    </div>
                </div>

                <div class="C">
                    <div class="C1">
                        <div style="text-align: center;">{{ $t('home.info3') }}</div>
                        <div>{{ HomeData.trading_volume }} USD</div>
                    </div>
                </div>
            </div>

            <div class="Mp4Box" style="max-width: 100%;">
                <div class="BoxTop">{{ $t('home.BoxTop') }}<img src="@/assets/Img/Imge/4.svg"></div>
                <div class="Mp4">
                    <!-- controls loop autoplay playsinline -->
                    <video preload="metadata" controls loop autoplay playsinline>
                        <source src="@/assets/Mp4/metaverse.f028867e.mp4" type="video/mp4">
                    </video>

                    <div class="desc">{{ $t('home.desc') }}</div>
                </div>
            </div>

            <div class="Home4">
                <img class="meta-land" src="@/assets/Img/Imge/5.png" alt="">
                <div class="Home4-info">
                    <div class="Home4-info-top">{{ $t("home.infoTop") }}</div>
                    <div class="Home4-info-ul">
                        <ul>
                            <li>{{ $t("home.li1") }}</li>
                            <li>{{ $t("home.li2") }}</li>
                            <li>{{ $t("home.li3") }}</li>
                            <li>{{ $t("home.li4") }}</li>
                            <li>{{ $t("home.li5") }}</li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div class="swiper-infor">
                <div class="swiperTop">{{ $t("home.swiperTop") }}<img src="@/assets/Img/Imge/6.svg"></div>
                <swiper :modules = "[Virtual,Autoplay]" 
                breakpoints:
                :autoplay = "swiperOption.autoplay"
                :freeMode = "swiperOption.freeMode"
                :speed="swiperOption.speed"
                :loop = "swiperOption.loop"
                :breakpoints="swiperOption.breakpoints"
                >
                    <swiper-slide
                    v-for="(slideContent, index) in ImgUrlArr"
                    :key="index"
                    :virtualIndex="index"
                    >
                        <img :src="slideContent.url" style="width: 92%; height: 100%;">
                    </swiper-slide>
                </swiper>
            </div>

            <div class="Case">
                <div class="CaseTop">{{ $t("home.caseTop") }}<img src="@/assets/Img/Imge/6.svg"></div>
                <div class="Cases-info">
                    <div class="Case-info">
                        <div><img src="@/assets/Img/Imge/7.png" alt="暂无图片"></div>
                        <div class="info">Justin Bieber</div>
                    </div>
                    <div class="Case-info">
                        <div><img src="@/assets/Img/Imge/7.png" alt="暂无图片"></div>
                        <div class="info">Real Estate Market</div>
                    </div>
                </div>
            </div>

            
        </div>
    </div>
    <div>
        <AppBar :Orindex="1"></AppBar>
    </div>

    <Transition name="outPage">
        <div v-show="!PageFlag">
            <Transition to="outPage">
                <component :is="MsgPage" @PageZ="setPageFlag"></component>
            </Transition>
        </div>
    </Transition>
    
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Virtual,Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import 'swiper/css/autoplay'
import { onMounted, ref } from 'vue';
import LandTop from './LandTop.vue';
import AppBar from './AppBar.vue';
import MsgPage from './MsgPage.vue';
import { storeMain } from '@/stores';

const ImgUrlArr = [{url:require("@/assets/Img/Imge/swiper1.png")},{url:require("@/assets/Img/Imge/swiper2.png")},{url:require("@/assets/Img/Imge/swiper3.png")},
    {url:require("@/assets/Img/Imge/swiper1.png")},{url:require("@/assets/Img/Imge/swiper2.png")},{url:require("@/assets/Img/Imge/swiper3.png")}
];
const PageFlag = ref(true);
const swiperOption = {
    autoplay:{
        delay:5,
        disableOnInteraction: false
    },
    speed:3000,
    freeMode:true,
    loop:true,
    breakpoints: {
        // when window width is >= 320px
        320: {
        slidesPerView: 3,
        spaceBetween: 10
        },
        // when window width is >= 480px
        480: {
        slidesPerView: 3,
        spaceBetween: 10
        },
        // when window width is >= 640px
        640: {
        slidesPerView: 4,
        spaceBetween: 40
        }
    }
}
const mainStore = storeMain()
const HomeData = ref('');
function setPageFlag(data){
    if(data == 'TopIndex'){
        PageFlag.value = false;
    }else{
        PageFlag.value = true;
    }
}

onMounted(async() => {
    document.querySelector('.HomePage').style.setProperty('padding', '75px 25px 125px 25px');

    // 调用首页数据API
    HomeData.value = await mainStore.GetHomeData();
})
</script>

<style>
.swiper-wrapper{
    transition-timing-function: linear !important;
}
</style>