import axios from "axios";
import { URL } from "../../config";
import { storeMain } from "@/stores";
import { showToast, closeToast, showLoadingToast, showNotify } from "vant";

const Server = axios.create({
  baseURL: URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});

// 请求拦截
Server.interceptors.request.use(
    (config) => {
        const mainStore = storeMain();
        const token = mainStore.token;
        if(token){
            config.headers.Authorization = `Bearer ${token}`
            // config.headers["lang"] = localStorage.getItem("Language");
        }

        // 在请求中添加固定的参数
        if (config.method === "get") {
            // 对于 GET 请求，将参数添加到 URL
            config.params = {
            ...config.params,
            };
        } else if (config.method === "post") {
            // 对于 POST 请求，将参数添加到 data
            config.data = {
              ...config.data,
              lang: localStorage.getItem("Language"),
            };
        }
        return config;
    },
    (error) => {
        // 处理请求错误
        return Promise.reject(error);
    },
);

// 响应拦截
Server.interceptors.response.use(
    res => {
        const mainStore = storeMain();
    
        const { code, msg } = res.data;
        mainStore.loading = false;
        if (code == -1) {
          showToast(msg);
          return new Promise(() => {});
        } else if (code == 401) {
          showToast(msg);
          return new Promise(() => {});
        } 

        return res;
    },
    error => {
        const mainStore = storeMain();
        if(!error.response){
            showToast("error");
        }
        mainStore.loading = false;
        return new Promise(() => {});
    }
)
export default Server;