<template>
  <div class="Login">
    <div class="L1">
      <img src="@/assets/Img/Imge/1.svg" />
    </div>
    <div class="L2">
      <div>{{ $t("home.HtD1") }}</div>

      <div>{{ $t("home.HtD2") }}</div>

      <div>{{ $t("home.HtD3") }}</div>
    </div>

    <div class="Login-btn">
      <w3m-button :label="$t('Login.lab1')" />
    </div>

    <div>
      <img class="gif" src="@/assets/Img/Imge/Login.gif" />
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted } from "vue";
import { watchAccount } from "@wagmi/core";

import "@/assets/css/index.css";
import router from "@/assets/Js/router";
import config from '../../utils/configweb3'
import { storeMain } from "@/stores/index.js";
import { showToast } from "vant";

let unwatch;
const mainStore = storeMain();

onMounted(async () => {
  unwatch = watchAccount(config, {
    async onChange(data) {
        const arr = {
            'address':data.address,
            'timestamp': Math.floor(Date.now() / 1000),
            'sign':'',
            'invite_code':'',
        }

      if (data.isConnected) {
          await mainStore.handleLoginFlag(arr)  
        localStorage.setItem("UserName", data.address);
        router.replace("/");
      }
    },
  });
});

onUnmounted(() => {
  unwatch();
});

</script>
