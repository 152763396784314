<template>
    <div>
        <div class="LandTopAll">
            <LandTop @PageFlag="setPageFlag"></LandTop>
        </div>

        <component :is="PageName" @PageZ="setPageFlag" @BOT="SetPageName" :PageData="PageData" @Build="SetPageNameTow"></component>

    </div>

    <div>
        <AppBar :Orindex="3"></AppBar>
    </div>


</template>

<script setup>
import { index, LandTop, AppBar, MsgPage, BOTPage, BuildPage } from '@/components/BuildPage/index.js'
import { ref } from 'vue';

const PageFlag = ref(true);
const PageName = ref(index)
const PageData = ref({})

// 信息提示打开和关闭
function setPageFlag(data){
    console.log(data)
    if(data == 'TopIndex'){
        PageName.value = MsgPage
        PageFlag.value = false;
    }else{
        PageName.value = index
        PageFlag.value = true;
    }
}

// 建筑土地
function SetPageName(data){
    if (data == true) {
        PageName.value = index;
    } else {
        PageName.value = BOTPage;
        PageData.value = data;
    }
}

// 已建设土地
function SetPageNameTow(data){
    if (data == true) {
        PageName.value = index;
    } else {
        PageName.value = BuildPage;
        PageData.value = data;
    }
}

</script>

