<template>
    <div style="position: fixed;">
        <div v-if="show">
            <div
                style="background: white;width: 100%;height: 346px;border-radius: 10px;padding: 20px;box-sizing: border-box;">
                <div style="font-size: 16px;font-weight: bold;text-align: center;padding-bottom: 20px;">
                    {{ $t('LoansTopName') }}
                </div>

                <div style="font-size: 14px;color: rgb(192, 192, 192);height: 220px;overflow: auto;">
                    This Credit Lending User Service Agreement (the "Agreement") is an agreement between the Platform
                    (also
                    referred to as the "Platform") and the user ("you") in relation to your use of the lending service
                    provided by the Platform (the "Service"). (the "Service"). By accessing, logging in to, and using
                    the
                    Platform and any of the Services provided, you acknowledge that you have read, understood and
                    accepted
                    all of the terms and conditions set forth in this Service Agreement. If you do not agree to any of
                    the
                    terms or conditions of this Agreement, please stop accessing the Platform and stop using the
                    Services.
                    If you continue to access the Platform or use the Service, you are deemed to have unconditionally
                    agreed
                    to the entire contents of this Agreement.1. Scope and Application of this Agreement. This Agreement
                    applies to your use of the Platform for Credit lending activities.2. Risk Notice: When you make
                    credit
                    borrowing and lending in this platform, you may get higher investment income. In order for you to
                    better
                    understand the risks involved, in accordance with the relevant laws and regulations, administrative
                    rules and relevant national policies, we would like to provide you with a reminder of the risks
                    involved
                    in pledging and lending, please read it carefully and in detail. The risks involved in your pledging
                    and
                    lending on the platform include but are not limited to(1) Risk warning: The platform guarantees that
                    all
                    information, programs, texts, etc. contained in the platform are completely safe and free from any
                    interference and damage by viruses, Trojan horses and other malicious programs. If you login, browse
                    any
                    other links, information, data, etc. are your personal decision and bear the risk and possible
                    loss.(2)
                    Risk of force majeure: The platform is not liable for any loss due to maintenance of information
                    network
                    equipment, failure of information network connection, failure of computer, communication or other
                    systems, power failure, encounter with hacker attacks, weather, accidents, strikes, labor disputes,
                    riots, uprisings, riots, lack of productivity or production materials, fire, flood, storm,
                    explosion,
                    war, bank or other partner reasons, digital asset market collapse, governmental acts, orders of
                    judicial
                    or administrative authorities, other acts beyond the control of the platform or beyond the
                    platform's
                    ability to control, or third parties, which result in the inability to provide services or delays in
                    services, and the losses caused to you, the platform shall not be liable.(3) Risk of being seized
                    and
                    frozen. When the authorities present the corresponding investigation documents and request the
                    platform
                    to cooperate with the investigation of your account, assets or transaction data in the platform, or
                    take
                    measures to seize, freeze or transfer your account, the platform will assist in providing your
                    corresponding data or carry out the corresponding operation in accordance with the requirements of
                    the
                    authorities, and the user will bear all the responsibility for the privacy leakage, account
                    inoperability and losses caused by this. Responsibility.(4) Other risks: you shall bear the losses
                    arising from the following circumstances: (a) losses due to your loss of account, forgotten
                    password,
                    improper operation, investment decision mistakes, etc.; (b) losses caused by malicious operation by
                    others after the online commission and hotkey operation is completed without timely exit; (c) losses
                    caused by entrusting others to act as your agent for pledging and lending on the platform; (d) Other
                    unexpected events and losses not caused by the Platform.Special Note: You should control your own
                    risk
                    by participating in lending on this platform, assessing the value of your asset investment and
                    investment risk, and bearing the economic risk of losing your entire investment; you should conduct
                    transactions according to your own economic conditions and risk tolerance.3. Definitions. For
                    purposes
                    of this Agreement, the following terms shall have the following definitions.(1) Borrowing and
                    Lending:
                    means the act of you pledging certain USDC assets on the Platform and borrowing certain USDC assets
                    from
                    the Platform.(2) Funds: It means that you voluntarily apply for a loan from the Platform and use the
                    funds of the Platform for bidding, transferring and withdrawing operations（3） Apply for a loan:
                    After
                    you log in to the platform, you can click "Receive" on the loan function page. After you click
                    "receive", the system will automatically review your loan application, when the loan application is
                    approved the system will automatically issue the loan funds to your account, and will temporarily
                    transfer funds from your wallet to your account as a loan funds, the account funds and loan funds
                    can be
                    used for NFT bidding transactions. You can withdraw all the funds in your account after you return
                    the
                    borrowed funds and interest within the specified time.（4） Lending interest: It refers to the
                    interest
                    you need to pay to the platform when you use the lending service provided by the platform, the
                    interest
                    is calculated according to the daily interest rate, the daily interest rate is (0.1% to 0.3%) For
                    example: you participate in a 10-day loan of 50,000 USDC, if the daily interest rate is 0.1%, then
                    your
                    interest for 10 days is 500 USDC.（5） Lending days: 7 days to 30 days（6） Use of borrowed assets: The
                    borrowed assets will be issued to your account, and you can use them for bidding and trading of
                    works on
                    the platform to gain income.（7） Repayment provisions. The funds in the account shall not be used for
                    repayment, and the profit gained on the platform during the borrowing period shall not be used for
                    repayment. Platform users need to deposit additional corresponding repayment funds and interest to
                    your
                    wallet for repayment. After the deposit is completed, you can apply to the customer service
                    department
                    to return the borrowed funds, and after the repayment approval is completed, you can withdraw any
                    funds
                    in your account at will.（8）Voluntary behavior. Your borrowing and lending on the Platform is
                    entirely a
                    voluntary transaction based on your own financial situation and understanding of the risks involved.
                    Once a lend is in effect, it is irrevocable.（9）Application for extension. You need to provide your
                    identity document and the reason for the extension. The specific number of days of extension is
                    subject
                    to the result of the audit department's review of the extension.（10） Users shall comply with the
                    relevant laws and regulations and the provisions of this Agreement (including the version updated by
                    the
                    Platform from time to time) when using the Services.（11） Any agreement and terms signed between the
                    user
                    and the platform in relation to the use of the platform services and any disputes arising therefrom
                    shall be the sole responsibility of the platform to resolve.
                </div>

                <div style="text-align: center;font-size: 18px;padding-top: 16px;">
                    <span style="color: rgb(179, 252, 237);font-weight: bold;" @click="show = false">{{ $t('LoansBtn')
                        }}</span>
                </div>
            </div>
        </div>

        <div v-if="!show">
            <div class="LoansTab">
                <div class="top-view">{{ $t('Loans_L1') }} 60</div>
                <div class="title_text">{{ $t('Loans_L2') }}</div>
                <div class="desc_text" v-html="$t('Loans_L3')"></div>
                <div class="info-view">
                    <div class="info-view-inner">
                        <div class="info-title-view">{{ $t('Loans_L4') }}</div>
                        {{ $t('Loans_L5') }}
                    </div>
                </div>

                <div class="info-view">
                    <div class="info-view-inner">
                        <div class="info-title-view">{{ $t('Loans_L6') }}</div>
                        {{ $t('Loans_L7') }}
                    </div>
                </div>

                <div class="content-view">
                    <div class="content-view-inner">
                        <div class="content-text">{{ $t('Loans_L8') }}</div>
                        <div class="line"></div>
                        <div class="content-info">
                            <span class="flex3">{{ $t('Loans_L9') }}</span>
                            <span class="flex2">{{ $t('Loans_L10') }}</span>
                            <span class="flex3">{{ $t('Loans_L11') }}</span>
                        </div>
                        <div class="line"></div>
                        <div class="content-info">
                            <span class="flex3">{{ data.loan_amount }} USDC</span>
                            <span class="flex2">{{ data.loan_period }} {{ $t('Loans_L10') }}</span>
                            <span class="flex3">{{ data.loan_interest }} USDC</span>
                        </div>
                    </div>
                </div>

                <div class="btn-view" @click="submit()">
                    {{ $t('Loans_L12') }}
                    <img src="@/assets/Img/Imge/LoansTabBtn.svg" class="btn-img">
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref,defineEmits } from 'vue';
import { storeMain } from "@/stores/index.js";
import { showToast } from 'vant';

const show = ref(true);
const mainStore = storeMain();
const data = ref(
    { 
        loan_open : 0,
        loan_amount:0,
        loan_period:0,
        loan_interest:0
    }
)
const emits = defineEmits(['emit']);

async function submit(){
    await mainStore.DaiKuan({ amount: data.value.loan_amount, period: data.value.loan_period, interest: data.value.loan_interest })
    showToast("Succeed")
    emits('emit',false)
}

onMounted(async() => {
    const UserData = await mainStore.GetUserData();
    data.value = UserData.loan;
})
</script>