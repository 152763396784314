<template>
  <div class="Base">
    <router-link @click="SetPage(1)" to="/">
      <div v-if="Orindex == 1">
        <img src="@/assets/Img/XzImg/Home.svg" />
        <div
          style="
            -webkit-text-fill-color: transparent;
            background: linear-gradient(
                74deg,
                rgb(0, 255, 240) 0%,
                rgb(104, 255, 101) 47.77%,
                rgb(250, 255, 0) 98.63%
              )
              text;
          "
        >
          {{ $t('AppBar.text1') }}
        </div>
      </div>
      <div v-if="Orindex != 1">
        <img src="@/assets/Img/WxzImg/Home.svg" />
        <div>{{ $t('AppBar.text1') }}</div>
      </div>
    </router-link>

    <router-link @click="SetPage(2)" to="/Map">
      <div v-if="Orindex == 2">
        <img src="@/assets/Img/XzImg/Map.svg" />
        <div
          style="
            -webkit-text-fill-color: transparent;
            background: linear-gradient(
                74deg,
                rgb(0, 255, 240) 0%,
                rgb(104, 255, 101) 47.77%,
                rgb(250, 255, 0) 98.63%
              )
              text;
          "
        >
          {{ $t('AppBar.text2') }}
        </div>
      </div>
      <div v-if="Orindex != 2">
        <img src="@/assets/Img/WxzImg/Map.svg" />
        <div>{{ $t('AppBar.text2') }}</div>
      </div>
    </router-link>

    <router-link @click="SetPage(3)" to="/Build">
      <div v-if="Orindex == 3">
        <img src="@/assets/Img/XzImg/Build.svg" />
        <div
          style="
            -webkit-text-fill-color: transparent;
            background: linear-gradient(
                74deg,
                rgb(0, 255, 240) 0%,
                rgb(104, 255, 101) 47.77%,
                rgb(250, 255, 0) 98.63%
              )
              text;
          "
        >
          {{ $t('AppBar.text3') }}
        </div>
      </div>
      <div v-if="Orindex != 3">
        <img src="@/assets/Img/WxzImg/Build.svg" />
        <div>{{ $t('AppBar.text3') }}</div>
      </div>
    </router-link>

    <router-link @click="SetPage(4)" to="/MyPage">
      <div v-if="Orindex == 4">
        <img src="@/assets/Img/XzImg/My.svg" />
        <div
          style="
            -webkit-text-fill-color: transparent;
            background: linear-gradient(
                74deg,
                rgb(0, 255, 240) 0%,
                rgb(104, 255, 101) 47.77%,
                rgb(250, 255, 0) 98.63%
              )
              text;
          "
        >
          {{ $t('AppBar.text4') }}
        </div>
      </div>
      <div v-if="Orindex != 4">
        <img src="@/assets/Img/WxzImg/My.svg" />
        <div>{{ $t('AppBar.text4') }}</div>
      </div>
    </router-link>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, defineProps } from "vue";

const prop = defineProps({ Orindex: Number });

function SetPage(i) {
  console.log(prop.Orindex);
}

onMounted(() => {});
</script>

