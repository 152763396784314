import Server from "@/Axios/index.js";

// 用户登录
async function LoginFlag(data) {
    return await Server.post("/login/signin", data);
}

// 获取首页数据
async function GetHomeData() {
    return await Server.post("/common/getHomeReport");
}

// 竞拍
async function JingPai(v) {
    await Server.post("/land/buy",{"token_id":v});
}

// 快速竞拍
async function QuickJingPai(v) {
    await Server.post("/land/quickBuy", { "type": v });
}

// 获取用户数据
async function GetUserData() {
    return await Server.post("/user/info");
}

// 获取竞标列表
async function GetWjsData(data){
    return await Server.post("/user/orderList",data);
}

// 获取建设用途
async function GetBuildType(data){
    return await Server.post("/land/getBuildType", data);
}

// 建设
async function Build(data){
    await Server.post("/land/build",data);
}

// 获取邀请信息
async function GetAskData() {
    return await Server.post("/user/getInviteInfo");
}

// 获取订单收益
async function GetOrderYieldData(data) {
    return await Server.post("/user/earningList", data);
}

// 获取提领记录
async function GetWitData(data) {
    return await Server.post("/user/withdrawList", data);
}

// 贷款
async function DaiKuan(data) {
    await Server.post("/user/loan",data);
}

// 贷款记录
async function GetDaiKData(data){
    return await Server.post("/user/loanList",data);
}

// 退出登录
async function Logout(){
    await Server.post("/user/logout");
}

// 提现收益
async function TxIncome(data) {
    await Server.post("/user/withdraw",data);
}

// 获取帮助中心信息
async function GetHelpData(){
    return await Server.post("/common/getHelpList");
}

// 获取网站配置
async function GetSitesData() {
    return await Server.post("/common/getSiteConfig");
}

// 获取土地等级
async function GetLandLevel() {
    return await Server.post("/land/getLandLevel");
}

export default {
  LoginFlag,
  GetHomeData,
  JingPai,
  QuickJingPai,
  GetUserData,
  GetWjsData,
  GetBuildType,
  Build,
  GetAskData,
  GetOrderYieldData,
  GetWitData,
  DaiKuan,
  GetDaiKData,
  Logout,
  TxIncome,
  GetHelpData,
  GetSitesData,
  GetLandLevel,
};