<template>

        <div class="LandTop" style="align-items: center !important;">
            <div>
                <img src="@/assets/Img/Imge/1.svg" style="width: 100px;">
            </div>
            <div class="LT" style="align-items: center !important;">
                <div style="width: 20px;height: 20px;margin-right: 20px;">
                    <svg viewBox="0 0 448 512" class="bell" @click="Goto()">
                        <path fill="url(#bellGradient)" d="M224 0c-17.7 0-32 14.3-32 32V49.9C119.5 61.4 64 124.2 64 200v33.4c0 45.4-15.5 89.5-43.8 124.9L5.3 377c-5.8 7.2-6.9 17.1-2.9 25.4S14.8 416 24 416H424c9.2 0 17.6-5.3 21.6-13.6s2.9-18.2-2.9-25.4l-14.9-18.6C399.5 322.9 384 278.8 384 233.4V200c0-75.8-55.5-138.6-128-150.1V32c0-17.7-14.3-32-32-32zm0 96h8c57.4 0 104 46.6 104 104v33.4c0 47.9 13.9 94.6 39.7 134.6H72.3C98.1 328 112 281.3 112 233.4V200c0-57.4 46.6-104 104-104h8zm64 352H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7s18.7-28.3 18.7-45.3z"></path><defs><linearGradient id="bellGradient" x1="0%" y1="0%" x2="100%" y2="100%"><stop offset="0%" style="stop-color: rgb(0, 255, 240);"></stop><stop offset="47.77%" style="stop-color: rgb(104, 255, 101);"></stop><stop offset="98.63%" style="stop-color: rgb(250, 255, 0);"></stop></linearGradient></defs>
                    </svg>
                </div>
                <div style="width: 20px;height: 20px;margin-right: 10px;"><img src="@/assets/Img/Imge/3.svg"></div>
                <div>
                    <el-select v-model="Langage" size="" style="font-weight: 700;width: 100px;" :teleported="false">
                        <el-option v-for="item in LangageArrList" :key="item.name" :label="item.name" :value="item.name" @click="setLange(item)">
                            <div class="LanSel">{{ item.name }}</div>
                        </el-option>
                    </el-select>
                </div>
            </div>
        </div>

    
    <Teleport to="body">
        <Transition name="outPage">
            <div v-show="!PageFlag" style="transition: all 0.5 ease-out;">
                <component :is="MsgPage" @PageZ="setPageFlag"></component>
            </div>
        </Transition>
    </Teleport>

</template>

<script setup>
import { ref,defineEmits } from 'vue';
import { setLange } from '@/assets/Js/Language';
// import MsgPage from './MsgPage.vue'

const emits = defineEmits('PageFlag')
const PageFlagZ = ref(true);
const Langage = ref(localStorage.getItem('LanguageName') ? localStorage.getItem('LanguageName') : 'English');
const LangageArrList = ref([
    { "name": 'English', "v": 'en' }, { "name": '한 원', "v": "ko" }, { 'name': '日本語', 'v': 'jq' }, { "name": '繁体中文', "v": "hk" },
    { "name": '简体中文', "v": "zh" }, { "name": 'Germany', "v": "de" }, { "name": 'Spanish', "v": "es" }, { "name": 'France', "v": "fr" },
    { "name": 'ภาษาไทย', "v": "th" }, { "name": 'Tiếng Việt', "v": "vi" }, { "name": 'Italiano', "v": "it" }
])

// function setPageFlag(data){
//     PageFlag.value = data;
// }

function Goto(){
    PageFlagZ.value = false;
    emits('PageFlag','TopIndex')
}

</script>

<style>
.LandTop{
    color: #000000;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    padding-left: 20px;
    z-index: 999;
    position: fixed;
    background: #000000;
}
.LT{
    display: flex;
}
.el-select{
    box-shadow:none !important;
}

.LandTop :root{
    --el-color-primary:none;
}

.el-select__wrapper{
    padding: 0 !important;
    box-shadow:none !important;
    background: #000000 !important;
}

.el-select__selection{
    width: 50px;
}

.LanSel{
    color: black;
    font-weight: 700;
    font-size: 12px !important;
}

.el-select__popper.el-popper{
    background: linear-gradient(74deg, rgb(0, 255, 240) 0%, rgb(104, 255, 101) 47.77%, rgb(250, 255, 0) 98.63%) !important;
}

.el-select__placeholder{
    background-image: linear-gradient(74deg, rgb(0, 255, 240) 0%, rgb(104, 255, 101) 47.77%, rgb(250, 255, 0) 98.63%) !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
    color: transparent !important;
}

.el-select-dropdown__item.is-hovering{
    background: none !important;
}

.el-select__placeholder{
    width: 70px !important;
}

.el-select__suffix{
  align-items: center; /* 垂直居中导航链接 */
  padding: 10px 30px; /* 可根据需要调整内边距 */

}
</style>