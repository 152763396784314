<template>
    <van-loading
        style="z-index: 999999;width: 100vw;height: 100vh;display: flex;align-items: center;justify-content: center;"
        size="100" v-if="mainStore.loading" />
    <div class="PageTop" v-if="!mainStore.loading" >
        <div class="PageTop-info">
            <div class="A1" @click="Goto()">
                <el-icon>
                    <ArrowLeft />
                </el-icon>
            </div>
            <div class="A2">{{ $t('Land.Top') }}</div>
        </div>

        <div>
            <div class="Page-BckImg" v-if="LandData.length == 0">
                <img src="@/assets/Img/Imge/8.svg">
                <div>{{ $t('Land.tis') }}</div>
            </div>

            <van-list v-model:loading="loading" :finished="finished" :finished-text="$t('Not_data_Msg')"
                @load="onLoadYsx" v-if="LandData.length != 0" style="padding-bottom: 50px;">
                <van-collapse v-model="activeNames">
                    <van-collapse-item :title="data.name+'-'+data.level_name" v-for="(data,index) in LandData"
                        :key="index" :name="data.id">
                        <div class="info">
                            <div class="bd"></div>
                            <div class="footer">
                                <div class="left">
                                    <div class="list">
                                        <div class="label">Token ID</div>
                                        <div class="text">{{ data.token_id }}</div>
                                    </div>
                                    <div class="list">
                                        <div class="label">{{ $t("Land_L1") }}</div>
                                        <div class="text">{{ data.land_price }} USDC</div>
                                    </div>
                                    <div class="list">
                                        <div class="label">{{ $t("Land_L2") }}</div>
                                        <div class="text">{{ data.land_period }} {{ $t("Land_L7") }}</div>
                                    </div>
                                    <div class="list">
                                        <div class="label">{{ $t("Land_L3") }}</div>
                                        <div class="text">{{ Converttime(data.time, data.land_period) }}</div>
                                    </div>
                                </div>
                                <div class="bd-left"></div>
                                <div class="right">
                                    <div class="list">
                                        <div class="label">{{ $t("Land_L4") }}</div>
                                        <div class="text">{{ data.x }}</div>
                                    </div>
                                    <div class="list">
                                        <div class="label">{{ $t("Land_L5") }}</div>
                                        <div class="text">{{ (Number(data.land_rate) + Number(data.build_rate == '' ? 0
                                            : data.build_rate)).toFixed(2) }} %</div>
                                    </div>
                                    <div class="list">
                                        <div class="label">{{ $t("Land_L6") }}</div>
                                        <div class="text">
                                            {{ ((Number(data.land_price) * (Number(data.land_rate) / 100) +
                                            Number(data.build_rate == '' ? 0 : data.build_rate)) *
                                            Number(data.land_period)) }} USDC</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </van-collapse-item>
                </van-collapse>
            </van-list>
        </div>
    </div>
</template>

<script setup>
import { defineEmits, onMounted, ref } from 'vue'
import { storeMain } from "@/stores/index.js";

const mainStore = storeMain();
const emits = defineEmits(['PageZ'])
const LandData = ref([]);
let PageIndex = 1;
const loading = ref(false);
const finished = ref(false);
const activeNames = ref([]);
const earnings = ref(0);

onMounted(async() => {
    mainStore.loading = true;
    await GetLandData();
})

async function GetLandData(){
    loading.value = true
    const arr = await mainStore.GetWjsData({ page :PageIndex,size: 12})
    for(let i = 0; i < arr.length;i ++){
        LandData.value.push(arr[i])
    }
    if(arr.length < 12){
        finished.value = true;
    }
    loading.value = false
}

function Converttime(time,day){
    let daytime = Number(day) * 86400;
    let date = new Date((Number(time) + daytime) * 1000);
    return date.getFullYear() + '-' +
        String(date.getMonth() + 1).padStart(2, '0') + '-' +
        String(date.getDate()).padStart(2, '0') + ' ' +
        String(date.getHours()).padStart(2, '0') + ':' +
        String(date.getMinutes()).padStart(2, '0') + ':' +
        String(date.getSeconds()).padStart(2, '0');
}

function Goto(){
    emits('PageZ',true)
}

async function onLoadYsx(){
    if(!finished.value){
        PageIndex++;
        await GetLandData()
    }
}
</script>

<style>
.van-cell{
    background: #151715 !important;
    color: white !important;
    border: none !important;
}

.van-collapse-item{
    margin-bottom: 15px !important;
}

.van-cell:after{
    border: none !important;
}

.van-collapse-item--border:after{
    border: none !important;
}

.van-hairline--top-bottom:after{
    border: none !important;
}

.van-collapse-item__content{
    background: #151715 !important;
}

.van-collapse-item__title--expanded{
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-top: 1px solid !important;
    border-left: 1px solid !important;
    border-right: 1px solid !important;
    
    border-image: linear-gradient(74deg, rgb(0, 255, 240), rgb(104, 255, 101), rgb(250, 255, 0)) 1 !important;
    border-image-slice: 1 !important;
    border-image-source: linear-gradient(74deg, rgb(0, 255, 240), rgb(104, 255, 101), rgb(250, 255, 0)) !important;
    border-image-width: 2 !important;
    border-bottom: none !important;
    /* 不设置底部边框 */
    color: #5ee35c !important;
}

.van-collapse-item__content{

    border-bottom: 1px solid !important;
    border-left: 1px solid !important;
    border-right: 1px solid !important;

    border-image: linear-gradient(74deg, rgb(0, 255, 240), rgb(104, 255, 101), rgb(250, 255, 0)) 1 !important;
    border-image-slice: 1 !important;
    border-image-source: linear-gradient(74deg, rgb(0, 255, 240), rgb(104, 255, 101), rgb(250, 255, 0)) !important;
    border-image-width: 2 !important;
    border-top: none !important;
}

.info {
    display: block;
    transition: max-height 0.3s;
}

.info .bd {
    border-bottom: 1px solid rgb(33, 35, 33);
    margin: 10px;
}

.info .footer {
    width: 100%;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.info .footer .left,
.info .footer .right {
    width: 50%;
    font-size: 12px;
    padding-left: 10px;
}

.list {
    width: 100%;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 10px 0px;
}

.list .label {
    color: rgb(153, 153, 153);
}
.list .label,
.list .text {
    width: 100%;
    text-align: left;
}

.list .text{
    color: white;
}
.van-collapse-item__content{
    padding: 0 !important;
    padding-top: 12px !important;
}
</style>