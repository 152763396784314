<template>
    <Transition name="outPage">
        <div class="AskPage" v-show="PageFlag">
            <div class="AskTop">
                <div class="A1" @click="Goto()">
                    <el-icon><ArrowLeft /></el-icon>
                </div>
                <div class="A2">{{ $t('Ask.Top') }}</div>
            </div>

            <div class="Ask-info">
                <div class="Ask-info-top">{{ $t('Ask.info1') }}</div>
                <div class="info">
                    <div class="info-data">
                        <div style="color: #999999">{{ $t('Ask.info2') }}</div>
                        <div>{{ AskData.team_num }}</div>
                    </div>
                    <div class="info-data">
                        <div style="color: #999999">{{ $t('Ask.info3') }}</div>
                        <div>{{ AskData.direct_num }}</div>
                    </div>
                </div>
                <div class="info">
                    <div class="info-data">
                        <div style="color: #999999">{{ $t('Ask.info4') }}</div>
                        <div>{{ AskData.total_income }}</div>
                    </div>
                    <div class="info-data">
                        <div style="color: #999999">{{ $t('Ask.info5') }}</div>
                        <div>{{ AskData.today_income }}</div>
                    </div>
                </div>
                <div class="info-a" style="text-align: center;margin: 10px 0;color:#878787;">
                    <a style="border-bottom: #999999 solid 1px" @click="GoPage()">{{ $t('Ask.a1') }}</a>
                </div>
                <div class="info-btn">
                    <div style="background: linear-gradient(74deg, rgb(0, 255, 240) 0%, rgb(104, 255, 101) 47.77%, rgb(250, 255, 0) 98.63%);color: black;padding: 5px 10px;border-radius: 5px">
                        {{ $t('Ask.btn1') }}
                    </div>
                </div>
            </div>

            <div class="AskUrl">
                <div>{{ $t('Ask.AskUrlText') }}</div>
                <div class="Url" style="overflow: hidden;width: 140px;text-overflow: ellipsis;white-space: nowrap;">{{ AskData.invite_url }}</div>
                <div class="AskUrl-btn" @click="getFz()">{{ $t('Ask.btn2') }}</div>
            </div>

            <div class="AskTable">
                <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">

                    <el-tab-pane :label="$t('Ask.lab1')" name="All">
                        <div class="TabDataTop">
                            <div>{{ $t('Ask.tab1') }}</div>
                            <div>{{ $t('Ask.tab2') }}</div>
                        </div>
                    </el-tab-pane>

                    <el-tab-pane :label="$t('Ask.lab2')" name="num1">
                        <div class="TabDataTop">
                            <div>{{ $t('Ask.tab1') }}</div>
                            <div>{{ $t('Ask.tab2') }}</div>
                        </div>
                    </el-tab-pane>

                    <el-tab-pane :label="$t('Ask.lab3')" name="num2">
                        <div class="TabDataTop">
                            <div>{{ $t('Ask.tab1') }}</div>
                            <div>{{ $t('Ask.tab2') }}</div>
                        </div>
                    </el-tab-pane>

                    <el-tab-pane :label="$t('Ask.lab4')" name="num3">
                        <div class="TabDataTop">
                            <div>{{ $t('Ask.tab1') }}</div>
                            <div>{{ $t('Ask.tab2') }}</div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </Transition>

    <Transition name="outPage">
        <div v-show="!PageFlag">
            <component :is="AskRecord" @PageFlagZ="setPageFlag"></component>
        </div>
    </Transition>

</template>

<script setup>
import { ref,defineEmits, onMounted } from 'vue';
import AskRecord from './AskRecord.vue'
import { ElMessage } from 'element-plus';
import { storeMain } from "@/stores/index.js";

const mainStore = storeMain();
const TeamSize = ref(0);
const DirectSize = ref(0);
const AllSum = ref(0);
const DaySum = ref(0);
const AskUrl = ref('');
const activeName = ref('All')
const PageFlag = ref(true);
const emits = defineEmits(['PageZ'])
const AskData = ref({});

onMounted(async() => {
    AskData.value = await mainStore.GetAskData();
    console.log(AskData.value)
})

function Goto(){
    emits('PageZ',true)
}

function GoPage(){
    PageFlag.value = false;
}

function setPageFlag(data){
    PageFlag.value = data;
}

function getFz(){
    navigator.clipboard.writeText(AskUrl.value);
    ElMessage('复制成功')
}
</script>