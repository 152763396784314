import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPersist from "pinia-plugin-persist";
import App from "./App.vue";
import ElementPlus from "element-plus";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import "element-plus/dist/index.css";
import router from "@/assets/Js/router.js";
import i18n from '@/assets/Js/Language.js'
import {
  Overlay,
  Loading,
  Toast,
  Pagination,
  List,
  Collapse,
  CollapseItem,
  Icon,
} from "vant";
import "vant/lib/index.css";

localStorage.getItem("Language") || localStorage.setItem("Language", "en");

const pinia = createPinia();
pinia.use(piniaPersist)
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.use(Overlay);
app.use(Collapse);
app.use(CollapseItem);
app.use(Pagination);
app.use(pinia);
app.use(Icon);
app.use(List);
app.use(i18n);
app.use(Toast);
app.use(Loading);
app.use(ElementPlus);
app.use(router);
app.mount("#app");
