<template>
    <van-loading
        style="z-index: 999999;width: 100vw;height: 100vh;display: flex;align-items: center;justify-content: center;"
        size="100" v-if="store.loading" />

    <div class="BuildPage" v-if="!store.loading">
        <el-tabs v-model="activeName" class="Build-tabs" @tab-click="handleClick">
            <el-tab-pane :label="$t('Build.label1')" :name="$t('Build.label1')">
                <div class="BuildImg" style="width: 100%;height: 80vh;" v-if="WjsData.length == 0">
                    <img src="@/assets/Img/Imge/8.svg">
                    <div>{{ $t('Build.labeltext') }}</div>
                </div>

                <div v-if="WjsData.length != 0">
                    <van-list @load="onLoad" v-model:loading="loading" :finished="finished" class="masonry-container">
                        <div v-for="(data,index) in WjsData" :key="index" class="masonry-item">
                            <div class="masonry-image">
                                <img src="@/assets/Img/Imge/BulidLogo.svg" alt="Image" />
                            </div>
                            <div>{{ data.name }}</div>
                            <div class="masonry-action">
                                <span class="action-button" v-if="data.build == 0">
                                    {{ $t('Build_L1') }}
                                </span>
                                <span class="action-button" v-if="data.build == 1" @click="BOTPage(data)"
                                    style="background: black;">
                                    {{ $t('Build_L1') }}
                                </span>
                            </div>
                        </div>
                    </van-list>
                </div>
            </el-tab-pane>
            <el-tab-pane :label="$t('Build.label2')" :name="$t('Build.label2')">
                <div class="BuildImg" style="width: 100%;height: 80vh;" v-if="JsData.length == 0">
                    <img src="@/assets/Img/Imge/8.svg">
                    <div>{{ $t('Build.labeltext') }}</div>
                </div>

                <div v-if="JsData.length != 0">
                    <van-list @load="onLoad" v-model:loading="loading" :finished="finished" class="masonry-container">
                        <div v-for="(data, index) in JsData" :key="index" class="masonry-item" @click="GoBuild(data)">
                            <div style="border: 10px;width: 100%;height: auto;">
                                <img src="@/assets/Img/BOTImge/tjs.png"
                                    style="border-radius: 10px;width: 100%;height: auto;">
                            </div>
                            <div>{{ data.name }}</div>
                            <div style="padding: 8px 0;">
                                <div class="desc">
                                    <div>{{ $t('Loans_L13') }}</div>
                                    <div>{{ data.land_price }} USDC</div>
                                </div>
                                <div class="desc">
                                    <div>{{ $t('Loans_L14') }}</div>
                                    <div>{{ Number(data.build_rate) + Number(data.land_rate) }}%</div>
                                </div>
                            </div>
                        </div>
                    </van-list>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script setup>
import { ref,defineEmits, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeMain } from "@/stores/index.js";

const store = storeMain();
const { t } = useI18n();
const activeName = ref(t('Build.label1'));
const emits = defineEmits(['BOT','Build'])
const WjsData = ref([])
const JsData = ref([])
const currentPage = ref(1)
const loading = ref(false);
const finished = ref(false);

onMounted(() => {
    store.loading = true;
    GetData();
})

function handleClick(v){
    WjsData.value = []
    JsData.value = []
    currentPage.value = 1;
    activeName.value = v.props.name;
    GetData();
}

async function GetData() {
    loading.value = true;

    if (activeName.value == t('Build.label1')) {
        const arr1 = await store.GetWjsData({ page: currentPage.value, size: 6, is_build: 1 });
        WjsData.value.push(...arr1);

        if (arr1.length < 6) { // 小于请求的数量时，标记为完成
            finished.value = true;
        }
    } else if (activeName.value == t('Build.label2')) {
        const arr2 = await store.GetWjsData({ page: currentPage.value, size: 6, is_build: 2 });
        JsData.value.push(...arr2);

        if (arr2.length < 6) { // 小于请求的数量时，标记为完成
            finished.value = true;
        }
    }

    loading.value = false; // 在数据加载完成后再设置为 false
}

function onLoad() {
    if (!finished.value) { // 如果还没有完成才加载更多
        currentPage.value++;
        GetData();
    }
}

function BOTPage(data){
    emits('BOT', data)
}

function GoBuild(data){
    emits('Build',data)
}
</script>

<style>
.Build-tabs .el-tabs__nav-wrap::after {
    position: static !important;
}

.el-tabs__item.is-active {
    color: rgb(104, 255, 101);
}

.el-tabs__item:hover {
    color: rgb(104, 255, 101);
}

.el-tabs__active-bar {
    background-color: rgb(104, 255, 101);
}

.el-tabs__nav {
    padding-left: 12px;
}

.el-tabs__item {
    padding: 0 12px;
}

.el-tabs__item {
    color: rgb(153, 153, 153);
}

.BuildImg {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 13px;
}

.BuildImg img {
    width: 50px;
    height: 75px;
}

.BuildImg div {
    margin: 10px;
}

.desc {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
}

.masonry-container {
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* 改为 space-around 或 space-evenly */
    /* box-sizing: border-box; */
    /* padding: 0 5px; */
    /* 确保项目间距足够  */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    /* 调整子项之间的间距 */
    box-sizing: border-box;
    padding-bottom: 50px;
}

.masonry-item {
    background: linear-gradient(74deg, rgb(0, 255, 240) 0%, rgb(104, 255, 101) 47.77%, rgb(250, 255, 0) 98.63%);
    padding: 2px;
    border-radius: 10px;
    /* width: calc(50% - 10px); */
    /* 每个项目宽度 50%，减去边距 */
    /* margin-bottom: 20px; */
    text-align: center;
    box-sizing: border-box;
    /* 确保 padding 和 border 不会影响布局 */
}

.masonry-image img {
    border-radius: 10px;
    width: 100%;
    height: auto;
}

.masonry-action {
    padding: 8px 0;
}

.action-button {
    padding: 1px 10px;
    background: #28995b;
    font-size: 14px;
    border-radius: 5px;
    color: #43ff95;
    display: inline-block;
}

.van-list__loading{
    width: 100%;
}
</style>