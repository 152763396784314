<template>
    <div v-if="modalStatus.isBuildingDetail" class="modal-quick_purchase">
        <div class="modal-container">
            <div class="modal-content">
                <p class="land-info_header">
                    {{ props.detail.land_name }} {{ props.detail.parcel_size }}
                </p>
                <div class="price-info">
                    <span class="price">
                        {{ props.detail.last_sale.last_sale_price }} USDC
                    </span>
                    <span class="info">{{ props.detail.land_state }}</span>
                </div>

                <div class="coordinates">
                    <img :src="ImgMap" alt="" />
                    <span> {{ $t('BuildingDetail.coordinates') }} : ({{ props.detail.coordinates.x }},{{
                        props.detail.coordinates.y
                        }})</span>
                </div>
                <div class="infos">
                    <div class="land-info">
                        <span class="label">{{ $t('BuildingDetail.owner') }}</span>
                        <span class="value">{{
                            props.detail.owner.address === ""
                            ? "Not"
                            : props.detail.owner.address
                            }}</span>
                    </div>
                    <div class="land-info">
                        <span class="label">{{ $t('QuickPurchase.earningRate') }}</span>
                        <span class="value">{{ props.detail.interest_rate * 100 }}%</span>
                    </div>
                    <div class="land-info">
                        <span class="label">{{ $t('QuickPurchase.earnings') }}</span>
                        <span class="value">{{
                            props.detail.last_sale.last_sale_price_usd.toFixed(2)
                            }}
                            USDC</span>
                    </div>
                    <div class="land-info">
                        <span class="label">{{ $t('QuickPurchase.size') }}</span>
                        <span class="value">{{ props.detail.parcel_size_m }}</span>
                    </div>

                    <div class="land-info-flex_container">
                        <div class="land-info-flex">
                            <span class="label">{{ $t('QuickPurchase.blockchain') }}</span>
                            <span class="value">{{ props.detail.last_sale.symbol }}</span>
                        </div>
                        <div class="land-info-flex">
                            <span class="label">{{ $t('QuickPurchase.size') }}</span>
                            <span class="value">{{ props.detail.parcel_size_m }}</span>
                        </div>
                        <div class="land-info-flex">
                            <span class="label">{{ $t('BuildingDetail.token') }} ID</span>
                            <span class="value">{{ props.detail.token_id }}</span>
                        </div>
                    </div>
                </div>

                <div class="action">
                    <p class="rules" @click="modalStatus.setModalBiddingRule(true)">
                        {{ $t('QuickPurchase.rules') }}
                    </p>
                    <p class="bidding" @click="store.JingPai(1, props.detail.token_id)"> {{ $t('QuickPurchase.bidding') }} </p>
                </div>
            </div>
        </div>
        <div @click="modalStatus.setModalBuildingDetail(false)" class="overlay-modal"></div>
    </div>
</template>

<script setup>
import { useModalStatus } from "../../stores/modal";
import ImgClose from "../../assets/Img/Imge/close.png";
import ImgMap from "../../assets/Img/Imge/position-icon.svg";
import { defineProps, onMounted, watch } from "vue";
import { storeMain } from "@/stores";

const store = storeMain();
const modalStatus = useModalStatus();

const props = defineProps({
  detail: undefined,
});
</script>

<style scoped>
#building-modal {
  z-index: 10;
  height: 100px;
  width: 100px;
  background-color: red;
  position: fixed;
  top: 0;
}

.overlay-modal {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 30;
}

.modal-container {
  transform: translate(-50%, -50%);
  width: 80vw;
  max-height: calc(100% - 100px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  padding: 2px;
  border-radius: 10px;
  background: linear-gradient(74deg,
      #00fff0 0%,
      #68ff65 47.77%,
      #faff00 98.63%) !important;
}

.modal-close {
  padding: 20px;
  margin-bottom: 0.1px;
  background: #000000e0;
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: end;
  border: 1px solid rgba(0, 0, 0, 0.1);

  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.modal-content {
  background: #000000e0;
  border-radius: 10px;
  padding: 1.25rem;
}

.land-info_header {
  text-align: center;
  color: #68ff65;
  font-size: 13px;
}

.coordinates {
  color: white;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 1rem;

  img {
    width: 20px;
    height: 20px;
  }
}

.land-info-flex_container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .land-info-flex {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .label {
      color: #999999;
      font-size: 12px;
    }

    .value {
      color: white;
      font-size: 12px;
    }
  }
}
</style>
