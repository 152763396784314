<template>
  <BiddingRulesModal v-if="!loading"/>
  <QuickPurchaseModal v-if="!loading"/>
  <BuildingDetailModal :detail="buildingDetail" v-if="!loading"/>
  <MapBuyingInfo v-if="!loading"/>
  <QuickPurchaseButton v-if="!loading"/>
  <img v-if="loading" id="loading-animation" src="/Loading.gif" alt="">
  <div :style="loading ? 'visibility: hidden;' : 'visibility: visible;'" class="map-container">
    <div ref="mapRef" id="map"></div>
    <div id="status"></div>
  </div>
  <div>
    <AppBar :Orindex="2"></AppBar>
  </div>
</template>

<script setup>
import AppBar from "./AppBar.vue";
import { ref, onMounted, onUnmounted } from "vue";
import * as maptalks from "maptalks";
import { GroupGLLayer } from "@maptalks/gl";
import * as maptalksThree from "maptalks.three";
import * as THREE from "three";
import BuildingDetailModal from "./Modals/BuildingDetailModal.vue";
import MapBuyingInfo from "./MapBuyingInfo.vue";
import QuickPurchaseButton from "./QuickPurchaseButton.vue";
import QuickPurchaseModal from "./Modals/QuickPurchaseModal.vue";
import BiddingRulesModal from "./Modals/BiddingRulesModal.vue";
import { useModalStatus } from "@/stores/modal";

let map = null;
const mapRef = ref(null);
const buildingDetail = ref(undefined);
const modalStatus = useModalStatus();
const loading = ref(false);
let isMounted = false;
const handleOpenDetail = (detail) => {
  const currStatus = modalStatus.isBuildingDetail;

  if (currStatus) {
    modalStatus.setModalBuildingDetail(false);
    buildingDetail.value = undefined;
  } else {
    modalStatus.setModalBuildingDetail(true);
    buildingDetail.value = detail;
  }
};

onMounted(() => {
  renderMap()
});

onUnmounted(() => {
    isMounted = true;
    if (map) {
        map.remove();
        map = null;
    }
})

function renderMap() {
  var deltaLon = 1;
  var deltaLat = 1;
  var centralPoint = [0, 0];
  map = new maptalks.Map(mapRef.value, {
    center: centralPoint,
    maxZoom: 18,
    minZoom: 8,
    zoom: 11,
    pitch: 60,
    dragRotate: true,
    dragPitch: true,
    centerCross: false,
    attribution: {
      content: "",
    },
    baseLayer: new maptalks.TileLayer("tile", {
      // urlTemplate: "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png",
      urlTemplate: "",
      subdomains: ["a", "b", "c", "d"],
    }),
  });
  
  const extent = [
    centralPoint[0] - deltaLon,
    centralPoint[1] - deltaLat,
    centralPoint[0] + deltaLon,
    centralPoint[1] + deltaLat,
  ];

  new maptalks.ImageLayer("images", [
    {
      url: "/Waterfront.jpg",
      extent,
    },
  ]).addTo(map);

  var threeLayer = new maptalksThree.ThreeLayer("t", {
    forceRenderOnMoving: true,
    forceRenderOnRotating: true,
    identifyCountOnEvent: 1,
    // animation: true
  });

  // var meshs = [];

  threeLayer.prepareToDraw = function (gl, scene, camera) {
    var light = new THREE.DirectionalLight(0xffffff);
    light.position.set(0, -10, 10).normalize();
    scene.add(light);

    scene.add(new THREE.AmbientLight(0xffffff));

    try{
        load();
    }catch(e){
        console.log(e)
    }

  };

  const sceneConfig = {
    enable: true,
    antialias: { enable: true },
    background: new THREE.Color(0xffffff),
  };
  const groupLayer = new GroupGLLayer("group", [threeLayer], { sceneConfig });
  groupLayer.addTo(map);

  const parcelSize = {
    A: 1,
    B: 200,
    C: 400,
    S: 1,
    SS: 1600,
    SSS: 3200,
  };

  const parcelColor = {
    A: "orange",
    B: "yellow",
    C: "fuchsia",
    S: "orange",
    SS: "tomato",
    SSS: "deeppink",
  };

  const parcelEmissiveColor = {
    orange: 0x7a301f,
    yellow: 0x8e8e01,
    fuchsia: 0x830183,
    tomato: 0x8c3a2c,
    deeppink: 0x950e56
  }

  const highlightmaterial = new THREE.MeshPhongMaterial({
    color: "black",
    transparent: true,
    opacity: 0.5,
  });

  async function load() {
      
    loading.value = true;
    console.time();

    const parcels = await fetch("/parcels.json").then((res) => res.json());
    const parcelData = await fetch("/parceldata.json").then((res) => res.json());
      
    const batchSize = 500; // Adjust batch size based on performance
    let currentBatch = 0;

    function renderBatch() {
        if (isMounted) return;
      const start = currentBatch * batchSize;
      const end = Math.min((currentBatch + 1) * batchSize, parcelData.assets.length);
        
      const meshs = parcelData.assets.slice(start, end).map((d, idx) => {
        const parcelType = parcels[start + idx].parcel_size; // Adjust index
        const material = new THREE.MeshPhongMaterial({
          color: parcelColor[parcelType],
          transparent: true,
          emissive: parcelEmissiveColor[parcelColor[parcelType]]
        });

        const geometry = d.geometry;
        geometry.properties = parcels[start + idx];
        geometry.properties.height = parcelSize[parcelType] * 3;

        var polygon = maptalks.GeoJSON.toGeometry(geometry);

        var mesh = threeLayer.toExtrudePolygons(
          polygon,
          { topColor: "#ffffff" },
          material,
        );
        
        ["click", "mouseout", "mouseover"].forEach(function (eventType) {
          mesh.on(eventType, function (e) {
            if (eventType === "mouseover") {
              // this.setSymbol(highlightmaterial);
            } else if (eventType === "mouseout") {
              // this.setSymbol(material);
            }

            if (eventType === "click") {
              handleOpenDetail(parcels[start + idx]);
            }
          });
        });
        
        return mesh;
      });

      threeLayer.addMesh(meshs);

      currentBatch++;
      if (currentBatch * batchSize < parcelData.assets.length) {
        setTimeout(renderBatch, 0); // Yield to the browser's event loop
      } else {
        console.timeEnd();
        loading.value = false;
      }
    }

    renderBatch();
  }

  function animation() {
    threeLayer._needsUpdate = !threeLayer._needsUpdate;
    if (threeLayer._needsUpdate) {
      threeLayer.redraw();
    }
    requestAnimationFrame(animation);
  }
}
</script>

<style>
.map-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#map {
  width: 100%;
  height: 100vh;
  max-height: 100%;
}

#status {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 140px;
  overflow: hidden;
}

#status div {
  background-color: rgba(13, 13, 13, 0.5);
  width: 100%;
  height: 100%;
  padding: 10px 10px 10px 10px;
  font: 13px bold sans-serif;
  color: #fff;
}

#loading-animation {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
}
</style>
