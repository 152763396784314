import { defaultWagmiConfig } from '@web3modal/wagmi';
import { arbitrum, mainnet } from "viem/chains";

export const projectId = "8f99ccd0bb4deea2264bfb9c5356cf32";
const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "localhost:8080",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};


const chains = [mainnet, arbitrum];

const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
});

export default config;
