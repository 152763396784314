export default {
  "home": {
    "HtD1": "함께 플레이하고 가치를 창출하세요",
    "HtD2": "그리고 자신만의 가상 땅을 소유하세요",
    "HtD3": "우리 함께 새로운 세상을 만들어 가자",
    "Btn": "둘러보기",
    "info1": "총 사용자 수",
    "info2": "총 활성 사용자 수",
    "info3": "일일 거래량",
    "BoxTop": "메타버스 가상랜드 소개",
    "desc": "가상 세계의 대부분은 현실 세계를 반영하고 실시간으로 연결되고 동기화되는 디지털 트윈이 될 것입니다. 다른 것들은 엔터테인먼트, 사교 활동, 게임, 학습, 협업 또는 비즈니스를 위해 설계될 것입니다.",
    "infoTop": "메타 공간 통해 독특한 게임 자산을 구축하고 토큰화하며, 우리는 이러한 권리를 사용자에게 부여합니다. 메타랜드는 소유, 운영 토지 블록 및 모든 게임 내 자산과 영원히 분리되고 사용자가 최종 가치를 결정하도록 합니다",
    "li1": "블록체인 가상세계",
    "li2": "소유권 보안",
    "li3": "출처의 진위성",
    "li4": "탈중앙화된 마켓플레이스를 통해 거래 가능",
    "li5": "검증된 보안 표준을 채택하여 구축",
    "swiperTop": "파트너",
    "caseTop": "사례"
  },
  "Build": {
    "label1": "미건설",
    "label2": "건설됨",
    "labeltext": "데이터 없음"
  },
  "MyPage": {
    "Score": "신용 점수",
    "JeCount1": "동결 금액",
    "JeCount2": "사용 가능 금액",
    "EarNingsTop": "수익",
    "EarNingsInfo1": "총 수익",
    "EarNingsInfo2": "수령 대기",
    "EarNingsInfo3": "오늘의 수익",
    "EarNingsBtn": "수익 출금",
    "List1": "초대 보상",
    "List2": "주문 기록",
    "List3": "역사 기록",
    "List4": "대출 기록",
    "List5": "입찰된 토지",
    "List6": "도움말 센터",
    "msg": "로그아웃 성공"
  },
  "MyTxPage": {
    "Top": "수익 출금",
    "Top2": "USDC를 지갑으로 출금",
    "info1": "플랫폼 USDC 잔액",
    "info2": "교환하고자 하는 USDC 수량 입력",
    "btn": "전부",
    "info3": "수수료",
    "info4": "실제 입금",
    "txmBA": "출금 요청이 24시간 내에 지갑으로 전송됩니다",
    "txmBB": "출금 확인",
    "Msg1": "수집 신청서가 제출되었으며 검토를 기다리는 중입니다.",
    "Msg2": "출금 실패, 출금 금액은 플랫폼 잔액을 초과할 수 없습니다"
  },
  "Order": {
    "Top": "주문 기록",
    "wc": "데이터 없음",
    "sb": "데이터 없음",
    "ywc": "완료됨",
    "ysb": "실패"
  },
  "History": {
    "Top": "역사 기록",
    "tis": "데이터 없음",
    "ShuoYi": "주문 수익",
    "JiLu": "출금 기록"
  },
  "Bor": {
    "Top": "대출 기록",
    "tis": "데이터 없음"
  },
  "Land": {
    "Top": "입찰된 토지",
    "tis": "데이터 없음"
  },
  "Ask": {
    "Top": "초대 보상",
    "info1": "초대 보상",
    "info2": "팀 규모",
    "info3": "직속 하위",
    "info4": "총 수입",
    "info5": "오늘의 수익",
    "a1": "초대 기록",
    "btn1": "보상 출금",
    "AskUrlText": "초대 링크",
    "btn2": "초대하러 가기",
    "tab1": "주소",
    "tab2": "시간",
    "lab1": "자산 기록",
    "lab2": "1급",
    "lab3": "2급",
    "lab4": "3급"
  },
  "AskRe": {
    "Top": "초대 기록",
    "text": "데이터 없음"
  },
  "Help": {
    "Top": "도움말 센터",
    "text1": "랜드C 가격: 500. 일일 이자율 1.3%. 기간: 3일 랜드 B 가격: 5,000 일일 이자율 1.5%: 랜드 A 가격: 30,000 일일 이자율 1.9%, 기간: 7일 Land S 가격: 100,000. 일일 이자율 2.5% 기간: 15일 Land SS 가격: 300,000. 일일 금리는 3.2%, 기간은 20일, 금리는 3.2%입니다. 토지 SSS 가격: 500,000. 일일 이자율 4.0%: 참고: S 플롯(S 플롯 포함) 이상의 플롯만 구성할 수 있으며 더 많은 일일 수익을 창출할 수 있습니다.",
    "text2": "Meta land 는 현실 세계를 매핑하거나 초월하는 디지털 기술, 현실 세계와 상호작용할 수 있는 가상 세계, 그리고 새로운 형태의 사회 시스템을 이용해 인간이 구축한 디지털 생활공간이다.",
    "text3": "Metaland를 사용하면 사용자는 USDC를 사용하여 가상 토지에 입찰하고 토지에 투기하고 입찰을 통해 가상 토지 수익을 회수하고 가상 토지를 회수할 수 있습니다. 따라서 실제 생활과 마찬가지로 토지를 구입하고 건설을 완료하여 더 높은 가격에 판매할 수 있습니다. 구축이 완료됨과 동시에 사용자는 자신이 만든 NFT를 소유할 수 있습니다.",
    "tte1": "Meta land 의 토지계획에 대하여",
    "tte2": "Meta Land란 무엇인가요?",
    "tte3": "사람들이 MetaLand에서 돈을 버는 방법"
  },
  "Login": {
    "lab1": "지갑 연결하여 탐험하기"
  },
  "AppBar": {
    "text1": "홈페이지",
    "text2": "지도",
    "text3": "건설",
    "text4": "나의것"
  },
  MsgPage: {
    Top: "알림",
    tte: "데이터 없음"
  },
  "QuickPurchase": {
    "btnText": "빠른 구매",
    "landLvl": "토지 레벨",
    "onAuction": "경매 중",
    "earningRate": "수익률",
    "earnings": "수익",
    "size": "크기",
    "rules": "규칙",
    "bidding": "입찰",
    blockchain: "블록체인",
    ethereum: "이더리움"
  },
  "QuickPurchaseRules": {
    "title": "토지로 무엇을 할 수 있습니까?",
    "rule1": "특별 보상 획득",
    "rule2": "MetalLand 소유자를 위한 독점 서약",
    "rule3": "토지를 판매하고 수익을 얻으세요",
    "rule4": "작품을 출판하고 수익화하세요"
  },
  "BuildingDetail": {
    "coordinates": "좌표",
    "owner": "소유자",
    "earningRate": "수익률",
    "earnings": "수익",
    "size": "크기",
    "token": "토큰"
  },
  "LoansTopName": "대출 규칙",
  "LoansBtn": "대출 신청 확인",
  "Loans_L1": "신용",
  "Loans_L2": "축하합니다",
  "Loans_L3": "신용 등급이 우수하여 대출을 신청할 수 있습니다. 신청서를 제출하기만 하면 됩니다.<br/>고객 서비스는 신용 등급에 따라 대출 한도를 결정합니다.",
  "Loans_L4": "신청 확인",
  "Loans_L5": "신용 점수를 기반으로 대출 신청 제출",
  "Loans_L6": "대출 받기",
  "Loans_L7": "상위 작업 완료",
  "Loans_L8": "작업을 계속 완료하여 신용 점수를 누적하세요. 최대 대출 한도는",
  "Loans_L9": "금액",
  "Loans_L10": "일",
  "Loans_L11": "이자",
  "Loans_L12": "수락",
  "Loans_L13": "투자 금액",
  "Loans_L14": "수익 비율",

  "Build_L1": "건설",

  "BOT_L1": "토지 건설",
  "BOT_L2": "토지 가격",
  "BOT_L3": "지금 건설",
  "BOT_Sel1": "주거용",
  "BOT_Sel2": "공공",
  "BOT_L4": "소유자",
  "BOT_L5": "위치:",
  "BOT_L6": "기본 수익:",
  "BOT_L7": "건설 수익:",
  "BOT_L8": "수익:",
  "BOT_L9": "건설 용도 선택:",
  "BOT_L10": "건설 유형 선택:",
  "BOT_L11": "건설 유형을 선택하세요:",
  "BOT_L12": "건설 비용",
  "BOT_L13": "NFT 청구",

  "Land_L1": "입찰 금액",
  "Land_L2": "기간 수익",
  "Land_L3": "종료",
  "Land_L4": "위치",
  "Land_L5": "수익 비율",
  "Land_L6": "수익",
  "Land_L7": "일",

  "Bor_L1": "대출 금액",
  "Bor_L2": "일",
  "Bor_L3": "신청 중",
  "Bor_L4": "시간",
  "Bor_L5": "이자",
  "Bor_L6": "완료",

  "Not_data_Msg": "더 이상 데이터가 없습니다"
}
