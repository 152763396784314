<template>
    <div class="PageTop">

        <div class="PageTop-info">
            <div class="A1" @click="Goto()">
                <el-icon>
                    <ArrowLeft />
                </el-icon>
            </div>
            <div class="A2">{{ $t("Help.Top") }}</div>
        </div>

        <div class="HelpPage">
            <el-collapse v-model="activeName" accordion>
                <el-collapse-item :title="item.title" :name="index" v-for="(item, index) in HelpData" :key="index">
                    <div>
                        {{ item.content }}
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
    </div>
</template>

<script setup>
import { computed, defineEmits, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n';
import { storeMain } from "@/stores/index.js";

const { t } = useI18n();
const activeName = ref()
const emits = defineEmits(['PageZ'])
const mainStore = storeMain();
const HelpData = ref([])

onMounted(async() => {
    const arr = await mainStore.GetHelpData();
    HelpData.value.push(...arr)
    activeName.value = HelpData.value[0].title;
})

function Goto(){
    emits('PageZ',true)
}
</script>

<style>
    .HelpPage .el-collapse-item__header{
        text-align:left;
        line-height:1.2 !important;
    }

    .el-collapse-item.is-active button{
        color:rgb(104, 255, 101) !important;
    }
</style>