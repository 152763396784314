<template>
    <div class="MsgTop">
        <div class="MsgTop-info">
            <div class="A1" @click="Goto()">
                <el-icon><ArrowLeft /></el-icon>
            </div>
            <div class="A2">{{ $t('MsgPage.Top') }}</div>
        </div>

        <div>
            <div class="Page-BckImg">
                <img src="@/assets/Img/Imge/8.svg">
                <div>{{ $t('MsgPage.tte') }}</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineEmits } from 'vue'

const emits = defineEmits('PageZ')

function Goto(){
    emits('PageZ',true)
}
</script>