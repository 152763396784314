import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";
import HomePage from "@/components/HomePage.vue";
import BuildPage from "@/components/BuildPage.vue";
import MyPage from "@/components/MyPage.vue";
import MapPage from "@/components/MapPage.vue";
import Login from "@/components/Login/LoginPage.vue";
import { storeMain } from "@/stores";
const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      component: HomePage,
    },
    {
      path: "/Build",
      component: BuildPage,
    },
    {
      path: "/MyPage",
      component: MyPage,
    },
    {
      path: "/Login",
      name: "Login",
      component: Login,
    },
    {
      path: "/Map",
      component: MapPage,
    },
  ],
});

router.beforeEach((to, from, next) => {
    const mainStore = storeMain();
    const token = mainStore.token;
    
  if (!token) {
    if (to.fullPath == "/Login") {
        if(!token) {
            next();
        }else if(token){
            next("/");
        }
    } else {
      next("/Login");
    }
  } else {
    next();
  }
});

export default router;
