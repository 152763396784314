export default {
  home: {
    HtD1: "Jugar juntos, crear valor",
    HtD2: "Crea y posee tu tierra virtual",
    HtD3: "Construyamos juntos un nuevo mundo",
    Btn: "Explorar ahora",
    info1: "Usuarios totales",
    info2: "Usuarios activos",
    info3:
      "Volumen de transacciones diarias + Volumen de transacciones mensuales + Volumen de transacciones trimestrales",
    BoxTop: "Introducción al Metaverso (Tierra Virtual)",
    desc: "El metaverso puede entenderse como una representación del mundo real, siempre conectado y sincronizado. Las instalaciones físicas están diseñadas para ser directamente aplicables a la socialización, el aprendizaje, los negocios, el entretenimiento, la colaboración y los juegos.",
    infoTop:
      "Crea activos de juego únicos con MetaSpaces y tokenízalos. El sistema no interfiere en la operación y dirección de construcción dentro de MetaSpaces, los usuarios tienen autonomía absoluta. Pueden determinar completamente el valor final de los activos creados en MetaSpaces.",
    li1: "Mundo virtual basado en blockchain",
    li2: "Garantía absoluta de seguridad",
    li3: "Autenticidad del origen",
    li4: "Comerciable en mercados descentralizados",
    li5: "Construido con estándares de seguridad verificados",
    swiperTop: "Socios",
    caseTop: "Estudios de casos",
  },
  Build: {
    label1: "No construido",
    label2: "Construido",
    labeltext: "Sin datos",
  },
  MyPage: {
    Score: "Puntuación de crédito",
    JeCount1: "Monto congelado",
    JeCount2: "Monto disponible",
    EarNingsTop: "Ganancias",
    EarNingsInfo1: "Ganancias totales",
    EarNingsInfo2: "Pendiente de retiro",
    EarNingsInfo3: "Ganancias de hoy",
    EarNingsBtn: "Retirar ganancias",
    List1: "Recompensas por invitación",
    List2: "Historial de pedidos",
    List3: "Historial",
    List4: "Historial de préstamos",
    List5: "Terrenos ofertados",
    List6: "Centro de ayuda",
    msg: "Cierre de sesión exitoso",
  },
  MyTxPage: {
    Top: "Retirar ganancias",
    Top2: "Retirar USDC a tu billetera",
    info1: "Saldo de USDC en la plataforma",
    info2: "Introduce la cantidad de USDC que deseas intercambiar",
    btn: "Todo",
    info3: "Tarifa",
    info4: "Monto acreditado realmente",
    txmBA:
      "Tu solicitud de retiro será enviada a tu billetera dentro de 24 horas",
    txmBB: "Confirmar retiro",
    Msg1: "La solicitud de cobro ha sido enviada y está a la espera de revisión.",
    Msg2: "Retiro fallido, el monto del retiro no puede ser mayor que el saldo de la plataforma",
  },
  Order: {
    Top: "Historial de pedidos",
    wc: "Sin datos",
    sb: "Sin datos",
    ywc: "Completado",
    ysb: "Fallido",
  },
  History: {
    Top: "Historial",
    tis: "Sin datos",
    ShuoYi: "Ganancias de pedidos",
    JiLu: "Historial de retiros",
  },
  Bor: {
    Top: "Historial de préstamos",
    tis: "Sin datos",
  },
  Land: {
    Top: "Terrenos ofertados",
    tis: "Sin datos",
  },
  Ask: {
    Top: "Recompensas por invitación",
    info1: "Recompensas por invitación",
    info2: "Tamaño del equipo",
    info3: "Subordinados directos",
    info4: "Ingresos totales",
    info5: "Ganancias de hoy",
    a1: "Historial de invitaciones",
    btn1: "Retirar recompensas",
    AskUrlText: "Enlace de invitación",
    btn2: "Invitar ahora",
    tab1: "Dirección",
    tab2: "Hora",
    lab1: "Historial de activos",
    lab2: "Nivel uno",
    lab3: "Nivel dos",
    lab4: "Nivel tres",
  },
  AskRe: {
    Top: "Historial de invitaciones",
    text: "Sin datos",
  },
  Help: {
    Top: "Centro de ayuda",
    text1:
      "Meta land permite a los usuarios hacer ofertas en terrenos virtuales usando USDC y especular sobre terrenos ofertados para recuperar ganancias y terrenos virtuales. Por lo tanto, al igual que en la vida real, compra terrenos y completa la construcción para venderlos a un precio más alto. Los usuarios pueden poseer los NFT que crean una vez completados.",
    text2:
      "Precio del terreno C: 500. Tasa de interés diaria 1.3%, plazo: 3 días. Precio del terreno B: 5,000. Tasa de interés diaria 1.5%, plazo: 5 días. Precio del terreno A: 30,000. Tasa de interés diaria 1.9%, plazo: 7 días. Precio del terreno S: 100,000. Tasa de interés diaria 2.5%, plazo: 15 días. Precio del terreno SS: 300,000. Tasa de interés diaria 3.2%, plazo: 20 días. Precio del terreno SSS: 500,000. Tasa de interés diaria 4.0%, plazo: 30 días. Nota: Solo los terrenos de nivel S y superiores (incluido el nivel S) pueden ser construidos y generan más ganancias diarias.",
    tte1: "Formas de ganar dinero en MetaLand",
    tte2: "Planificación de terrenos en Meta Land",
  },
  Login: {
    lab1: "Conecta la billetera para explorar",
  },
  AppBar: {
    text1: "Inicio",
    text2: "Mapa",
    text3: "Construcción",
    text4: "Activos personales",
  },
  MsgPage: {
    Top: "Notificación",
    tte: "No hay datos disponibles",
  },
  QuickPurchase: {
    btnText: "Compra Rápida",
    landLvl: "Nivel de Tierra",
    onAuction: "En Subasta",
    earningRate: "Tasa de Ganancias",
    earnings: "Ganancias",
    size: "Tamaño",
    rules: "Reglas",
    bidding: "Puja",
    blockchain: "Cadena de bloques",
    ethereum: "Ethereum",
  },
  QuickPurchaseRules: {
    title: "¿Qué puedo hacer con la tierra?",
    rule1: "Ganar Recompensas Especiales",
    rule2: "Promesas exclusivas para titulares de MetalLand",
    rule3: "Vende tu tierra y gana ingresos",
    rule4: "Publica y monetiza tus creaciones",
  },
  BuildingDetail: {
    coordinates: "Coordenadas",
    owner: "Propietario",
    earningRate: "Tasa de Ganancias",
    earnings: "Ganancias",
    size: "Tamaño",
    token: "Token",
  },

  LoansTopName: "Reglas de Préstamo",
  LoansBtn: "Confirmar Solicitud de Préstamo",
  Loans_L1: "Crédito",
  Loans_L2: "Felicidades",
  Loans_L3:
    "Su calificación crediticia es buena, puede solicitar un préstamo. Solo necesita enviar una solicitud<br/>El servicio al cliente determinará el monto del préstamo basado en su calificación crediticia.",
  Loans_L4: "Confirmar Solicitud",
  Loans_L5: "Enviar solicitud de préstamo basada en puntos de crédito",
  Loans_L6: "Obtener Préstamo",
  Loans_L7: "Completar tareas de nivel superior",
  Loans_L8:
    "Continúe completando tareas para acumular puntos de crédito. El límite máximo de préstamo es",
  Loans_L9: "Monto",
  Loans_L10: "Días",
  Loans_L11: "Interés",
  Loans_L12: "Aceptar",
  Loans_L13: "Monto de Inversión",
  Loans_L14: "Porcentaje de Rendimiento",

  Build_L1: "Construcción",

  BOT_L1: "Construir Terreno",
  BOT_L2: "Precio del Terreno",
  BOT_L3: "Construir Ahora",
  BOT_Sel1: "Residencial",
  BOT_Sel2: "Público",
  BOT_L4: "Propietario",
  BOT_L5: "Ubicación:",
  BOT_L6: "Rendimiento Básico:",
  BOT_L7: "Rendimiento de Construcción:",
  BOT_L8: "Rendimiento:",
  BOT_L9: "Selección de Uso de Construcción:",
  BOT_L10: "Selección de Tipo de Construcción:",
  BOT_L11: "Seleccione un tipo de construcción:",
  BOT_L12: "Costo de Construcción",
  BOT_L13: "Reclamar NFT",

  Land_L1: "Monto de la Oferta",
  Land_L2: "Rendimiento Durante el Período",
  Land_L3: "Fin",
  Land_L4: "Ubicación",
  Land_L5: "Porcentaje de Rendimiento",
  Land_L6: "Rendimiento",
  Land_L7: "Días",

  Bor_L1: "Monto del Préstamo",
  Bor_L2: "Días",
  Bor_L3: "En Solicitud",
  Bor_L4: "Tiempo",
  Bor_L5: "Interés",
  Bor_L6: "Completado",

  Not_data_Msg: "No hay más datos",
};
