<template>
  <RouterView></RouterView>
</template>

<script setup>
import { createWeb3Modal } from "@web3modal/wagmi/vue";
import { reconnect } from "@wagmi/core";
import config, { projectId } from './utils/configweb3.js'

reconnect(config);

createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, //可选-默认为您的云配置
  enableOnramp: true, // 可选-默认为false
  themeMode: 'light',
  themeVariables: {
    '--w3m-accent': 'linear-gradient(74deg, rgb(0, 255, 240) 0%, rgb(104, 255, 101) 47.77%, rgb(250, 255, 0) 98.63%)',
    '--w3m-border-radius-master': '1px',
    '--wui-color-inverse-100': '#000'
  }
});

import "@/assets/css/index.css";
</script>
<style>
body {
  margin: 0 !important;
}
</style>>>>>
