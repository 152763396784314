export default{
  "home": {
    "HtD1": "Chơi cùng nhau, tạo ra giá trị",
    "HtD2": "Tạo ra và sở hữu đất ảo của bạn",
    "HtD3": "Hãy cùng nhau xây dựng một thế giới mới",
    "Btn": "Đi khám phá",
    "info1": "Tổng số người dùng",
    "info2": "Người dùng hoạt động",
    "info3": "Khối lượng giao dịch hàng ngày + hàng tháng + hàng quý",
    "BoxTop": "Giới thiệu về Metaverse (đất ảo)",
    "desc": "Metaverse có thể coi là một phản ánh của thế giới thực, có thể kết nối và đồng bộ hóa mọi lúc, các cơ sở vật chất thực tế được thiết kế để phù hợp với các hoạt động xã hội, học tập, thương mại, giải trí, hợp tác và trò chơi",
    "infoTop": "Xây dựng tài sản trò chơi độc đáo qua MetaSpaces và biến nó thành mã thông báo, hệ thống không can thiệp vào hoạt động và hướng phát triển trong MetaSpaces, người dùng có quyền tự quyết định giá trị cuối cùng của tài sản xây dựng trong MetaSpaces.",
    "li1": "Thế giới ảo blockchain",
    "li2": "Được bảo đảm tuyệt đối",
    "li3": "Độ chính xác của nguồn gốc",
    "li4": "Có thể giao dịch qua thị trường phi tập trung",
    "li5": "Xây dựng theo các tiêu chuẩn bảo mật đã được xác thực",
    "swiperTop": "Đối tác",
    "caseTop": "Trường hợp"
  },
  "Build": {
    "label1": "Chưa xây dựng",
    "label2": "Đã xây dựng",
    "labeltext": "Không có dữ liệu"
  },
  "MyPage": {
    "Score": "Điểm tín dụng",
    "JeCount1": "Số tiền bị khóa",
    "JeCount2": "Số tiền có sẵn",
    "EarNingsTop": "Thu nhập",
    "EarNingsInfo1": "Tổng thu nhập",
    "EarNingsInfo2": "Chờ rút",
    "EarNingsInfo3": "Thu nhập hôm nay",
    "EarNingsBtn": "Rút thu nhập",
    "List1": "Phần thưởng mời",
    "List2": "Lịch sử đơn hàng",
    "List3": "Lịch sử",
    "List4": "Lịch sử vay mượn",
    "List5": "Đất đã đấu giá",
    "List6": "Trung tâm trợ giúp",
    "msg": "Đăng xuất thành công"
  },
  "MyTxPage": {
    "Top": "Rút thu nhập",
    "Top2": "Rút USDC vào ví của bạn",
    "info1": "Số dư USDC trên nền tảng",
    "info2": "Nhập số lượng USDC bạn muốn đổi",
    "btn": "Tất cả",
    "info3": "Phí giao dịch",
    "info4": "Số tiền thực nhận",
    "txmBA": "Yêu cầu rút tiền của bạn sẽ được gửi đến ví của bạn trong vòng 24 giờ",
    "txmBB": "Xác nhận rút tiền",
    "Msg1": "Đơn xin thu thập đã được gửi và đang chờ xem xét",
    "Msg2": "Rút tiền thất bại, số tiền rút không được vượt quá số dư trên nền tảng"
  },
  "Order": {
    "Top": "Lịch sử đơn hàng",
    "wc": "Không có dữ liệu",
    "sb": "Không có dữ liệu",
    "ywc": "Đã hoàn thành",
    "ysb": "Thất bại"
  },
  "History": {
    "Top": "Lịch sử",
    "tis": "Không có dữ liệu",
    "ShuoYi": "Thu nhập từ đơn hàng",
    "JiLu": "Lịch sử rút tiền"
  },
  "Bor": {
    "Top": "Lịch sử vay mượn",
    "tis": "Không có dữ liệu"
  },
  "Land": {
    "Top": "Đất đã đấu giá",
    "tis": "Không có dữ liệu"
  },
  "Ask": {
    "Top": "Phần thưởng mời",
    "info1": "Phần thưởng mời",
    "info2": "Quy mô đội",
    "info3": "Cấp dưới trực tiếp",
    "info4": "Tổng thu nhập",
    "info5": "Thu nhập hôm nay",
    "a1": "Lịch sử mời",
    "btn1": "Nhận phần thưởng",
    "AskUrlText": "Liên kết mời",
    "btn2": "Đi mời",
    "tab1": "Địa chỉ",
    "tab2": "Thời gian",
    "lab1": "Lịch sử tài sản",
    "lab2": "Cấp độ một",
    "lab3": "Cấp độ hai",
    "lab4": "Cấp độ ba"
  },
  "AskRe": {
    "Top": "Lịch sử mời",
    "text": "Không có dữ liệu"
  },
  "Help": {
    "Top": "Trung tâm trợ giúp",
    "text1": "Meta land cho phép người dùng sử dụng USDC để đấu giá đất ảo và thực hiện đầu cơ từ đất và thu hồi thu nhập từ đất ảo. Do đó, giống như trong cuộc sống thực, mua đất và thực hiện xây dựng trên đất để bán với giá cao hơn. Khi hoàn thành, người dùng có thể sở hữu NFT mà mình tạo ra",
    "text2": "Đất C giá: 500. Lãi suất hàng ngày 1,3%, Thời gian: 3 ngày. Đất B giá: 5.000. Lãi suất hàng ngày 1,5%, Thời gian: 5 ngày. Đất A giá: 30.000. Lãi suất hàng ngày 1,9%, Thời gian: 7 ngày. Đất S giá: 100.000. Lãi suất hàng ngày 2,5%, Thời gian: 15 ngày. Đất SS giá: 300.000. Lãi suất hàng ngày 3,2%, Thời gian: 20 ngày. Đất SSS giá: 500.000. Lãi suất hàng ngày 4,0%, Thời gian: 30 ngày. Lưu ý: Chỉ có các lô đất cấp S hoặc cao hơn (bao gồm lô đất cấp S) mới có thể được xây dựng và tạo ra nhiều lợi nhuận hàng ngày hơn.",
    "tte1": "Những cách để kiếm tiền trên MetaLand",
    "tte2": "Về quy hoạch đất trong Meta Land"
  },
  "Login": {
    "lab1": "Kết nối ví Đi khám phá"
  },
  "AppBar": {
    "text1": "Trang chủ",
    "text2": "Bản đồ",
    "text3": "Xây dựng",
    "text4": "Tài sản cá nhân"
  },
  "MsgPage": {
    "Top": "Thông báo",
    "tte": "Không có dữ liệu"
  },
  "QuickPurchase": {
    "btnText": "Mua ngay",
    "landLvl": "Cấp độ đất",
    "onAuction": "Đang đấu giá",
    "earningRate": "Tỷ lệ thu nhập",
    "earnings": "Thu nhập",
    "size": "Kích thước",
    "rules": "Quy tắc",
    "bidding": "Đấu giá",
    "blockchain": "Blockchain",
    "ethereum": "Ethereum"
  },
  "QuickPurchaseRules": {
    "title": "Tôi có thể làm gì với đất?",
    "rule1": "Nhận phần thưởng đặc biệt",
    "rule2": "Cầm cố độc quyền cho chủ sở hữu MetaLand",
    "rule3": "Bán đất của bạn và kiếm thu nhập",
    "rule4": "Đăng tải và kiếm tiền từ sáng tạo của bạn"
  },
  "BuildingDetail": {
    "coordinates": "Tọa độ",
    "owner": "Chủ sở hữu",
    "earningRate": "Tỷ lệ thu nhập",
    "earnings": "Thu nhập",
    "size": "Kích thước",
    "token": "Mã thông báo"
  },

  "LoansTopName": "Quy tắc vay mượn",
  "LoansBtn": "Xác nhận đăng ký vay mượn",
  "Loans_L1": "Tín dụng",
  "Loans_L2": "Chúc mừng",
  "Loans_L3": "Xếp hạng tín dụng của bạn tốt, bạn có thể đăng ký vay mượn, bạn chỉ cần gửi đơn đăng ký<br/>Bộ phận dịch vụ khách hàng sẽ xác định hạn mức vay dựa trên xếp hạng tín dụng của bạn",
  "Loans_L4": "Xác nhận đăng ký",
  "Loans_L5": "Đăng ký vay theo điểm tín dụng",
  "Loans_L6": "Nhận khoản vay",
  "Loans_L7": "Hoàn thành nhiệm vụ cấp cao hơn",
  "Loans_L8": "Tiếp tục hoàn thành nhiệm vụ và tích lũy điểm tín dụng, hạn mức vay cao nhất là",
  "Loans_L9": "Số tiền",
  "Loans_L10": "Ngày",
  "Loans_L11": "Lãi suất",
  "Loans_L12": "Chấp nhận",
  "Loans_L13": "Số tiền đầu tư",
  "Loans_L14": "Tỷ lệ lợi nhuận",

  "Build_L1": "Xây dựng",

  "BOT_L1": "Xây dựng đất",
  "BOT_L2": "Giá đất",
  "BOT_L3": "Xây dựng ngay",
  "BOT_Sel1": "Nhà ở",
  "BOT_Sel2": "Công cộng",
  "BOT_L4": "Chủ sở hữu",
  "BOT_L5": "Vị trí:",
  "BOT_L6": "Thu nhập cơ bản:",
  "BOT_L7": "Thu nhập từ xây dựng:",
  "BOT_L8": "Thu nhập:",
  "BOT_L9": "Chọn mục đích xây dựng:",
  "BOT_L10": "Chọn loại xây dựng:",
  "BOT_L11": "Vui lòng chọn loại xây dựng:",
  "BOT_L12": "Chi phí xây dựng",
  "BOT_L13": "Rút NFT",

  "Land_L1": "Số tiền đấu giá",
  "Land_L2": "Thu nhập trong khoảng thời gian",
  "Land_L3": "Kết thúc",
  "Land_L4": "Vị trí",
  "Land_L5": "Tỷ lệ lợi nhuận",
  "Land_L6": "Thu nhập",
  "Land_L7": "Ngày",

  "Bor_L1": "Số tiền vay",
  "Bor_L2": "Ngày",
  "Bor_L3": "Đang xử lý",
  "Bor_L4": "Thời gian",
  "Bor_L5": "Lãi suất",
  "Bor_L6": "Hoàn thành",

  "Not_data_Msg": "Không còn dữ liệu"
}
