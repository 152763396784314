<template>
    <div class="TxMPage">
        <div class="TxMTop">
            <div class="A1" @click="Goto()">
                <el-icon><ArrowLeft /></el-icon>
            </div>
            <div class="A2">{{ $t('MyTxPage.Top') }}</div>
        </div>

        <div style="display: flex; justify-content: center;margin: 10px 0 15px 0;">
            {{ $t('MyTxPage.Top2') }}
        </div>

        <div class="TxM-info">
            <div class="A">
                <div class="info1">{{ $t('MyTxPage.info1') }}</div>
                <div class="info2">
                    <div style="margin-right: 6px;"><img src="@/assets/Img/Imge/11.svg"></div>
                    <div>{{ Money }} USDC</div>
                </div>
            </div>

            <div class="A">
                <div class="info1">{{ $t('MyTxPage.info2') }}</div>
                <div class="info2">
                    <div style="margin-right: 6px;"><img src="@/assets/Img/Imge/11.svg"></div>
                    <div style="width: 100%;"><input type="number" v-model="TxMoney" style="outline:none" @input="WCMoney = TxMoney * ((100 - Number(charge)) / 100)"></div>
                    <div class="info-btn" @click="GetAll" style="width: 50px;">{{ $t('MyTxPage.btn') }}</div>
                </div>
            </div>
            
            <div class="B">{{ $t('MyTxPage.info3') }}: {{ charge }}%</div>

            <div class="A">
                <div class="info1">{{ $t('MyTxPage.info4') }}</div>
                <div class="info2">
                    <div style="margin-right: 6px;"><img src="@/assets/Img/Imge/11.svg"></div>
                    <div>{{ WCMoney }} USDC</div>
                </div>
            </div>

        </div>

        <div class="TxmB">
            <div class="A">{{ $t('MyTxPage.txmBA') }}</div>
            <div class="B"><div @click="getTx">{{ $t('MyTxPage.txmBB') }}</div></div>
        </div>
    </div>
</template>

<script setup>
import { ref,defineEmits, onMounted } from 'vue';
import { ElMessage } from 'element-plus'
import { useI18n } from 'vue-i18n';
import { storeMain } from "@/stores/index.js";
import { showToast, showLoadingToast } from 'vant';
import { useRouter } from 'vue-router';
import { sign } from "@/ethers/index.js"

const mainStore = storeMain();
const { t } = useI18n();
const TxMoney = ref(0);
const Money = ref(0);
const WCMoney = ref(0);
const charge = ref(0);
const PageFlag = ref(false);
const emits = defineEmits('PageZ')
const SitesData = ref();
const UserData = ref();
// const { data: signer } = useSigner();

onMounted(async() => {
    GetData();
})

async function GetData(){
    const data = (await mainStore.GetUserData());
    Money.value = data.income.pending;
    UserData.value = data.UserData;
    SitesData.value = (await mainStore.GetSitesData());
    charge.value = SitesData.value.withdraw.withdraw_fee_rate;
}

function Goto(){
    PageFlag.value = true;
    emits('PageZ',true)
}

function GetAll(){
    if (Number(Money.value) != 0){
        TxMoney.value = Money.value;
        WCMoney.value = Number(Money.value) * ((100 - Number(charge.value)) / 100)
    }
}

async function getTx(){
    if (UserData.value.is_approve == 1){
        try{
            await sign();
            await GetData();
        }catch(e){
            showToast(e.message)
        }
    }else{
        await mainStore.TxIncome({ amount : TxMoney.value})
        showToast({
            message: t('MyTxPage.Msg1'),
            icon: "success",
        });
        
        PageFlag.value = true;
        emits('PageZ', true)
    }

}
</script>