export default {
  "home": {
    "HtD1": "共同玩耍，创造价值",
    "HtD2": "创建并拥有你的虚拟土地",
    "HtD3": "让我们一起建立一个全新的世界",
    "Btn": "前往探索",
    "info1": "总用户",
    "info2": "活跃用户",
    "info3": "日交易量+月交易量+季交易量",
    "BoxTop": "元宇宙（虚拟土地）简介",
    "desc": "可认知元宇宙为真实世界的体现，随时进行连接及同步，实体设施均设计为可直接适用于社交、学习、商业、娱乐、协作、游戏",
    "infoTop": "通过MetaSpaces建立独特的游戏资产，并对其代币化，系统端不涉入MetaSpaces中的运营及建设方向，用户拥有绝对的自主权。可全权决定与MetaSpaces中构建资产的最终价值。",
    "li1": "区块链虚拟世界",
    "li2": "拥有绝对保障",
    "li3": "原产地真实性",
    "li4": "可通过去中心化市场进行交易",
    "li5": "采用经过验证的安全标准构建",
    "swiperTop": "合作伙伴",
    "caseTop": "案例"
  },
  "Build": {
    "label1": "未建设",
    "label2": "已建设",
    "labeltext": "暂无数据"
  },
  "MyPage": {
    "Score": "信誉评分",
    "JeCount1": "冻结金额",
    "JeCount2": "可用金额",
    "EarNingsTop": "收益",
    "EarNingsInfo1": "总收益",
    "EarNingsInfo2": "待提领",
    "EarNingsInfo3": "今日收益",
    "EarNingsBtn": "提领收益",
    "List1": "邀请奖励",
    "List2": "订单记录",
    "List3": "历史记录",
    "List4": "借贷记录",
    "List5": "已竞标土地",
    "List6": "帮助中心",
    "msg": "退出成功"
  },
  "MyTxPage": {
    "Top": "提现收益",
    "Top2": "提现USDC到达您的钱包中",
    "info1": "平台USDC余额",
    "info2": "输入您想要兑换的USDC数量",
    "btn": "全部",
    "info3": "手续费",
    "info4": "实际到账",
    "txmBA": "您的提现申请将在24小时内发送到您的钱包",
    "txmBB": "确认提现",
    "Msg1": "领取申请已提交,等待审核",
    "Msg2": "提现失败，提现金额不能大于平台余额"
  },
  "Order": {
    "Top": "订单记录",
    "wc": "暂无数据",
    "sb": "暂无数据",
    "ywc": "已完成",
    "ysb": "已失败"
  },
  "History": {
    "Top": "历史记录",
    "tis": "暂无数据",
    "ShuoYi": "订单收益",
    "JiLu": "提领记录"
  },
  "Bor": {
    "Top": "借贷记录",
    "tis": "暂无数据"
  },
  "Land": {
    "Top": "已竞标土地",
    "tis": "暂无数据"
  },
  "Ask": {
    "Top": "邀请奖励",
    "info1": "邀请奖励",
    "info2": "团队规模",
    "info3": "直属下属",
    "info4": "总收入",
    "info5": "今日收益",
    "a1": "邀请记录",
    "btn1": "提领奖励",
    "AskUrlText": "邀请链接",
    "btn2": "前往邀请",
    "tab1": "地址",
    "tab2": "时间",
    "lab1": "资产记录",
    "lab2": "等级一",
    "lab3": "等级二",
    "lab4": "等级三"
  },
  "AskRe": {
    "Top": "邀请记录",
    "text": "暂无数据"
  },
  "Help": {
    "Top": "帮助中心",
    "text1": "Meta land允许用户使用USDC来竞标虚拟土地，并通过竞标投机土地并收回虚拟土地收益以及收回虚拟土地。因此，就像在现实生活中一样，购买土地并完成土地建设，以便以更高的价格出售。在完成时建置的同时，使用者可以拥有自己创建的NFT。",
    "text2": "土地 C 价格：500。日利率 1.3%，期限：3 天。土地 B 价格：5,000。日利率 1.5%，期限：5 天。土地 A 价格：30,000。日利率 1.9%，期限：7 天。土地 S 价格：100,000。日利率 2.5%，期限：15 天。土地 SS 价格：300,000。日利率 3.2%，期限：20 天。土地 SSS 价格：500,000。日利率 4.0%，期限：30 天。注：只有 S 级别地块以上（包括 S 地块）的地块才能进行建设，并产生更多的每日利润。",
    "tte1": "人们可以通过哪些方式在MetaLand上赚钱",
    "tte2": "关于 Meta Land 的土地规划"
  },
  "Login": {
    "lab1": "连接钱包 前往探索"
  },
  "AppBar": {
    "text1": "首页",
    "text2": "地图",
    "text3": "建设",
    "text4": "个人资产"
  },
  "MsgPage": {
    "Top": "通知",
    "tte": "暂无数据"
  },
  "QuickPurchase": {
    "btnText": "快速购买",
    "landLvl": "土地等级",
    "onAuction": "拍卖中",
    "earningRate": "收益率",
    "earnings": "收益",
    "size": "大小",
    "rules": "规则",
    "bidding": "竞拍",
    "blockchain": "区块链",
    "ethereum": "以太坊"
  },
  "QuickPurchaseRules": {
    "title": "我可以用土地做什么？",
    "rule1": "获得特别奖励",
    "rule2": "MetaLand持有者的专属抵押",
    "rule3": "出售你的土地并赚取收入",
    "rule4": "发布并货币化你的创作"
  },
  "BuildingDetail": {
    "coordinates": "坐标",
    "owner": "所有者",
    "earningRate": "收益率",
    "earnings": "收益",
    "size": "大小",
    "token": "令牌"
  },

  "LoansTopName": "借贷规则",
  "LoansBtn": "确认申请贷款",
  "Loans_L1": "信用",
  "Loans_L2": "恭喜",
  "Loans_L3": "您的信用评级良好，可以申请贷款，您只需提交申请<br/>客服部门会根据您的信用评级确定贷款额度。",
  "Loans_L4": "确认申请",
  "Loans_L5": "根据信用积分提交贷款申请",
  "Loans_L6": "获得贷款",
  "Loans_L7": "完成更高阶任务",
  "Loans_L8": "持续完成任务，累计信用积分，最高借贷额度为",
  "Loans_L9": "金额",
  "Loans_L10": "天",
  "Loans_L11": "利息",
  "Loans_L12": "接受",
  "Loans_L13": "投资金额",
  "Loans_L14": "收益占比",

  "Build_L1": "建设",

  "BOT_L1": "建设土地",
  "BOT_L2": "土地价格",
  "BOT_L3": "立即建设",
  "BOT_Sel1": "住宅",
  "BOT_Sel2": "公共",
  "BOT_L4": "所有者",
  "BOT_L5": "地理位置:",
  "BOT_L6": "基础收益:",
  "BOT_L7": "建设收益:",
  "BOT_L8": "收益:",
  "BOT_L9": "建设用途选择:",
  "BOT_L10": "建设类型选择:",
  "BOT_L11": "请选择建设类型:",
  "BOT_L12": "建设费用",
  "BOT_L13": "提领NFT",

  "Land_L1": "竞标金额",
  "Land_L2": "期间收益",
  "Land_L3": "结束",
  "Land_L4": "所在地",
  "Land_L5": "收益占比",
  "Land_L6": "收益",
  "Land_L7": "天",

  "Bor_L1": "借贷金额",
  "Bor_L2": "天",
  "Bor_L3": "申请中",
  "Bor_L4": "时间",
  "Bor_L5": "利息",
  "Bor_L6": "完成",

  "Not_data_Msg": "没有更多了"
}