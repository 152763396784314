export default{
  "home": {
    "HtD1": "Gioca insieme, crea valore",
    "HtD2": "Crea e possiedi il tuo terreno virtuale",
    "HtD3": "Costruiamo insieme un mondo completamente nuovo",
    "Btn": "Vai a esplorare",
    "info1": "Numero totale di utenti",
    "info2": "Utenti attivi",
    "info3": "Volume di scambi giornaliero + mensile + trimestrale",
    "BoxTop": "Introduzione al Metaverso (terreno virtuale)",
    "desc": "Il Metaverso può essere visto come un'estensione del mondo reale, sempre connesso e sincronizzato, con strutture fisiche progettate per essere direttamente applicabili a socializzazione, apprendimento, commercio, intrattenimento, collaborazione e giochi",
    "infoTop": "Costruisci beni di gioco unici tramite MetaSpaces e tokenizzali; il sistema non interferisce con le operazioni e la direzione di sviluppo in MetaSpaces, e gli utenti hanno un'autonomia assoluta. Puoi decidere completamente il valore finale degli asset costruiti in MetaSpaces.",
    "li1": "Mondo virtuale blockchain",
    "li2": "Garantito assolutamente",
    "li3": "Autenticità dell'origine",
    "li4": "Transazioni tramite mercato decentralizzato",
    "li5": "Costruito secondo standard di sicurezza verificati",
    "swiperTop": "Partner",
    "caseTop": "Casi"
  },
  "Build": {
    "label1": "Non costruito",
    "label2": "Già costruito",
    "labeltext": "Nessun dato"
  },
  "MyPage": {
    "Score": "Punteggio di credito",
    "JeCount1": "Importo bloccato",
    "JeCount2": "Importo disponibile",
    "EarNingsTop": "Guadagni",
    "EarNingsInfo1": "Guadagni totali",
    "EarNingsInfo2": "In attesa di prelievo",
    "EarNingsInfo3": "Guadagni odierni",
    "EarNingsBtn": "Preleva guadagni",
    "List1": "Premio di invito",
    "List2": "Storico ordini",
    "List3": "Storico",
    "List4": "Storico prestiti",
    "List5": "Terreno messo all'asta",
    "List6": "Centro assistenza",
    "msg": "Disconnesso con successo"
  },
  "MyTxPage": {
    "Top": "Preleva guadagni",
    "Top2": "Preleva USDC nel tuo portafoglio",
    "info1": "Saldo USDC sulla piattaforma",
    "info2": "Inserisci la quantità di USDC che desideri scambiare",
    "btn": "Tutti",
    "info3": "Commissioni",
    "info4": "Importo effettivo ricevuto",
    "txmBA": "La tua richiesta di prelievo sarà inviata al tuo portafoglio entro 24 ore",
    "txmBB": "Conferma prelievo",
    "Msg1": "La richiesta di riscossione è stata inviata ed è in attesa di revisione",
    "Msg2": "Prelievo fallito, l'importo del prelievo non può superare il saldo della piattaforma"
  },
  "Order": {
    "Top": "Storico ordini",
    "wc": "Nessun dato",
    "sb": "Nessun dato",
    "ywc": "Completato",
    "ysb": "Fallito"
  },
  "History": {
    "Top": "Storico",
    "tis": "Nessun dato",
    "ShuoYi": "Guadagni dagli ordini",
    "JiLu": "Storico prelievi"
  },
  "Bor": {
    "Top": "Storico prestiti",
    "tis": "Nessun dato"
  },
  "Land": {
    "Top": "Terreno messo all'asta",
    "tis": "Nessun dato"
  },
  "Ask": {
    "Top": "Premio di invito",
    "info1": "Premio di invito",
    "info2": "Dimensione del team",
    "info3": "Sottoposti diretti",
    "info4": "Guadagno totale",
    "info5": "Guadagno odierno",
    "a1": "Storico inviti",
    "btn1": "Richiedi premio",
    "AskUrlText": "Link di invito",
    "btn2": "Vai a invitare",
    "tab1": "Indirizzo",
    "tab2": "Tempo",
    "lab1": "Storico beni",
    "lab2": "Livello uno",
    "lab3": "Livello due",
    "lab4": "Livello tre"
  },
  "AskRe": {
    "Top": "Storico inviti",
    "text": "Nessun dato"
  },
  "Help": {
    "Top": "Centro assistenza",
    "text1": "Meta land consente agli utenti di utilizzare USDC per fare offerte su terreni virtuali e speculare su terreni per recuperare guadagni virtuali. Quindi, come nella vita reale, acquista terreni e costruisci su di essi per venderli a un prezzo più alto. Una volta completato, l'utente può possedere NFT creati da lui stesso.",
    "text2": "Terreno C prezzo: 500. Tasso giornaliero 1,3%, Durata: 3 giorni. Terreno B prezzo: 5.000. Tasso giornaliero 1,5%, Durata: 5 giorni. Terreno A prezzo: 30.000. Tasso giornaliero 1,9%, Durata: 7 giorni. Terreno S prezzo: 100.000. Tasso giornaliero 2,5%, Durata: 15 giorni. Terreno SS prezzo: 300.000. Tasso giornaliero 3,2%, Durata: 20 giorni. Terreno SSS prezzo: 500.000. Tasso giornaliero 4,0%, Durata: 30 giorni. Nota: Solo i lotti di terreno di livello S o superiore (incluso il terreno di livello S) possono essere costruiti e generare maggiori profitti giornalieri.",
    "tte1": "Come guadagnare su MetaLand",
    "tte2": "Pianificazione del terreno in Meta Land"
  },
  "Login": {
    "lab1": "Collega il portafoglio Vai a esplorare"
  },
  "AppBar": {
    "text1": "Home",
    "text2": "Mappa",
    "text3": "Costruisci",
    "text4": "Beni personali"
  },
  "MsgPage": {
    "Top": "Notifiche",
    "tte": "Nessun dato"
  },
  "QuickPurchase": {
    "btnText": "Acquisto veloce",
    "landLvl": "Livello terreno",
    "onAuction": "In asta",
    "earningRate": "Tasso di guadagno",
    "earnings": "Guadagni",
    "size": "Dimensione",
    "rules": "Regole",
    "bidding": "Offerta",
    "blockchain": "Blockchain",
    "ethereum": "Ethereum"
  },
  "QuickPurchaseRules": {
    "title": "Cosa posso fare con il terreno?",
    "rule1": "Ricevi premi speciali",
    "rule2": "Pegno esclusivo per i possessori di MetaLand",
    "rule3": "Vendi il tuo terreno e guadagna reddito",
    "rule4": "Pubblica e monetizza le tue creazioni"
  },
  "BuildingDetail": {
    "coordinates": "Coordinate",
    "owner": "Proprietario",
    "earningRate": "Tasso di guadagno",
    "earnings": "Guadagni",
    "size": "Dimensione",
    "token": "Token"
  },

  "LoansTopName": "Regole di prestito",
  "LoansBtn": "Conferma richiesta di prestito",
  "Loans_L1": "Credito",
  "Loans_L2": "Congratulazioni",
  "Loans_L3": "La tua valutazione del credito è buona, puoi richiedere un prestito, devi solo inviare una richiesta<br/>Il servizio clienti determinerà l'importo del prestito in base alla tua valutazione del credito.",
  "Loans_L4": "Conferma richiesta",
  "Loans_L5": "Richiedi prestito in base ai punti di credito",
  "Loans_L6": "Ottieni prestito",
  "Loans_L7": "Completa compiti di livello superiore",
  "Loans_L8": "Continua a completare compiti e accumulare punti di credito, il limite massimo del prestito è",
  "Loans_L9": "Importo",
  "Loans_L10": "Giorni",
  "Loans_L11": "Interesse",
  "Loans_L12": "Accetta",
  "Loans_L13": "Importo investito",
  "Loans_L14": "Tasso di rendimento",

  "Build_L1": "Costruisci",

  "BOT_L1": "Costruisci terreno",
  "BOT_L2": "Prezzo terreno",
  "BOT_L3": "Costruisci subito",
  "BOT_Sel1": "Residenziale",
  "BOT_Sel2": "Pubblico",
  "BOT_L4": "Proprietario",
  "BOT_L5": "Posizione:",
  "BOT_L6": "Guadagno di base:",
  "BOT_L7": "Guadagno da costruzione:",
  "BOT_L8": "Guadagno:",
  "BOT_L9": "Seleziona scopo di costruzione:",
  "BOT_L10": "Seleziona tipo di costruzione:",
  "BOT_L11": "Seleziona il tipo di costruzione:",
  "BOT_L12": "Costo di costruzione",
  "BOT_L13": "Ritiro NFT",

  "Land_L1": "Importo dell'offerta",
  "Land_L2": "Guadagno nel periodo",
  "Land_L3": "Fine",
  "Land_L4": "Posizione",
  "Land_L5": "Tasso di guadagno",
  "Land_L6": "Guadagno",
  "Land_L7": "Giorni",

  "Bor_L1": "Importo del prestito",
  "Bor_L2": "Giorni",
  "Bor_L3": "In lavorazione",
  "Bor_L4": "Tempo",
  "Bor_L5": "Interesse",
  "Bor_L6": "Completato",

  "Not_data_Msg": "Non ci sono più dati"
}
