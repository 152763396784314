import { defineStore } from "pinia";
import Port from "./Port";
import { useModalStatus } from "./modal.js";
import { showLoadingToast, showToast } from "vant";

export const storeMain = defineStore("main", {
  state: () => {
    return {
      loading: false,
      token: "",
      username: "",
    };
  },
  actions: {
    // 登录
    async handleLoginFlag(data) {
      this.loading = true;
      const ApiData = (await Port.LoginFlag(data)).data.data;
      this.token = ApiData.token;
      this.username = ApiData.username;
    },

    // 获取首页数据
    async GetHomeData() {
      return (await Port.GetHomeData()).data.data;
    },

    // 竞拍
    async JingPai(type, v) {
        this.loading = true;
        showLoadingToast({
            message: "",
            forbidClick: this.loading,
            loadingType: "spinner",
        });
      const modalStatus = useModalStatus();
      // 竞拍
      if (type == 1) {
        await Port.JingPai(v);
        modalStatus.setModalBuildingDetail(false);
      }
      // 快速竞拍
      else if (type == 2) {
        await Port.QuickJingPai(v);
        modalStatus.setModalQuickPurchase(false);
      }
      
      showToast({
        message: "",
        icon: "success",
      });

    },

    // 获取用户数据
    async GetUserData() {
      const data = (await Port.GetUserData()).data.data;
      return {
        UserData: data,
        income: data.income,
        loan: data.loan,
      };
    },

    //获取竞标列表
    async GetWjsData(data) {
      return (await Port.GetWjsData(data)).data.data;
    },

    // 获取建设用途
    async GetBuildType(data) {
      return (await Port.GetBuildType(data)).data.data;
    },

    // 建设
    async Build(data) {
      await Port.Build(data);
    },

    // 获取邀请信息
    async GetAskData() {
      return (await Port.GetAskData()).data.data;
    },

    // 获取订单收益
    async GetOrderYieldData(data){
        return (await Port.GetOrderYieldData(data)).data.data;
    },

    // 获取提领记录
    async GetWitData(data){
        return (await Port.GetWitData(data)).data.data;
    },

    // 贷款
    async DaiKuan(data){
        await Port.DaiKuan(data);
    },

    // 借款记录
    async GetDaiKData(data){
        return (await Port.GetDaiKData(data)).data.data;
    },

    // 退出登录
    async Logout(){
        await Port.Logout();
    },

    // 提现收益
    async TxIncome(data){
        await Port.TxIncome(data);
    },

    // 获取帮助中心信息
    async GetHelpData(){
        return (await Port.GetHelpData()).data.data;
    },

    // 获取网站配置
    async GetSitesData(){
        return (await Port.GetSitesData()).data.data;
    },

    // 获取土地等级
    async GetLandLevel(){
        return (await Port.GetLandLevel()).data.data;
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'pinia_store', // 可以根据需要自定义 key
        storage: localStorage, // 使用 localStorage
        // 可以省略 paths 来存储所有 state 数据
      },
    ]
  },
});