<template>
    <van-loading style="z-index: 999999;width: 100vw;height: 100vh;display: flex;align-items: center;justify-content: center;" size="100" v-if="mainStore.loading"/>
    <div class="OrderPage" v-if="!mainStore.loading">

        <div class="OrderTop">
            <div class="A1" @click="Goto()" style="width: 30px;">
                <el-icon>
                    <ArrowLeft />
                </el-icon>
            </div>
            <div class="A2">{{ $t('Order.Top') }}</div>
        </div>

        <div>
            <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
                <el-tab-pane :label="$t('Order.ywc')" name="wc" style="padding-bottom: 70px;">
                    <div class="Order-BckImg" v-if="YwcData.length == 0">
                        <img src="@/assets/Img/Imge/8.svg">
                        <div>{{ $t('Order.wc') }}</div>
                    </div>
                    <van-list v-model:loading="loading" :finished="finished" :finished-text="$t('Not_data_Msg')"
                        @load="onLoadYwc" v-if="YwcData.length != 0">
                        <div v-for="(data, index) in YwcData" :key="index" class="css-1t7ooz7">
                            <div class="top">{{ ConvertTime(data.time,1) }}</div>
                            <div class="info isFlex">
                                <div class="left">{{ ConvertTime(data.time) }}</div>
                                <div class="right">{{ data.price }}</div>
                            </div>
                        </div>
                    </van-list>
                </el-tab-pane>
                <el-tab-pane :label="$t('Order.ysb')" name="sb" style="padding-bottom: 70px;">
                    <div class="Order-BckImg" v-if="YsxData.length == 0">
                        <img src="@/assets/Img/Imge/8.svg">
                        <div>{{ $t('Order.sb') }}</div>
                    </div>

                    <van-list v-model:loading="loading" :finished="finished" :finished-text="$t('Not_data_Msg')"
                        @load="onLoadYsx" v-if="YsxData.length != 0">
                        <div v-for="(data, index) in YsxData" :key="index" class="css-1t7ooz7">
                            <div class="top">{{ ConvertTime(data.time, 1) }}</div>
                            <div class="info isFlex">
                                <div class="left">">{{ ConvertTime(data.time) }}</div>
                                <div class="right">{{ data.price }}</div>
                            </div>
                        </div>
                    </van-list>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script setup>
import { ref,defineEmits, onMounted } from 'vue'
import { storeMain } from "@/stores/index.js";

const activeName = ref('wc')
const emits = defineEmits(['PageZ'])
const mainStore = storeMain();
const loading = ref(false);
const finished = ref(false);

const YwcData = ref([]);
const YsxData = ref([]);
let PageIndex = 1

onMounted(async() => {
    mainStore.loading = true;
    await GetYwcData();
    await GetYsxData();
})

async function GetYwcData(){
    const arr1 = await mainStore.GetWjsData({ page: PageIndex, size: 10, is_build: 1 })
    const arr2 = await mainStore.GetWjsData({ page: PageIndex, size: 10, is_build: 2 })
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i].state != 3) {
            YwcData.value.push(arr1[i])
        }
    }
    for (let i = 0; i < arr2.length; i++) {
        if (arr1[i].state != 3) {
            YwcData.value.push(arr2[i])
        }
    }

    if(arr1.length == 0 && arr2.length == 0){
        finished.value = true;
    }
}

async function GetYsxData(){
    const arr1 = await mainStore.GetWjsData({ page: PageIndex, size: 10, is_build: 1 })
    const arr2 = await mainStore.GetWjsData({ page: PageIndex, size: 10, is_build: 2 })
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i].state == 3) {
            YsxData.value.push(arr1[i])
        }
    }
    for (let i = 0; i < arr2.length; i++) {
        if (arr1[i].state == 3) {
            YsxData.value.push(arr2[i])
        }
    }

    if (arr1.length == 0 && arr2.length == 0) {
        finished.value = true;
    }
}

// 时间戳转换
function ConvertTime(time, v) {
    let date = new Date(Number(time) * 1000);
    if (v == 1) {
        return date.getFullYear() + '-' +
            String(date.getMonth() + 1).padStart(2, '0') + '-' +
            String(date.getDate()).padStart(2, '0')
    } else {
        return date.getFullYear() + '-' +
            String(date.getMonth() + 1).padStart(2, '0') + '-' +
            String(date.getDate()).padStart(2, '0') + ' ' +
            String(date.getHours()).padStart(2, '0') + ':' +
            String(date.getMinutes()).padStart(2, '0') + ':' +
            String(date.getSeconds()).padStart(2, '0');
    }
}

function Goto(){
    emits('PageZ',true)
}

async function handleClick(v){
    PageIndex = 1;
    if (v.props.name == 'wc'){
        YwcData.value = [];
        await GetYwcData();
    }
    if (v.props.name == 'sb'){
        YsxData.value = [];
        await GetYsxData()
    }
}

const onLoadYwc = async() => {
    PageIndex++;
    await GetYwcData();
    loading.value = false;
};

const onLoadYsx = async () => {
    PageIndex++;
    await GetYsxData();
    loading.value = false;
};
</script>

<style scoped>
.css-1t7ooz7 {
    border-bottom: 1px solid rgba(255, 255, 255, 0.306);
    padding: 10px 0px;
}

.css-1t7ooz7 .top {
    color: rgb(153, 153, 153);
    font-size: 12px;
}

.css-1t7ooz7 .isFlex {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.css-1t7ooz7 .info {
    color: rgb(238, 238, 238);
    font-size: 14px;
    font-weight: bold;
    padding: 7px 0px;
}
</style>