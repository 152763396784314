<template>
    <div class="MsgTop" style="padding-bottom: 100px;">
        <div class="MsgTop-info">
            <div class="A1" @click="Goto()">
                <el-icon>
                    <ArrowLeft />
                </el-icon>
            </div>
            <div class="A2">{{ $t('BOT_L1') }}</div>
        </div>

        <div class="main">
            <img src="@/assets/Img/Imge/BulidLogo.svg" style="border-radius: 10px;width: 100%;height: auto;">
        </div>

        <div class="title">{{ props.PageData.name }} : {{ props.PageData.level_name }}</div>

        <div class="land-price-main">
            <div class="text-color-active">{{ $t("BOT_L2") }} {{ props.PageData.price }} USDC</div>
        </div>

        <div class="built-info">
            <div class="top">
                <div class="left">{{ $t('BOT_L4') }}<div class="address"> {{ props.PageData.address }}</div>
                </div>
                <div class="right">
                    <img src="@/assets/Img/BOTImge/1.svg" style="margin: -3px 5px 0px 0px; height: 15px;">
                    {{ $t('BOT_L5') }}: -{{ props.PageData.x }}
                </div>
            </div>
            <div class="info_">
                <div class="info_left">
                    <div class="info_flex" style="position: relative;">
                        <div class="ant-space-item" @click="OpenSel(1)">{{ UseType }}</div>
                        <div class="ant-space-item" @click="OpenSel(1)">
                            <el-icon>
                                <ArrowDown />
                            </el-icon>
                        </div>

                        <Transition name="SelPop">
                            <div style="position: absolute;top: 20px;background: linear-gradient(74deg, rgb(0, 255, 240) 0%, rgb(104, 255, 101) 47.77%, rgb(250, 255, 0) 98.63%);
                                width: 100%;color: black;text-align: center;font-weight: bold;border-radius: 5px;width: 100px;z-index: 1000;
                            " v-show="UseFlag" class="Sel">
                                <div style="padding: 10px 0;" v-for="(data, index) in UseArr" :key="index"
                                    @click="SelUse(data.type)">{{ data.type }}</div>
                            </div>
                        </Transition>
                    </div>
                </div>
                <div class="info_right" style="justify-content: right;padding-right: 10px;">
                    <div class="info_flex" style="position: relative;min-width: 100px;justify-content: right;">
                        <div class="ant-space-item" @click="OpenSel(2)">{{ UseTel }}</div>
                        <div class="ant-space-item" @click="OpenSel(2)">
                            <el-icon>
                                <ArrowDown />
                            </el-icon>
                        </div>

                        <Transition name="SelPop">
                            <div style="position: absolute;top: 20px;background: linear-gradient(74deg, rgb(0, 255, 240) 0%, rgb(104, 255, 101) 47.77%, rgb(250, 255, 0) 98.63%);
                                width: 100%;color: black;text-align: center;font-weight: bold;border-radius: 5px;width: 100px;z-index: 1000;
                            " v-show="UseTypeFlag" class="Sel">
                                <div style="padding: 10px 0;" v-for="(data, index) in UseTypeArr" :key="index"
                                    @click="SelTypeUse(data.type)">{{ data.type }}</div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </div>
            <div class="info_ info_bg_">
                <div class="name">{{ $t('BOT_L12') }}</div>
                <div class="name">{{ BuildData.cost}} USDC</div>
            </div>
            <div class="info_ info_bg_">
                <div class="name">{{ $t('BOT_L6') }}</div>
                <div class="name">{{ BuildData.base_interest_rate }}%</div>
            </div>
            <div class="info_ info_bg_">
                <div class="name">{{ $t('BOT_L7') }}</div>
                <div class="name">{{ BuildData.interest_rate }}%</div>
            </div>
            <div class="info_ info_bg_">
                <div class="name">{{ $t('BOT_L8') }}</div>
                <div class="name">{{ earnings }}</div>
            </div>
        </div>

        <div style="margin: 20px 0;text-align: center;">
            <span style="background: linear-gradient(74deg, rgb(0, 255, 240) 0%, rgb(104, 255, 101) 47.77%, rgb(250, 255, 0) 98.63%);
                color: black;font-weight: bold;padding: 10px 12px;border-radius: 10px;
                " @click="GoBuild()">
                {{$t('BOT_L3') }}
            </span>
        </div>

        <!-- <div v-if="UseFlag || UseTypeFlag" class="ZZZ" style="position: absolute;top: 0;left: 0;width: 100vw;height: 100vh;z-index: 999;"></div> -->
    </div>
</template>

<script setup>
import { defineEmits,defineProps, onMounted, onUnmounted, ref } from 'vue'
import { storeMain } from "@/stores/index.js";
import { showToast } from 'vant';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const store = storeMain();
const emits = defineEmits('BOT')
const props = defineProps({
    PageData:Array
})
const UseType = ref(t('BOT_L9'))
const UseFlag = ref(false)
const UseTel = ref(t('BOT_L10'))
const UseTypeFlag = ref(false)

const BuildData = ref({cost:0,base_interest_rate:0,interest_rate:0,id:null})
const earnings = ref(0)

function Goto() {
    emits('BOT', true)
}

const BuildTypeData = ref([])
const UseArr = ref([])
const UseTypeArr = ref([{'type':'Loading...'}])

onMounted(async() => {
    BuildTypeData.value = await store.GetBuildType({ level_name: props.PageData.level_name });
    UseArr.value = BuildTypeData.value.reduce((acc, current) => {
        let cleanedUsageName = current.usage_name.trim().toLowerCase(); // 清理字符串
        if (!acc.find(item => item.type === cleanedUsageName)) {
            acc.push({ 'type': cleanedUsageName });
        }
        return acc;
    }, []);
})

function OpenSel(i){
    if(i == 1){
        UseFlag.value = true;
    }else if(i == 2){
        UseTypeFlag.value = true;
    }
    window.addEventListener('click', handleClick);
}

// 选择用途
function SelUse(data) {
    UseType.value = data;
    let arr = []
    BuildTypeData.value.forEach(item => {
        if (item.usage_name.trim().toLowerCase() === data.trim().toLowerCase()) {
            // 检查是否已经存在相同的 type
            if (!arr.some(el => el.type === item.type_name)) {
                arr.push({ 'type': item.type_name });
            }
        }
    });
    UseTypeArr.value = arr;
    UseTel.value = t('BOT_L10');
    BuildData.value = { cost: 0, base_interest_rate: 0, interest_rate: 0, id: null }
    earnings.value = 0;
    UseFlag.value = false; // 关闭选择框
}

// 选择类型
function SelTypeUse(data){
    if (data != 'Loading...'){
        UseTel.value = data;
    }
    UseTypeFlag.value = false;

    BuildTypeData.value.forEach((item) => {
        if (item.usage_name.trim().toLowerCase() == UseType.value && item.type_name.trim().toLowerCase() == UseTel.value){
            BuildData.value = item
            return
        }
    })
    // 转换字符串为数值类型
    const landPrice = Number(props.PageData.land_price);
    const baseInterestRate = Number(BuildData.value.base_interest_rate);
    const interestRate = Number(BuildData.value.interest_rate);
    const landPeriod = Number(props.PageData.land_period);
    if (isNaN(landPrice) || isNaN(baseInterestRate) || isNaN(interestRate) || isNaN(landPeriod)) {
        console.error('One of the values is not a valid number');
    } else {
        // 进行计算
        earnings.value = (landPrice * ((baseInterestRate + interestRate) / 100) * landPeriod).toFixed(2);
    }
}

// 建设
async function GoBuild(){
    console.log(BuildData.value)
    if (BuildData.value.id != null) {
        await store.Build(
            {
                order_id: props.PageData.id,
                build_id: BuildData.value.id
            }
        )
        showToast('Succeed')
        emits('BOT', true)
    }else{
        showToast(t('BOT_L11'));
    }
    
}

// 定义事件处理函数
function handleClick(event) {
    if (event.target.className != 'Sel' && event.target.parentElement.className != 'info_flex' && event.target.parentElement.className != 'el-icon') {
        window.removeEventListener('click', handleClick);
        UseFlag.value = false;
        UseTypeFlag.value = false;
    }
}
</script>

<style scoped>
div{
    box-sizing: border-box;
}
.main {
    width: 240px;
    height: 240px;
    background: linear-gradient(74deg, rgb(0, 255, 240) 0%, rgb(104, 255, 101) 47.77%, rgb(250, 255, 0) 98.63%);
    border-radius: 10px;
    margin: 10px auto;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 1px;
}

.main .img {
    width: 100%;
    height: 100%;
}

.title {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 10px 0px;
}

.land-price-main {
    margin: 10px auto;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.land-price-main .text-color-active {
    background-image: linear-gradient(74deg, rgb(0, 255, 240) 0%, rgb(104, 255, 101) 47.77%, rgb(250, 255, 0) 98.63%);
    -webkit-text-fill-color: transparent;
    font-weight: bold;
    border: 1px solid rgb(104, 255, 101);
    padding: 6px 20px;
    border-radius: 8px;
    background-clip: text !important;
}

.built-info {
    margin: 30px 0px;
}

.built-info .top {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    font-size: 13px;
}

.built-info .top .left {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.built-info .top .right {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

 .built-info .top .left .address {
     width: 100px;
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
     padding-left: 10px;
     background-image: linear-gradient(74deg, rgb(0, 255, 240) 0%, rgb(104, 255, 101) 47.77%, rgb(250, 255, 0) 98.63%);
     background-position: initial;
     background-size: initial;
     background-repeat: initial;
     background-attachment: initial;
     background-origin: initial;
     background-color: initial;
     -webkit-text-fill-color: transparent;
     font-weight: bold;
     background-clip: text !important;
 }

.built-info .info_ {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin: 15px 0px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}

.built-info .info_ .info_left,
.built-info .info_ .info_right {
    width: 44%;
    height: 100%;
    display: flex;
    -webkit-box-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    align-items: center;
    background: rgb(21, 23, 21);
    border-radius: 10px;
    padding-left: 10px;
}

.info_flex{
    display: flex;
    align-items: center;
    column-gap: 8px;

}

.built-info .info_bg_ {
    background: rgb(21, 23, 21);
    padding: 0px 10px;
}


.SelPop-enter-active,
.SelPop-leave-active {
    transition: all 0.3s ease;
}

/* 进入时的初始状态：从上方淡入 */
.SelPop-enter-from {
    transform: translateY(-20%);
    opacity: 0;
}

/* 进入时的最终状态：在原位置显示 */
.SelPop-enter-to {
    transform: translateY(0);
    opacity: 1;
}

/* 离开时的初始状态：在原位置 */
.SelPop-leave-from {
    transform: translateY(0);
    opacity: 1;
}

/* 离开时的最终状态：向下方淡出 */
.SelPop-leave-to {
    transform: translateY(-20%);
    opacity: 0;
}
</style>