export default {
  "home": {
    "HtD1": "Gemeinsam spielen, Werte schaffen",
    "HtD2": "Erstellen und besitzen Sie Ihr virtuelles Land",
    "HtD3": "Lassen Sie uns gemeinsam eine ganz neue Welt aufbauen",
    "Btn": "Jetzt erkunden",
    "info1": "Gesamtbenutzer",
    "info2": "Aktive Benutzer",
    "info3": "Tägliches Handelsvolumen + monatliches Handelsvolumen + vierteljährliches Handelsvolumen",
    "BoxTop": "Einführung in das Metaversum (virtuelles Land)",
    "desc": "Das Metaversum kann als Abbild der realen Welt angesehen werden, jederzeit verbunden und synchronisiert, wobei physische Einrichtungen für soziale Interaktionen, Lernen, Geschäft, Unterhaltung, Zusammenarbeit und Spiele geeignet sind.",
    "infoTop": "Durch MetaSpaces können einzigartige Spielressourcen erstellt und tokenisiert werden. Das System beteiligt sich nicht an der Verwaltung und Ausrichtung von MetaSpaces. Die Benutzer haben die vollständige Entscheidungsfreiheit und Kontrolle über den Wert der in MetaSpaces geschaffenen Ressourcen.",
    "li1": "Blockchain virtuelle Welt",
    "li2": "Absolute Sicherheit",
    "li3": "Authentizität des Ursprungs",
    "li4": "Handel über dezentrale Märkte möglich",
    "li5": "Mit verifizierten Sicherheitsstandards gebaut",
    "swiperTop": "Partner",
    "caseTop": "Beispiele"
  },
  "Build": {
    "label1": "Nicht gebaut",
    "label2": "Gebaut",
    "labeltext": "Keine Daten vorhanden"
  },
  "MyPage": {
    "Score": "Reputationsbewertung",
    "JeCount1": "Eingefrorenes Guthaben",
    "JeCount2": "Verfügbares Guthaben",
    "EarNingsTop": "Einkommen",
    "EarNingsInfo1": "Gesamteinkommen",
    "EarNingsInfo2": "Ausstehend",
    "EarNingsInfo3": "Tägliches Einkommen",
    "EarNingsBtn": "Einkommen abheben",
    "List1": "Einladungsprämien",
    "List2": "Bestellhistorie",
    "List3": "Historie",
    "List4": "Kreditverlauf",
    "List5": "Gebotene Grundstücke",
    "List6": "Hilfezentrum",
    "msg": "Erfolgreich abgemeldet"
  },
  "MyTxPage": {
    "Top": "Einkommen abheben",
    "Top2": "USDC wird auf Ihre Brieftasche überwiesen",
    "info1": "Plattform USDC-Balance",
    "info2": "Geben Sie die Menge an USDC ein, die Sie umtauschen möchten",
    "btn": "Alles",
    "info3": "Gebühren",
    "info4": "Tatsächlich erhalten",
    "txmBA": "Ihre Abhebungsanfrage wird innerhalb von 24 Stunden an Ihre Brieftasche gesendet",
    "txmBB": "Abhebung bestätigen",
    "Msg1": "Der Inkassoantrag wurde eingereicht und wartet auf Prüfung",
    "Msg2": "Abhebung fehlgeschlagen, der Abhebungsbetrag darf das Plattformguthaben nicht überschreiten"
  },
  "Order": {
    "Top": "Bestellhistorie",
    "wc": "Keine Daten vorhanden",
    "sb": "Keine Daten vorhanden",
    "ywc": "Abgeschlossen",
    "ysb": "Fehlgeschlagen"
  },
  "History": {
    "Top": "Historie",
    "tis": "Keine Daten vorhanden",
    "ShuoYi": "Bestellgewinn",
    "JiLu": "Abhebungsaufzeichnungen"
  },
  "Bor": {
    "Top": "Kreditverlauf",
    "tis": "Keine Daten vorhanden"
  },
  "Land": {
    "Top": "Gebotene Grundstücke",
    "tis": "Keine Daten vorhanden"
  },
  "Ask": {
    "Top": "Einladungsprämien",
    "info1": "Einladungsprämien",
    "info2": "Teamgröße",
    "info3": "Direkte Untergebene",
    "info4": "Gesamteinkommen",
    "info5": "Tägliches Einkommen",
    "a1": "Einladungshistorie",
    "btn1": "Prämie abheben",
    "AskUrlText": "Einladungslink",
    "btn2": "Zur Einladung gehen",
    "tab1": "Adresse",
    "tab2": "Zeit",
    "lab1": "Vermögensaufzeichnungen",
    "lab2": "Stufe eins",
    "lab3": "Stufe zwei",
    "lab4": "Stufe drei"
  },
  "AskRe": {
    "Top": "Einladungshistorie",
    "text": "Keine Daten vorhanden"
  },
  "Help": {
    "Top": "Hilfezentrum",
    "text1": "Meta Land ermöglicht es Benutzern, USDC zu verwenden, um auf virtuelles Land zu bieten, Spekulationen anzustellen und Erträge aus virtuellem Land zu erhalten. Ähnlich wie im echten Leben können Sie Land kaufen, es entwickeln und zu einem höheren Preis verkaufen. Gleichzeitig können Sie ein NFT erstellen, wenn Sie das Land bauen.",
    "text2": "Land C Preis: 500. Tageszins 1,3%, Laufzeit: 3 Tage. Land B Preis: 5.000. Tageszins 1,5%, Laufzeit: 5 Tage. Land A Preis: 30.000. Tageszins 1,9%, Laufzeit: 7 Tage. Land S Preis: 100.000. Tageszins 2,5%, Laufzeit: 15 Tage. Land SS Preis: 300.000. Tageszins 3,2%, Laufzeit: 20 Tage. Land SSS Preis: 500.000. Tageszins 4,0%, Laufzeit: 30 Tage. Hinweis: Nur Grundstücke der Stufe S oder höher (einschließlich Stufe S) können entwickelt werden und erzeugen höhere tägliche Erträge.",
    "tte1": "Wie können Menschen auf MetaLand Geld verdienen?",
    "tte2": "Über die Grundstücksplanung von Meta Land"
  },
  "Login": {
    "lab1": "Brieftasche verbinden und erkunden"
  },
  "AppBar": {
    "text1": "Startseite",
    "text2": "Karte",
    "text3": "Bauen",
    "text4": "Persönliches Vermögen"
  },
  "MsgPage": {
    "Top": "Benachrichtigungen",
    "tte": "Keine Daten vorhanden"
  },
  "QuickPurchase": {
    "btnText": "Schnellkauf",
    "landLvl": "Grundstücksstufe",
    "onAuction": "Zur Versteigerung",
    "earningRate": "Ertragsrate",
    "earnings": "Einkommen",
    "size": "Größe",
    "rules": "Regeln",
    "bidding": "Bieten",
    "blockchain": "Blockchain",
    "ethereum": "Ethereum"
  },
  "QuickPurchaseRules": {
    "title": "Was kann ich mit dem Land machen?",
    "rule1": "Besondere Prämien erhalten",
    "rule2": "Exklusive Sicherheiten für MetaLand-Besitzer",
    "rule3": "Verkaufen Sie Ihr Land und verdienen Sie Einkommen",
    "rule4": "Veröffentlichen und monetisieren Sie Ihre Kreationen"
  },
  "BuildingDetail": {
    "coordinates": "Koordinaten",
    "owner": "Eigentümer",
    "earningRate": "Ertragsrate",
    "earnings": "Einkommen",
    "size": "Größe",
    "token": "Token"
  },

  "LoansTopName": "Kreditregeln",
  "LoansBtn": "Kreditantrag bestätigen",
  "Loans_L1": "Kredit",
  "Loans_L2": "Glückwunsch",
  "Loans_L3": "Ihre Kreditbewertung ist gut, Sie können einen Kredit beantragen. Sie müssen nur einen Antrag einreichen.<br/>Der Kundenservice wird den Kreditbetrag basierend auf Ihrer Kreditbewertung bestimmen.",
  "Loans_L4": "Antrag bestätigen",
  "Loans_L5": "Kreditantrag basierend auf Kreditpunkten stellen",
  "Loans_L6": "Kredit erhalten",
  "Loans_L7": "Höhere Aufgaben abschließen",
  "Loans_L8": "Schließen Sie weiterhin Aufgaben ab, sammeln Sie Kreditpunkte, der maximale Kreditbetrag beträgt",
  "Loans_L9": "Betrag",
  "Loans_L10": "Tage",
  "Loans_L11": "Zinsen",
  "Loans_L12": "Akzeptieren",
  "Loans_L13": "Investitionsbetrag",
  "Loans_L14": "Ertragsanteil",

  "Build_L1": "Bauen",

  "BOT_L1": "Grundstück bauen",
  "BOT_L2": "Grundstückspreis",
  "BOT_L3": "Jetzt bauen",
  "BOT_Sel1": "Wohngebäude",
  "BOT_Sel2": "Öffentlich",
  "BOT_L4": "Eigentümer",
  "BOT_L5": "Standort:",
  "BOT_L6": "Grundlegeendes Einkommen:",
  "BOT_L7": "Bauertrag:",
  "BOT_L8": "Einkommen:",
  "BOT_L9": "Nutzungswahl für den Bau:",
  "BOT_L10": "Bauartwahl:",
  "BOT_L11": "Bitte Bauart wählen:",
  "BOT_L12": "Baukosten",
  "BOT_L13": "NFT abheben",

  "Land_L1": "Gebotsbetrag",
  "Land_L2": "Erträge während des Zeitraums",
  "Land_L3": "Beendet",
  "Land_L4": "Standort",
  "Land_L5": "Ertragsanteil",
  "Land_L6": "Einkommen",
  "Land_L7": "Tage",

  "Bor_L1": "Kreditbetrag",
  "Bor_L2": "Tage",
  "Bor_L3": "In Bearbeitung",
  "Bor_L4": "Zeit",
  "Bor_L5": "Zinsen",
  "Bor_L6": "Abgeschlossen",

  "Not_data_Msg": "Keine weiteren Daten"
}
