import { defineStore } from "pinia";

export const useModalStatus = defineStore("modal_status", {
  state: () => {
    return {
      isQuickPurchase: false,
      isBiddingRule: false,
      isBuildingDetail: false,
    };
  },
  actions: {
    setModalQuickPurchase(bool) {
      this.isQuickPurchase = bool;
      this.setBodyFreeze(bool);
    },
    setModalBiddingRule(bool) {
      this.isBiddingRule = bool;
      this.setBodyFreeze(bool);
    },
    setModalBuildingDetail(bool) {
      this.isBuildingDetail = bool;
      this.setBodyFreeze(bool);
    },
    setBodyFreeze(bool) {
      const body = document.querySelector("body");
      if (bool) {
        body.style.overflowY = "hidden";
      } else {
        body.style.overflowY = "visible";
      }
    },
  },
});
