import en from '@/assets/lang/en.js'
import hk from '@/assets/lang/hk.js'
import zh from '@/assets/lang/zh.js'
import ko from "../lang/ko";
import de from "@/assets/lang/de.js"
import es from '../lang/es.js'
import fr from '../lang/fr.js'
import jq from '../lang/jq';
import th from '@/assets/lang/th.js'
import vi from "@/assets/lang/vi.js";
import it from "@/assets/lang/it.js";
import { createI18n } from 'vue-i18n'


const cur_lang = localStorage.getItem('Language') ? localStorage.getItem('Language') : 'en';

const i18n = createI18n({
    legacy: false,
    locale:cur_lang || 'en',
    messages:{
        'en':en,
        'ko':ko,
        'jq':jq,
        'hk':hk,
        'zh':zh,
        'de':de,
        'es':es,
        'fr':fr,
        'th':th,
        'vi':vi,
        'it':it,
    }
})

function setLange(i){
    localStorage.setItem('LanguageName',i.name)
    localStorage.setItem('Language',i.v)
    location.reload(true)
}
console.log(i18n.messages)
export default i18n;

export{
    setLange
}