export default {
  "home": {
    "HtD1": "Jouer ensemble, créer de la valeur",
    "HtD2": "Créez et possédez votre terrain virtuel",
    "HtD3": "Construisons ensemble un nouveau monde",
    "Btn": "Explorer maintenant",
    "info1": "Total des utilisateurs",
    "info2": "Utilisateurs actifs",
    "info3": "Volume de transactions quotidien + Volume de transactions mensuel + Volume de transactions trimestriel",
    "BoxTop": "Introduction au Métavers (Terrain Virtuel)",
    "desc": "Le métavers peut être compris comme une incarnation du monde réel, toujours connecté et synchronisé. Les installations physiques sont conçues pour être directement applicables à la socialisation, l'apprentissage, les affaires, le divertissement, la collaboration et les jeux.",
    "infoTop": "Créez des actifs de jeu uniques avec MetaSpaces et tokenisez-les. Le système n'intervient pas dans l'exploitation et la direction de la construction au sein de MetaSpaces, les utilisateurs ont une autonomie absolue. Ils peuvent entièrement déterminer la valeur finale des actifs créés dans MetaSpaces.",
    "li1": "Monde virtuel basé sur la blockchain",
    "li2": "Garantie de sécurité absolue",
    "li3": "Authenticité de l'origine",
    "li4": "Négociable sur les marchés décentralisés",
    "li5": "Construit selon des normes de sécurité vérifiées",
    "swiperTop": "Partenaires",
    "caseTop": "Études de cas"
  },
  "Build": {
    "label1": "Non construit",
    "label2": "Construit",
    "labeltext": "Aucune donnée"
  },
  "MyPage": {
    "Score": "Score de crédit",
    "JeCount1": "Montant gelé",
    "JeCount2": "Montant disponible",
    "EarNingsTop": "Revenus",
    "EarNingsInfo1": "Revenus totaux",
    "EarNingsInfo2": "En attente de retrait",
    "EarNingsInfo3": "Revenus d'aujourd'hui",
    "EarNingsBtn": "Retirer les revenus",
    "List1": "Récompenses d'invitation",
    "List2": "Historique des commandes",
    "List3": "Historique",
    "List4": "Historique des prêts",
    "List5": "Terrains proposés",
    "List6": "Centre d'aide",
    "msg": "Déconnexion réussie"
  },
  "MyTxPage": {
    "Top": "Retirer les revenus",
    "Top2": "Retirer l'USDC vers votre portefeuille",
    "info1": "Solde USDC de la plateforme",
    "info2": "Entrez la quantité d'USDC que vous souhaitez échanger",
    "btn": "Tout",
    "info3": "Frais",
    "info4": "Montant effectivement crédité",
    "txmBA": "Votre demande de retrait sera envoyée à votre portefeuille dans les 24 heures",
    "txmBB": "Confirmer le retrait",
    "Msg1": "La demande de collecte a été soumise et est en attente d'examen",
    "Msg2": "Échec du retrait, le montant du retrait ne peut pas dépasser le solde de la plateforme"
  },
  "Order": {
    "Top": "Historique des commandes",
    "wc": "Aucune donnée",
    "sb": "Aucune donnée",
    "ywc": "Terminé",
    "ysb": "Échoué"
  },
  "History": {
    "Top": "Historique",
    "tis": "Aucune donnée",
    "ShuoYi": "Revenus des commandes",
    "JiLu": "Historique des retraits"
  },
  "Bor": {
    "Top": "Historique des prêts",
    "tis": "Aucune donnée"
  },
  "Land": {
    "Top": "Terrains proposés",
    "tis": "Aucune donnée"
  },
  "Ask": {
    "Top": "Récompenses d'invitation",
    "info1": "Récompenses d'invitation",
    "info2": "Taille de l'équipe",
    "info3": "Subordonnés directs",
    "info4": "Revenu total",
    "info5": "Revenus d'aujourd'hui",
    "a1": "Historique des invitations",
    "btn1": "Retirer les récompenses",
    "AskUrlText": "Lien d'invitation",
    "btn2": "Inviter maintenant",
    "tab1": "Adresse",
    "tab2": "Temps",
    "lab1": "Historique des actifs",
    "lab2": "Niveau 1",
    "lab3": "Niveau 2",
    "lab4": "Niveau 3"
  },
  "AskRe": {
    "Top": "Historique des invitations",
    "text": "Aucune donnée"
  },
  "Help": {
    "Top": "Centre d'aide",
    "text1": "Meta land permet aux utilisateurs de faire des offres sur des terrains virtuels en utilisant l'USDC, de spéculer sur les terrains proposés et de récupérer les revenus et les terrains virtuels. Par conséquent, comme dans la vie réelle, achetez des terrains et terminez la construction pour les vendre à un prix plus élevé. Les utilisateurs peuvent posséder les NFT qu'ils ont créés une fois terminés.",
    "text2": "Prix du terrain C: 500. Taux d'intérêt journalier 1.3%, durée: 3 jours. Prix du terrain B: 5,000. Taux d'intérêt journalier 1.5%, durée: 5 jours. Prix du terrain A: 30,000. Taux d'intérêt journalier 1.9%, durée: 7 jours. Prix du terrain S: 100,000. Taux d'intérêt journalier 2.5%, durée: 15 jours. Prix du terrain SS: 300,000. Taux d'intérêt journalier 3.2%, durée: 20 jours. Prix du terrain SSS: 500,000. Taux d'intérêt journalier 4.0%, durée: 30 jours. Remarque: Seuls les terrains de niveau S et supérieur (y compris le niveau S) peuvent être construits et génèrent plus de revenus quotidiens.",
    "tte1": "Moyens de gagner de l'argent sur MetaLand",
    "tte2": "Planification des terrains sur Meta Land"
  },
  "Login": {
    "lab1": "Connecter le portefeuille pour explorer"
  },
  "AppBar": {
    "text1": "Accueil",
    "text2": "Carte",
    "text3": "Construction",
    "text4": "Actifs personnels"
  },
  MsgPage: {
    Top: "Notification",
    tte: "Aucune donnée disponible"
  },
  "QuickPurchase": {
    "btnText": "Achat Rapide",
    "landLvl": "Niveau de Terrain",
    "onAuction": "Aux Enchères",
    "earningRate": "Taux de Gain",
    "earnings": "Gains",
    "size": "Taille",
    "rules": "Règles",
    "bidding": "Enchères",
    blockchain: "Chaîne de blocs",
    ethereum: "Ethereum"
  },
  "QuickPurchaseRules": {
    "title": "Que puis-je faire avec la terre ?",
    "rule1": "Gagner des Récompenses Spéciales",
    "rule2": "Engagements exclusifs pour les détenteurs de MetalLand",
    "rule3": "Vendre votre terre et gagner des revenus",
    "rule4": "Publier et monétiser vos créations"
  },
  "BuildingDetail": {
    "coordinates": "Coordonnées",
    "owner": "Propriétaire",
    "earningRate": "Taux de Gain",
    "earnings": "Gains",
    "size": "Taille",
    "token": "Jeton"
  },

   "LoansTopName": "Règles de Prêt",
  "LoansBtn": "Confirmer la Demande de Prêt",
  "Loans_L1": "Crédit",
  "Loans_L2": "Félicitations",
  "Loans_L3": "Votre cote de crédit est bonne, vous pouvez demander un prêt. Vous devez simplement soumettre une demande<br/>Le service client déterminera le montant du prêt en fonction de votre cote de crédit.",
  "Loans_L4": "Confirmer la Demande",
  "Loans_L5": "Soumettre une demande de prêt basée sur les points de crédit",
  "Loans_L6": "Obtenir un Prêt",
  "Loans_L7": "Compléter des tâches de niveau supérieur",
  "Loans_L8": "Continuez à accomplir des tâches pour accumuler des points de crédit. La limite maximale de prêt est",
  "Loans_L9": "Montant",
  "Loans_L10": "Jours",
  "Loans_L11": "Intérêt",
  "Loans_L12": "Accepter",
  "Loans_L13": "Montant de l'Investissement",
  "Loans_L14": "Pourcentage de Rendement",

  "Build_L1": "Construction",

  "BOT_L1": "Construction du Terrain",
  "BOT_L2": "Prix du Terrain",
  "BOT_L3": "Construire Maintenant",
  "BOT_Sel1": "Résidentiel",
  "BOT_Sel2": "Public",
  "BOT_L4": "Propriétaire",
  "BOT_L5": "Emplacement:",
  "BOT_L6": "Rendement de Base:",
  "BOT_L7": "Rendement de Construction:",
  "BOT_L8": "Rendement:",
  "BOT_L9": "Sélection de l'Usage de Construction:",
  "BOT_L10": "Sélection du Type de Construction:",
  "BOT_L11": "Veuillez sélectionner un type de construction:",
  "BOT_L12": "Coût de Construction",
  "BOT_L13": "Réclamer NFT",

  "Land_L1": "Montant de l'Offre",
  "Land_L2": "Rendement Pendant la Période",
  "Land_L3": "Fin",
  "Land_L4": "Emplacement",
  "Land_L5": "Pourcentage de Rendement",
  "Land_L6": "Rendement",
  "Land_L7": "Jours",

  "Bor_L1": "Montant du Prêt",
  "Bor_L2": "Jours",
  "Bor_L3": "En Cours de Demande",
  "Bor_L4": "Temps",
  "Bor_L5": "Intérêt",
  "Bor_L6": "Terminé",

  "Not_data_Msg": "Pas plus de données"
}
